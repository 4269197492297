class TicketSearchForm extends Component {
  static paramKeys = ['search', 'search_number', 'search_content', 'search_account', 'search_user', 'order'];

  constructor(props) {
    super(props);
    this.state = { type: props.initType, value: props.initValue };
  }

  render() { 
    return (
      <form className='ticket-search-form'>
        {this.renderSearchTypeToggler()}
        <div className="d-inline-flex align-center">
          <TextInput inline
            small
            value={this.state.value || ''}
            onChange={v => this.setState({ value: v })} />

          <Button color='surface-lighten' small onClick={e => this.submit(e)} ><Icon name='search' /></Button>
        </div>
      </form>
    )
  }

  renderSearchTypeToggler() {
    const selectOptions = [
      { id: 'general',            label: 'Общий поиск' },
      { id: 'number',             label: 'Поиск по номеру' },
      { id: 'account',            label: 'Поиск по магазину' },
      { id: 'content',            label: 'Поиск по содержимому' },
      { id: 'user',               label: 'Поиск по клиенту' },
    ];

    const selectedOption = selectOptions.filter(o => o.id === this.currentType())[0];

    return (
      <DropdownTrigger items={selectOptions} onSelect={id => this.setState({ type: id })}>
          {selectedOption.label}
      </DropdownTrigger>
    );
  }

  currentType() {
    return this.state.type || 'general';
  }

  submit(e) {
    e.preventDefault();

    let params = { search: null,
                   search_content: null,
                   search_user: null,
                   search_account: null,
                   search_number: null };

    if(this.currentType() == 'general') {
      params.search = this.state.value;
    } else {
      params[`search_${this.currentType()}`] = this.state.value;
    }

    params['order'] = 'relevance'

    this.props.onSubmit(params);
  }
}

export default TicketSearchForm;

