class NewAutoReplyPage extends Component {
  render() {

    return (
      <layouts.Staff>
        <Card stretch>
          <h2>Авто-ответы</h2>
          <Margin size="20"/>
          <staff.AutoReply onSave={() => browserHistory.push(`/staff/auto_replies`)}
                           autoReply={{title: '', content: '', delay_in_days: 7, active: true}}/>
        </Card>
      </layouts.Staff>
    );
  }
}

export default NewAutoReplyPage;
