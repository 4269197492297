class Checkbox extends Component {
  render() {
    const {label, checked, onChange, disabled} = this.props
    return (
      <label className='checkbox'>
        <input className="checkbox__input"
               type="checkbox"
               disabled={disabled}
               checked={checked}
               onChange={e => onChange(e.target.checked)} />

        <div className="checkbox__dummy" />
        {Boolean(label) && <div className="checkbox__label">{label}</div>}
      </label>
    );
  }

}

export default Checkbox;
