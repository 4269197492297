import {bemModifiers} from 'app/lib/utils';

class NumericTextInput extends Component {
  render() {
    const {value, onChange, inline, short, small, type, disabled, step} = this.props;
    const {placeholder} = this.props;

    const modifiers = [inline && 'inline', small && 'small', short && 'short'];
    const className = bemModifiers('numeric-text-input', modifiers);

    return (
      <input value={value || ''} onChange={e => onChange(parseInt(e.target.value))}
             disabled={disabled} placeholder={placeholder}
             className={className} type={type || 'number'} step={step || 1}/>
    );
  }
}

export default NumericTextInput;
