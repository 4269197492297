import AbstractEntity from './AbstractEntity'

class StatisticType extends AbstractEntity {
  parametersAsArray() {
    const result = [];
    for(const name in this.parameters) {
      result.push(Object.assign({ name }, this.parameters[name]));
    }

    return result;
  }

  requiredParams() {
    const result = [];
    for(let param in this.parameters) {
      if(this.parameters[param].required)
        result.push(param);
    }
    return result;
  }
}

export default StatisticType;
