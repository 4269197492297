import {boolValue} from 'app/lib/utils'

class ArticlesPage extends Component {
  state = { articles: [] }

  componentDidMount() {
    this.getData();
  }

  getData() {
    if(this.state.dataLoading)
      return;

    this.setState({ dataLoading: true });
    const repo = new repositories.ArticleRepo();
    repo.getAll()
        .then(articles => this.setState({ articles, dataLoading: false }))
  }

  render() {
    const {articles} = this.state;

    return (
      <layouts.Staff>
        <Card stretch>
          <h2>Статьи</h2>

          <Margin size='20' />

          <Table cols={['Название', 'Опубликована', '']}>
            {articles.map(a => this.renderTableRow(a))}
          </Table>

          <Margin size='20' />
          <Button size='xs' color='green' to='/staff/articles/new'>Создать</Button>
        </Card>
      </layouts.Staff>
    );
  }

  renderTableRow(article) {
    return (
      <tr key={article.id}>
        <td>{article.title}</td>
        <td>{boolValue(article.published)}</td>
        <td>
          <Spaced h={10} w={10}>
            <Button size="xs" color="blue" to={`/staff/articles/${article.id}`}>Редактировать</Button>
            <Button size="xs" color="gray" onClick={() => this.deleteArticle(article)}>Удалить</Button>
          </Spaced>
        </td>
      </tr>
    )
  }

  deleteArticle(article) {
    if(!confirm(`Вы уверены, что хотите удалить статью ${article.title} ?`))
      return;

    const repo = new repositories.ArticleRepo();
    repo.destroy(article)
        .then(data => this.getData());
  }
}

export default ArticlesPage;
