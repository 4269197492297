class ArticleRepo {
  faq(params) {
    const promise = new Promise(function(resolve, reject) {
      API.articles.faq({ params, success: resolve, error: reject });
    });

    return promise;
  }

  getAll(params) {
    const promise = new Promise(function(resolve, reject) {
      API.articles.getAll({
        params,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }

  get(id) {
    const promise = new Promise(function(resolve, reject) {
      API.articles.get({
        id,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }

  update(article) {
    const promise = new Promise(function(resolve, reject) {
      API.articles.update({
        id: article.id,
        params: article,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }


  create(article) {
    const promise = new Promise(function(resolve, reject) {
      API.articles.create({
        params: article,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }

  destroy(article) {
    const id = typeof article == 'object' ? article.id : article;

    const promise = new Promise(function(resolve, reject) {
      API.articles.delete({
        id,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }
}

export default ArticleRepo;
