import {connect} from 'react-redux'
import {reloadChatGPTModels} from 'app/store/helpers'
import {formatDate} from 'app/lib/utils'

const repo = new repositories.ChatGPTModelRepo();

class ChatGPTModelsList extends Component {
  state = { errors: null };

  componentDidMount() {
    reloadChatGPTModels();
  }

  hasWriteAccess() {
    const {currentUser} = this.props;
    return currentUser && currentUser.kind === 'manager' && currentUser.admin;
  }

  delete = (model) => {
    if(!confirm(`Вы уверены, что хотите удалить модель ${model.name} ?`)) {
      return;
    }
      
    repo.destroy(model.id).then(
      reloadChatGPTModels,
      this.showErrors,
    );
  }

  showErrors = ({responseJSON}) => {
    let errors = ["Произошла ошибка"];
    if(responseJSON && responseJSON.errors) {
      errors = responseJSON.errors;
    }

    this.setState({ errors });
    setTimeout(() => this.hideErrors(), 5000);
  }

  hideErrors = () => {
    this.setState({ errors: null });
  }

  renderRow(model) {
    const {id, name, created_at, status, manager: {full_name}} = model;

    const cells = [
      <span>{name}</span>,
      <span>{status}</span>,
      <span>{full_name}</span>,
      <span>{formatDate(created_at)}</span>,
      this.renderControlButtons(model),
    ];

    return (
      <tr key={'' + id}>
        {cells.map((c, i) => <td key={i}>{c}</td>)}
      </tr>
    );
  }

  renderControlButtons(model) {
    if (!this.hasWriteAccess()) {
      return null;
    }

    return (
      <Spaced w={3}>
        <Button size="xs" color="blue" to={`/staff/chat_gpt_models/${model.id}`}>Редактировать</Button>
        <Button size="xs" color="red" onClick={() => this.delete(model)}>Удалить</Button>
      </Spaced>
    );
  }

  render() {
    const {chatGPTModels} = this.props;
    if (!chatGPTModels) {
      return <PageTransition />;
    }

    const { errors } = this.state;

    return (
      <layouts.Staff>
        <Card stretch>
          <Margin size="20 0"><h2>Список моделей Chat GPT</h2></Margin>
          <Button size="xs" color="green" to={"/staff/chat_gpt_models/new"}>Создать</Button>
          <Margin size="10" />
          <h4>Статусы:</h4>
          <ul>
            <li>new - создана модель в нашей базе, но не создан бот и не обучен</li>
            <li>pending и остальные неописанные - создан бот и ожидается обучение на стороне Chat GPT</li>
            <li>running - обучение запущено в Chat GPT</li>
            <li>succeeded - бот обучен и готов к работе</li>
            <li>failed - обучение провалилось, следует удалить модель и создать новую</li>
          </ul>
          <Margin size="20" />
          {errors && <ErrorNotice errors={errors} />}
          <Table cols={['Название', 'Статус', 'Автор', 'Создана', '']}>
            {
              chatGPTModels.map(t => this.renderRow(t))
            }
          </Table>
          <Margin size="20" />
          <Button size="xs" color="green" to={"/staff/chat_gpt_models/new"}>Создать</Button>
        </Card>
      </layouts.Staff>
    );
  }
}

const mapStateToProps = state => ({
  chatGPTModels: state.chatGPTModels,
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(ChatGPTModelsList);
