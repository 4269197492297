//autoindex via plugin:
import * as modules from 'glob:./**/*.jsx';
import * as root_modules from 'glob:./*.jsx';
import * as root_js_modules from 'glob:./!(index).js';
import recursive_modules from '../lib/recursive_modules'

export default {
  ...root_modules,
  ...root_js_modules,
  ...recursive_modules(modules)
}
