import {bemModifiers} from 'app/lib/utils'

class ThisMonthFreeWorksNumber extends Component {
  render() {
    const {done, available, freeWorkType} = this.props;

    const className = bemModifiers(
      'this-month-free-works-number',
      [done && available && available <= done && 'limit-reached']
    );

    return (
      <div className={className}>
        Бесплатных {freeWorkType} в этом месяце:
        &nbsp; {this.renderCounter()}
      </div>
    );
  }

  renderCounter() {
    const {done, available} = this.props;
    if(done !== 0 && !done)
      return null;

    return available ? `${done} из ${available} доступных.` : done.toString();
  }
}

export default ThisMonthFreeWorksNumber;
