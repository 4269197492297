class CategoryList extends Component {
  isHighlighted(category) {
    if (!category.id || !this.props.highlight) {
      return false;
    }
    const highlight = this.props.highlight.map((e) => e - 0);
    return highlight.includes(category.id - 0);
  }

  renderCategoryBlock(block) {
    return (
      <div key={block.parent?.id}>
        <div>{this.renderCategory(block.parent, true)}</div>
        <div>
          {block.child.map((c) => this.renderCategory(c))}
        </div>
      </div>
    )
  }

  renderCategory(category, main) {
    const { onSelect, onDelete } = this.props;
    return (
      <Tag
        key={category.id}
        data={category}
        small={this.props.small}
        main={main}
        onClick={onSelect && (() => onSelect(category))}
        onDelete={onDelete}
        highlight={this.isHighlighted(category)}
      />
    );
  }

  // Работает корректно с одним уровнем вложенности(one level of nesting)
  categoriesBlock = (categories) => {
    const result = categories.reduce((acc, category) =>
      !category.parent_id ? [...acc, {parent: category, child: []}] : acc, [])
    categories.map((category) => {
      const index = result.findIndex((i) => i.parent?.id === category.parent_id)
      index !== -1 && result[index].child.push(category)
    })
    return result
  }


  render() {
    const categories = this.props.categories || [];
    return (
      <div className="category-list">
        {
          categories.length === 0
            ? null
            : this.categoriesBlock(categories).map((c) => this.renderCategoryBlock(c))
        }
      </div>
    );
  }
}

export default CategoryList;
