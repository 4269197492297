import {connect} from 'react-redux'
import {findById} from 'app/lib/utils'


const AddTag = function({action, tags}) {
  const id = action.action_value;
  const tag = findById(id, tags);
  return <div>Добавить тег {tag ? <Tag small data={tag} /> : `#${id}`}</div>;
};

const mapStateToProps = s => ({ tags: s.tags });
export default connect(mapStateToProps)(AddTag);
