import Chart from 'chart.js';
import {connect} from 'react-redux'
import {findById} from 'app/lib/utils'

/*
 * <charts.Pie idSuffix='abc' data={[[1, 'label1'], [2, 'label2']]} label='Some chart' />
 */

class Pie extends Component {
  componentDidMount() {
    if(this.haveDataToShow())
      this.renderChart();
  }

  componentDidUpdate() {
    if(this.haveDataToShow())
      this.renderChart();
  }

  render() {
    const {label, data} = this.props;
    return (
      <div>
        <strong>{label}</strong>
        <br />
        {
          this.haveDataToShow() ?
          <canvas id={this.canvasId()} width='250' height='250'></canvas> :
          'Нет данных'
        }
      </div>
    )
  }

  haveDataToShow() {
    const {data} = this.props;
    return data && 0 < data.filter(e => e[0] > 0).length;
  }

  canvasId() {
    return `pie-chart${this.props.idSuffix || ''}`;
  }

  renderChart() {
    const {statistic, label, responsive} = this.props;
    const ctx = document.getElementById(this.canvasId()).getContext('2d');

    var myChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: this.labels(),
        datasets: [{
          label, // but it looks like this never shows up
          borderWidth: 0,
          data: this.values(),
          backgroundColor: this.colors()
        }]
      },
      options: {
        responsive: !!responsive
      }
    });
  }

  values() {
    const {data} = this.props;
    return data.map(e => e[0]);
  }

  labels() {
    const {data} = this.props;
    return data.map(e => e[1]);
  }

  colors() {
    const {data} = this.props;
    const availableColors = ['crimson', 'green', 'blue', 'yellow', 'grey'];
    return availableColors.slice(0, Math.min(data.length, availableColors.length));
  }
}

export default Pie;
