import PropTypes from 'prop-types';

class StaffMessageForm extends Component {
  state = {}
  static propTypes = {
    ticket: PropTypes.object.isRequired,
    triggerDataReload: PropTypes.func.isRequired, // #getData from parent
    formIdSuffix: PropTypes.string,
    showErrorsFromResponse: PropTypes.func.isRequired,  // from parent
  }

  componentDidUpdate() {
    if(this.focusMessageForm) {
      this.refs.messageForm.focus();
      this.focusMessageForm = null;
    }
  }

  getText() {
    return this.refs.messageForm.getText();
  }

  setText(txt) {
    this.refs.messageForm.setText(txt);
  }

  show() {
    const {messageType} = this.state;
    if(!messageType)
      this.setState({ messageType: 'message' });
  }

  focus() {
    this.focusMessageForm = true;
    this.forceUpdate();
  }

  render() {
    const {ticket, formIdSuffix} = this.props;
    const {messageType} = this.state;

    return (
      <div>
        <Button small onClick={() => this.toggleMessageType('message')}>Сообщение</Button>
        <Button small color="gray" additionalClasses="ml-2" onClick={() => this.toggleMessageType('note')}>Заметка</Button>
        <Margin size="15" />
        <MessageForm ref={'messageForm'}
                            formId={`message-form-${ticket.id}${formIdSuffix ? '-' + formIdSuffix : ''}`}
                            visible={messageType}
                            color={messageType == 'message' ? null : 'orange'}
                            label={messageType == 'message' ? 'Текст сообщения' : 'Текст заметки'}
                            withStatusSelect={messageType === 'message'}
                            onSubmit={data => this.submitMessageForm(data)} />
      </div>
    );
  }

  toggleMessageType(type) {
    if(type === this.state.messageType) {
      this.setState({ messageType: null });
    } else {
      // CRUTCH! solution: remove `visible` prop from MessageForm and use something like `focusOnMount`
      this.focusMessageForm = true;
      this.setState({ messageType: type });
    }
  }

  submitMessageForm({message, fileIds, status}) {
    const messageForm = this.refs.messageForm;
    const {ticket, triggerDataReload, showErrorsFromResponse} = this.props;
    const {messageType} = this.state;

    const onSuccess = () => {
      triggerDataReload();
      messageForm.reset();
    }
    const onVersionConflict = () => {
      triggerDataReload();
      alert('Похоже, тикет был обновлен. Попробуйте еще раз')
    }
    const onError = data => data.status == 409 ? onVersionConflict() : showErrorsFromResponse(data);
    if(messageType == 'message') {
      API.tickets.addMessage({ id: ticket.id,
                               params: { message, file_ids: fileIds, version: ticket.version, status: status },
                               success: onSuccess,
                               error: onError,
                               acceptStatuses: [409, 422]
                             });
    } else {
      API.tickets.addNote({ id: ticket.id,
                            params: { message, file_ids: fileIds },
                            success: onSuccess,
                            error: showErrorsFromResponse,
                            acceptStatuses: [409, 422]
                          });
    }
  }
}

export default StaffMessageForm;
