import ImageGallery from "react-image-gallery";

const ImageSlider = function({ onHide, images = [], startIndex }) {
  const items = images.map(({ url }) => ({ original: url }))

  return (
    <Modal large onHide={onHide}>
      <h2>Изображения</h2>
      <Margin size="20" />
      <ImageGallery items={items} showPlayButton={false} startIndex={startIndex} />
    </Modal>
  )
}

export default ImageSlider
