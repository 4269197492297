import {connect} from 'react-redux'
import {setAppbarSearchType} from 'app/store/helpers'
import { bemModifiers } from 'app/lib/utils';
import cookie from 'cookie'
import insalesLogo from 'app/assets/insales.svg'

class Appbar extends Component {
  render() {
    // rightAddon - ?
    const {person, logoUrl, withSearch} = this.props;
    const className = bemModifiers('appbar', [withSearch && 'with-search']);
    const spaceControlsParams = this.spaceControlsParams()

    return (
      <div className={className}>
        <layouts.partials.SiteContent> {/* this component centers content */}
          <div className="appbar__content">
            <Link to={logoUrl} className="appbar__logo">
              <img className="appbar__logo-img" alt="inSales" src={insalesLogo} width="75" height="25" />
            </Link>

            {withSearch && this.renderGroupSelect()}

            {withSearch && this.renderSearchForm()}

            <Spaced w={spaceControlsParams.width} h={spaceControlsParams.height} className="appbar__controls">
              {person.full_name && <Person person={person} white />}
              {this.renderDropdownMenu()}
            </Spaced>
          </div>
        </layouts.partials.SiteContent>
      </div>
    );
  }

  spaceControlsParams () {
    let desktopParams = {
      width: 40,
      height: 10
    }
    let mobileParams = {
      width: 16,
      height: 4
    }

    return window.outerWidth <= 768 ? mobileParams : desktopParams
  }

  renderGroupSelect() {
    const groups = this.props.groups;

    if (!groups || groups.length == 0) {
      return;
    }

    const currentGroup = groups.find((group) => group.id == cookie.parse(document.cookie).g) || groups[0];

    const items = groups.map((group) => {
      return { id: group.id, label: group.title }
    })

    const onSelect = (id) => {
      document.cookie = cookie.serialize('g', id, { maxAge: 60 * 60 * 24 * 7, path: '/' })
      location.reload()
    }

    return (
      <div>
        <DropdownTrigger className='test-class' items={items} onSelect={onSelect} right noUnderline>
          <div className='appbar__menu-inner'>
            <div className='appbar__menu-title'>
              <span>Группа: { currentGroup.title }</span>
            </div>
            <div className='appbar__menu-icon'>
              <Icon name="arrow_drop_down" className="valign-middle" />
            </div>
          </div>
        </DropdownTrigger>
      </div>
    )
  }

  renderDropdownMenu() {
    const person = this.props.person;

    const isManager = u => u.kind == 'manager';
    const isUser = u => u.kind == 'user';
    const links = this.links();

    const items = [
      { id: { path: links.profile },         label: 'Профиль',          key: 'profile',   check: isManager },
      { id: { path: links.templates },       label: 'Шаблоны ответов',  key: 'templates', check: isManager },
      { id: { path: links.scripts },         label: 'Сценарии',         key: 'scripts',   check: isManager },
      { id: { path: links.subs },            label: 'Подписки',         key: 'subs',      check: isManager },
      { id: { path: links.activityFeed },    label: 'Лента активности', key: 'activityFeed', check: isManager },
      { id: { path: links.ratingFeed },      label: 'Лента оценок',     key: 'ratingFeed', check: isManager },
    ];

    if(person.main_host) {
      let backofficeUrl = person.main_host + '/admin2/home';
      if(!backofficeUrl.match(/^http/)) {
        backofficeUrl = 'http://' + backofficeUrl;
      }
      items.push({ id: { url: backofficeUrl }, label: 'Назад в магазин', check: isUser });
    }
    const onSelect = ({url, path}) => path ? browserHistory.push(path) : location.replace(url);

    return (
      <DropdownTrigger className='test-class' items={items.filter(i => !i.check || i.check(person))} onSelect={onSelect} right noUnderline>
        <div className='appbar__menu-icon'>
          <Icon name="menu" className="valign-middle" />
        </div>
      </DropdownTrigger>
    )
  }

  links() {
    const person = this.props.person;

    let result;

    if(person && person.kind === 'manager') {
      result = {
        profile: `/staff/managers/${person.id}`,
        scripts: '/staff/scripts',
        subs: '/staff/subscriptions',
        templates: '/staff/answer_templates',
        activityFeed: `/staff/managers/activity?manager=${person.id}`,
        ratingFeed: `/staff/managers/rates?managers=${[person.id]}`,
      };
    } else {
      result = {};
    }


    return result;
  }

  renderSearchForm() {
    const queryString = params => staff.TicketSearchForm.paramKeys
                                                               .filter(k => params[k])
                                                               .map(k => `${k}=${params[k]}`)
                                                               .join('&');
    const onSubmit = params => {
      setAppbarSearchType(this.refs.searchForm.currentType());
      browserHistory.push(`/staff/tickets?${queryString(params)}`);
    }

    return <staff.TicketSearchForm onSubmit={onSubmit}
                                   ref='searchForm'
                                   initType={this.props.appbarSearchType} />;
  }
}

const mapStateToProps = s => ({ appbarSearchType: s.userSettings.appbarSearchType });
export default connect(mapStateToProps)(Appbar);
