const notificationRepo = new repositories.NotificationRepo()

class NotificationsPage extends Component {
  state = { notification: {} }

  componentDidMount() {
    this.getNotificationInfo()
  }

  getNotificationInfo() {
    notificationRepo.getAll().then((response) => {
      this.setState({ notification: response })
    })
  }

  render() {
    return (
      <layouts.Staff>
        <Card stretch>
          <h2>Уведомления</h2>
          <Margin size="20" />
          {!Object.keys(this.state.notification).length ?
            (<div>
              <Button size="xs" to="/staff/notifications/new">Создать</Button>
              <Margin size="20" />
            </div>)
            :
            (<div>
              <h3>Текущее уведомление</h3>
              <Margin size="20" />
              {this.state.notification.description}
              <Margin size="10" />
              <p><b>Активно до:</b></p>{this.state.notification.expires_at}
              <Margin size="20" />
              <Spaced h={10} w={10}>
                <Button size="xs" to="/staff/notifications/edit">Редактировать</Button>
                <Button size="xs" onClick={() => {
                  notificationRepo.destroy({ notification: { id: 0 } })
                  this.setState({ notification: 0 })
                }} color="gray">Удалить</Button>
              </Spaced>
            </div>)}
        </Card>
      </layouts.Staff>
    )
  }
}
export default NotificationsPage;
