import Select from 'react-select'
import {isUndefined} from 'app/lib/utils'

class TagForm extends Component {
  render() {
    const {tag, onChange, onSave} = this.props;

    return (
      <div className='tag-form'>
        <Spaced w={16} h={8}>
          <Checkbox label="Публичный?" checked={tag.public || false} onChange={v => onChange({ public: v })} />
          <Checkbox label="Запрещает автоснятие тикетов с ответственного"
                          checked={tag.forbid_autotake_from_others || false}
                          onChange={v => onChange({ forbid_autotake_from_others: v })} />
        </Spaced>
        <Margin size='16' />
        <Spaced h={40}>
          <LabeledInput label="Название">
            <TextInput value={tag.name || ''} onChange={v => onChange({ name: v })}/>
          </LabeledInput>
          <LabeledInput label="Короткое название">
            <TextInput value={tag.short_name || ''} onChange={v => onChange({ short_name: v })} />
          </LabeledInput>
          <LabeledInput label="Время ответа на первое сообщение (в часах)">
            <TextInput value={tag.first_response_time || ''} onChange={v => onChange({ first_response_time: v })} />
          </LabeledInput>
          <LabeledInput label="Время ответа на последующие сообщения (в часах)">
            <TextInput value={tag.common_response_time || ''} onChange={v => onChange({ common_response_time: v })} />
          </LabeledInput>
          <LabeledInput label="Сортировочный вес (больше = выше)">
            <TextInput value={isUndefined(tag.order_weight) ? '' : tag.order_weight} onChange={v => onChange({ order_weight: v })} />
          </LabeledInput>
          <LabeledInput label="Описание">
            <TextInput value={tag.description} onChange={v => onChange({ description: v })}></TextInput>
          </LabeledInput>
          {this.renderArticleSelect()}
          <SplitSides>
            <div></div>
            <Button onClick={onSave}>Сохранить</Button>
          </SplitSides>
        </Spaced>
      </div>
    );
  }

  renderArticleSelect() {
    const {tag, articles, onChange} = this.props;

    if(!articles) {
      return null;
    }

    const items = articles.map(a => ({ value: a.id, label: a.title }));
    return (
      <LabeledInput label="Статья с описанием (для пользователя)">
        <Select options={items}
                onChange={item => onChange({ article_id: item && item.value })}
                value={tag.article_id}
                placeholder='Выбрать' />
      </LabeledInput>
    );
  }
}

export default TagForm;
