function bemModifiers(base, modifiers) {
  return base + ' ' + modifiers.filter(e => e).map(m => `${base}--${m}`).join(' ');
}

const boolValue = value => value ? 'да' : 'нет';

const formatDate = function(t) {
  const date = new Date(t);
  const formatDateOptions = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
  return date.toLocaleString('ru', formatDateOptions);
}

const queryParams = function(parameters, keys = Object.keys(parameters)) {
  // false should be include into result, but nulls not
  const isKeyPresent = k => parameters[k] !== null && !isUndefined(parameters[k]) && Object.keys(parameters).indexOf(k) != -1;
  const parts = keys.filter(isKeyPresent).map(k => `${k}=${parameters[k]}`);
  return parts.join('&');
}

const isArray = obj => Object.prototype.toString.call(obj) === '[object Array]';

const objectWithoutNulls = function(obj) {
  const result = {};
  for(let k in obj) {
    if(obj[k] !== null)
      result[k] = obj[k];
  }
  return result;
}

const findById = (id, coll) => coll.filter(e => e.id == id)[0];

// creates new object based on `object` but with `key` associated with `value`
const assoc = (object, key, value) => Object.assign({}, object, { [key]: value });

function isUndefined(x) { return typeof x === 'undefined'; }

const fetchObjectSubset = function(obj, properties) {
  return properties.reduce(
    (a, e) => isUndefined(obj[e]) ? a : Object.assign(a, { [e]: obj[e] }),
    {}
  )
}

function iterateObject(obj, cb) {
  for(let k in obj) {
    cb(k, obj[k]);
  }
  return obj;
}

const extractFreeWorkType = (params) => {
  if (!params) return;
  return params?.freePlan ? "FreeWork::TechSupport" : null;
};

export { bemModifiers, boolValue, formatDate, queryParams, isArray, objectWithoutNulls, findById, extractFreeWorkType };
export { assoc, isUndefined, fetchObjectSubset, iterateObject }
