/*
 *
 * Use promise to unify repositories
 * (in case we won't use JQuery in future or some cases)
 *
 */
class SubscriptionRepo {
  getAll(managerId, params) {
    const promise = new Promise(function(resolve, reject) {
      const toEntities = data => data.map(s => new entities.Subscription(s));

      API.subscriptions.getAll({
        managerId,
        params,
        success: data => resolve(toEntities(data)),
        error: reject,
      });
    });

    return promise;
  }

  create(managerId, params) {
    const promise = new Promise(function(resolve, reject) {
      API.subscriptions.create({
        managerId,
        params,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }

  destroy(managerId, id) {
    const promise = new Promise(function(resolve, reject) {
      API.subscriptions.delete({
        managerId,
        id,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }
}

export default SubscriptionRepo;
