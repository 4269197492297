import {connect} from 'react-redux'

class RatingEvent extends Component {
  constructor(props) {
    super(props);
    const {event} = props;
    this.state = {
      is_motivation: event.is_motivation || false,
      motivation_comment: event.motivation_comment || '',
      eliminate_from_calculations: event.eliminate_from_calculations || false,
    };
  }

  render() {
    const manager = this.props.manager;
    const message = this.props.event.message;
    const opinion = this.props.event.value_string;
    const messageClass = `rating-event__message rating-event__message--${opinion}`;

    return (
      <div className='rating-event'>
        <RatingIcon opinion={opinion} size={30} />
        {manager && <Person person={manager} note={this.props.personNote} />}
        {this.renderMotivationData()}
        {message && <div className={messageClass}>{message}</div>}
      </div>
    );
  }

  renderMotivationData() {
    const {is_motivation, motivation_comment, eliminate_from_calculations, saved} = this.state;
    const {currentUser, manager} = this.props
    const disabled = manager ? (currentUser.id != manager.id) : false;

    const setMotivation = v => this.setState({ saved: false, is_motivation: v });
    const setComment = v => this.setState({ saved: false, motivation_comment: v });
    const setEliminateFromCalculations = v => this.setState({ saved: false, eliminate_from_calculations: v });

    return (
      <div>
        <form onSubmit={e => this.submitMotivationData(e)}>
          <div style={{flexDirection:'row'}}>
          {!disabled && <Checkbox disabled={disabled} onChange={setMotivation} checked={is_motivation} label='мотивация' />}
          {
            is_motivation && (motivation_comment || !disabled) &&
            <TextInput disabled={disabled} placeholder='Комментарий'
                              value={motivation_comment} onChange={setComment}
                              small inline />
          }
          </div>
          <div>
          {currentUser.admin && <Checkbox onChange={setEliminateFromCalculations} checked={eliminate_from_calculations} label='Не учитывать' />}
          </div>
          <div>
            {(!disabled || currentUser.admin) && <Button additionalClasses='mt-1' small>Сохранить</Button>}
          {saved && <span>&nbsp; <Icon name='done' /></span>}
          {this.hasChargeableTag() && <div><Tag data={this.getChargeableTag()} /></div>}
          </div>
        </form>
      </div>
    )
  }

  hasChargeableTag() {
    const {event} = this.props;
    const chargeableTag = this.getChargeableTag();
    return chargeableTag && event.ticket.tag_ids.find(id => id == chargeableTag.id);
  }

  getChargeableTag() {
    const tagRepo = new repositories.TagRepo();
    return tagRepo.store.getChargeableTag();
  }

  submitMotivationData(event) {
    event.preventDefault();
    const {is_motivation, motivation_comment, eliminate_from_calculations} = this.state;
    const activity_point_id = this.props.event.activity_point_id;
    const {currentUser, manager} = this.props
    const disabled = manager ? (currentUser.id != manager.id) : false;

    !disabled && API.managers.ratingFeed.update({
                  id: this.props.event.id,
                  params: { is_motivation, motivation_comment },
                  success: () => this.setState({ saved: true }),
                  error: () => alert('Что-то не так'),
                });

    currentUser.admin && API.activityPoints.eliminateFromCalculations({
                          id: activity_point_id,
                          params: { eliminate_from_calculations },
                          success: () => this.setState({ saved: true }),
                          error: () => alert('Что-то не так'),
                        });
  }
}

const mapStateToProps = s => ({ currentUser: s.currentUser });
export default connect(mapStateToProps)(RatingEvent);
