class TicketFork extends Component {
  state = {}
  componentDidMount() {
    const ticket = this.props.ticket;
    this.setState({ subject: `Отсоединено от #${ticket.id}` });
  }

  render() {
    const {onHide, events} = this.props;
    const {errorMessage, subject} = this.state;

    return (
      <div className='ticket-fork'>
        <Modal onHide={onHide}>
          <h2>Отсоединение тикета</h2>
          {errorMessage && <Notice type="error">{errorMessage}</Notice>}
          <Margin size="20" />
          Тема нового тикета:
          <br />
          <TextInput value={subject || ''} onChange={v => this.setState({ subject: v })} />
          <Margin size="20" />
           Выберите сообщения:
          <Margin size="20" />
          <staff.TicketEventFeed events={events} onSelect={selected => this.setState({ selected })} />
          <SplitSides>
            <div />
            <Button onClick={() => this.fork()}>Отсоединить</Button>
          </SplitSides>
          {errorMessage && <Notice type="error">{errorMessage}</Notice>}
        </Modal>
      </div>
    );
  }

  fork() {
    const {subject, selected} = this.state;
    const {ticket, onHide} = this.props;
    const params = { subject, messages: selected };
    const gotoTicket = t => { onHide(); browserHistory.push(`/staff/${t.id}`); };
    const onError = ({responseJSON}) => this.showError(responseJSON.errors.join('; '));
    this.hideError();
    API.tickets.fork({ params, id: ticket.id, success: data => gotoTicket(data.ticket), error: onError });
  }

  showError(message) {
    this.setState({ errorMessage: message });
  }

  hideError() {
    this.setState({ errorMessage: null });
  }
}

export default TicketFork;
