import debounce from 'app/lib/debounce'

class ScrollToBottom extends Component {
  state = {
    directionStart: false
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this), { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { refEnd } = this.props;

    return (
      <div className="scroll-to-bottom" onClick={() => this.scrollToEnd(refEnd)}>
        <span className='material-icons'>
          {this.getDirection()}
        </span>
      </div>
    );
  }

  handleScroll = debounce(() => {
    this.setState({ directionStart: window.pageYOffset > window.app.offsetHeight / 1.8 });
  }, 200)

  scrollToEnd(refEnd) {
    if (refEnd && !refEnd.current) {
      return null
    } else {
      let direction = this.state.directionStart ? 'start' : 'end'
      refEnd.current.scrollIntoView({ behavior: "smooth", block: direction });
    }
  }


  getDirection() {
    return this.state.directionStart ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
  }
}

export default ScrollToBottom;
