const SetStatus = function(props) {
  const {action, value, onChange} = props;

  return (
    <div>
      Установить статус <StatusSelect current={value ||action.action_value}
                                             onChange={onChange} />
    </div>
  );
}

export default SetStatus;
