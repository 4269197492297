class StatisticsRepo {
  getTypes() {
    const toEntities = data => data.map(t => new entities.StatisticType(t));

    const promise = new Promise(function(resolve, reject) {
      API.statistics.getTypes({
        success: data => resolve(toEntities(data)),
        error: reject,
      });
    });

    return promise;
  }

  get(systemName, params) {
    const promise = new Promise(function(resolve, reject) {
      API.statistics.get({
        systemName,
        params,
        success: statistic => resolve(new entities.Statistic(statistic)),
        error: reject,
      });
    });

    return promise;
  }
}

export default StatisticsRepo;
