import {bemModifiers} from 'app/lib/utils';

class Button extends Component {
  render() {
    const {to, onClick, onDoubleClick, children, disabled} = this.props;
    if(to && onClick) {
      throw 'Button: props conflict (to & onClick)';
    }
    const { color, small, outline, icon, additionalClasses = null} = this.props;
    const modifiers = [color, small && 'small', outline && 'outline'].filter(e => e);
    const className = [bemModifiers('button', modifiers), additionalClasses].filter(Boolean).join(' ')
    return (
      <button className={className}
              disabled={disabled}
              onClick={to ? () => browserHistory.push(to) : onClick}
              onDoubleClick={onDoubleClick}>
        {children}
        {icon && <Icon name={icon} className="button__icon" />}
      </button>
    );
  }
}

export default Button;
