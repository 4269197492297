import strings from 'app/lib/strings'
import {formatDate} from 'app/lib/utils'

const passedSince = function(time) {
  const date = +new Date(time)
  const now = +new Date
  const total_secs = (now - date) / 1000;
  if (total_secs < 60)
    return 'меньше минуты';

  const days = Math.floor(total_secs / 60 / 60 / 24);
  const hours = Math.floor(total_secs / 60 / 60) % 24;
  const minutes = Math.floor(total_secs / 60) % 60;

  let result = '';
  if(days !== 0)
    result = `${days} ${strings.p(days, 'день', 'дня', 'дней')}`;
  if(days < 5 && hours !== 0)
    result += ` ${hours} ${strings.p(hours, 'час', 'часа', 'часов')}`;
  if(days === 0 && minutes !== 0)
    result += ` ${minutes} ${strings.p(minutes, 'минуту', 'минуты', 'минут')}`;

  return result;
}

const TimePassedSince = function({time, reversedView}) {
  let text = passedSince(time),
      tooltip = formatDate(time);

  if(reversedView) {
    let tmp = text;
    text = tooltip;
    tooltip = tmp;
  }

  return (
    <Tooltip content={tooltip}>
      <span className='time-passed-since'>{text}</span>
    </Tooltip>
  );
}
export default TimePassedSince;
