class PageTransition extends Component {
  static saveDom() {
    this.dom = jQuery('#app').clone()
  }

  componentDidMount() {
    if (this.constructor.dom) {
      jQuery(this.refs.root).append(this.constructor.dom)
    }
  }

  render() {
    return (
      <div ref="root" className='page-transition'>
        <div ref='curtain' className='page-transition__curtain' />
      </div>
    )
  }
}

export default PageTransition
