class DraggableTrHeader extends Component {
  state = {
    draggable: true,
    draggedOverClass: ''
  };

  handleDragStart(e, headerName, handler) {
    e.dataTransfer.setData('headerName', headerName);
    handler();
  }

  handleDrop(e, handler) {
    this.setState({ draggedOverClass: '' });
    handler(e);
  }

  handleDragOver(e, getPreferPersonal) {
    e.preventDefault();
    if (e.dataTransfer.types[0] === 'id') {
      return;
    }

    const currentHeaderName = e.currentTarget.innerText.split('\t')[1];
    if (getPreferPersonal()) {
      this.setState({ draggedOverClass: ((currentHeaderName === 'Личные') ? 'dragged-over' : 'dragged-over-reversed') });
    } else {
      this.setState({ draggedOverClass: ((currentHeaderName === 'Личные') ? 'dragged-over-reversed' : 'dragged-over') });
    }
  }

  render() {
    const {colSpan, headerName, getPreferPersonal, onDragStart, onDrop} = this.props;
    /*
       Работаем на Drag'n'Drop API HTML5.
       Чтобы они работали, обзательно проставить draggable для элемента.
       - onDragStart срабатывает, когда элемент начинают тащить,
       - onDrop срабатывает на элементе, над которым отпустили перетаскиваемый,
       - onDragOver срабатывает, на элементе, над которым "протаскивают" перетаскиваемый,
       - onDragLeave срабатывает, когда перетаскиваемый элемент перестаёт находиться над этим
    */
    return (
      <tr
        className={this.state.draggedOverClass}
        draggable={this.state.draggable /* не даём таскать за обычные колонки */}
        onDragStart={(e) => this.handleDragStart(e, headerName, onDragStart)}
        onDrop={(e) => this.handleDrop(e, onDrop)}
        onDragOver={(e) => this.handleDragOver(e, getPreferPersonal)}
        onDragLeave={() => this.setState({ draggedOverClass: '' })} >
        <td>
          <Icon clickable name='drag_handle'/>
        </td>
        <td
          key={headerName}
          colSpan={colSpan} 
          className='td-header'
          onMouseEnter={()=>this.setState({draggable: false})} /* чтобы не включался Drag при, например, выделении */
          onMouseLeave={()=>this.setState({draggable: true})} >
          {headerName}
        </td>
      </tr>
    );
  }
}

export default DraggableTrHeader;
