class SpamMarker extends Component {
  state = {};

  sendData(email) {
    const repo = new repositories.EmailsRepo();
    repo.toggleSpam(email.id, {email: {spam: email.spam}})
        .then(e => this.setState(email));
  }

  onClickHandler(e, email) {
    e.stopPropagation();
    email.spam = !email.spam;
    this.sendData(email);
  }

  render() {
    const {email} = this.props;
    const is_spam = email.spam;

    return(
      <div className={'is-spam spam-' + is_spam } onClick={(e) => {this.onClickHandler(e, email)}}>
        <Icon name={is_spam ? 'error' : 'error_outline'}
              title={is_spam ? 'Убрать из спама' : 'Пометить как спам'}/>
      </div>
    )
  }
}

export default SpamMarker;
