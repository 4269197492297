import cookie from 'cookie'

/*
 *
 * Use promise to unify repositories
 * (in case we won't use JQuery in future or some cases)
 *
 */
class AnswerTemplateRepo {
  getAll(params = {}) {
    if (!params.group_id) {
      params.group_id = cookie.parse(document.cookie).g
    }
    const promise = new Promise(function(resolve, reject) {
      const mapToEntities = answerTemplates => answerTemplates.map(t => new entities.AbstractEntity(t));
      API.answerTemplates.getAll({
        params,
        success: answerTemplates => resolve(mapToEntities(answerTemplates)),
        error: reject
      });
    });

    return promise;
  }
}

export default AnswerTemplateRepo;
