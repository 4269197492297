import {connect} from 'react-redux'
import {fetchObjectSubset} from 'app/lib/utils'
import {reloadTags} from 'app/store/helpers'

class EditArticlePage extends Component {
  state = {}

  componentDidMount() {
    this.getData();
  }

  getData() {
    const {currentUser} = this.props;
    if(!currentUser || this.state.dataLoading)
      return;

    this.setState({ dataLoading: true });
    const repo = new repositories.ArticleRepo();
    repo.get(this.props.match.params.id)
        .then(article => this.setState({ article, dataLoading: false }))
        .catch(data => {
          if(data.status === 403)
            return browserHistory.push('/staff');
          this.setState({ dataLoading: false });
        })
  }

  render() {
    const article= this.state.article;

    return (
      <layouts.Staff>
        <Card stretch>
          <h2>Статьи</h2>
          <Margin size="20" />
          {article ? <staff.Article onSave={data => this.setState({ article: data.article })}
                                           article={article} /> : '...'}
        </Card>
      </layouts.Staff>
    );
  }
}

const mapStateToProps = s => fetchObjectSubset(s, ['currentUser']);
export default connect(mapStateToProps)(EditArticlePage);
