const Parameters = function({values, statisticType, onChange}) {
  values = values || {};
  const valuesForTable = []

  const elements = statisticType.parametersAsArray().map(
    p => {
    if (p.type === 'tag_id_array' || p.type === 'category_id_array') {
      valuesForTable.push(p)
      return
    }
    return <staff.statistic.Parameter key={p.type + p.name}
                                           onChange={v => onChange({ [p.name]: v })}
                                           value={values[p.name]}
                                           info={p} />
    }
  );

  const tableElements = valuesForTable.map(
    p => <staff.statistic.Parameter key={p.type + p.name}
                                    onChange={v => onChange({ [p.name]: v })}
                                    value={values[p.name]}
                                    info={p} />
  )

  return (
    <div>
      <div className='parameters-table'>
        {tableElements}
      </div>
      {elements}
    </div>
  );
}

export default Parameters;
