export default {
  dashCase(string) {
    return string
      .replace(/([a-z0-9])([A-Z]+)/g, '$1-$2')
      .replace(/_+/g, '-')
      .toLowerCase()
  },

  underscoreCase(string) {
    return string
      .replace(/([a-z0-9])([A-Z]+)/g, '$1_$2')
      .toLowerCase()
  },

  camelCase(string) {
    return string.replace(/[-_]+(.)/g, (f, g) => g.toUpperCase())
  },

  p(number, ...strings) {
    const cases = [2, 0, 1, 1, 1, 2]
    return strings[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ]
  }
}
