export default (globbed) => {
  let result = {}
  for (const [key, value] of Object.entries(globbed)) {
    // result[key.replaceAll('$', ' -> ')] = value

    let current_level = result;
    let levels = key.split('$')
    let last_level = levels.pop()
    for(const level of levels) {
      if(!current_level[level])
        current_level[level] = {}
      current_level = current_level[level]
    }
    if(last_level == 'index') continue;
    current_level[last_level] = value
  }
  return result;
}
