import PropTypes from 'prop-types';
import Select from 'react-select'
import {connect} from 'react-redux';
import {findById, fetchObjectSubset} from 'app/lib/utils'
import {sortedScripts} from 'app/store/mapping_helpers'
import StaffMessageForm from './StaffMessageForm'

// Message, status/assignee setting, etc
class ControlBlock extends Component {
  static propTypes = {
    ticket: PropTypes.object.isRequired,
    triggerDataReload: PropTypes.func.isRequired, // #getData from parent
    dispatchState: PropTypes.func.isRequired,  // #setState from parent
    showErrorNotice: PropTypes.func.isRequired,  // from parent
    formIdSuffix: PropTypes.string,
    from_number_search: PropTypes.bool, // from location

    /***** REDUX *****/
    managers: PropTypes.arrayOf(PropTypes.object).isRequired,
    activeManagers: PropTypes.arrayOf(PropTypes.object).isRequired,
    scripts: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  state = {}

  componentDidMount() {
    API.me.get({
      success: (data) => this.setState({ admin: data.admin }),
      error: (error) => console.log(error),
    });
  }

  getMessageFormText() {
    return this.refs.staffMessageForm.getText();
  }
  setMessageFormText(txt) {
    this.refs.staffMessageForm.setText(txt);
  }

  showMessageForm() {
    this.refs.staffMessageForm.show();
  }

  focusMessageForm() {
    this.refs.staffMessageForm.focus();
  }

  render() {
    const {ticket, tags, formIdSuffix, triggerDataReload} = this.props;
    const {selectedScript} = this.state;

    if (!ticket)
      return null;

    return (
      <div>
        <Margin size="30" />
        {selectedScript && this.renderScriptModal()}

        <TagList tags={ticket.tags} onDelete={id => this.removeTag(id)}
                        onAdd={id => this.addTag(id)} availableTags={tags}
                        showShortName />
        <Margin size="8" />
        {this.renderManagerAssignMenu()}
        <Margin size="8" />
        <Spaced w={15} h={15}>
          {this.renderStatusMenu()}
          {this.renderScriptMenu()}
          {ticket.hidden_from_autotake || this.renderWaitingForColleaguesButton()}
        </Spaced>

        <Margin size="15" />

        <StaffMessageForm ref='staffMessageForm'
                          ticket={ticket}
                          triggerDataReload={triggerDataReload}
                          formIdSuffix={formIdSuffix}
                          showErrorsFromResponse={data => this.showErrorsFromResponse(data)} />
      </div>
    );
  }

  formId() {
    const {ticket, formIdSuffix} = this.props;
    let result = `message-form-${ticket.id}`;
    if (formIdSuffix)
      result = `${result}-${formIdSuffix}`;
    return result;
  }

  removeTag(id) {
    const {ticket, dispatchState, showErrorNotice} = this.props;
    if(ticket.tags.length < 2) {
      showErrorNotice('У тикета должен остаться по меньшей мере один тег');
      return;
    }

    API.tickets.removeTag({
      id: ticket.id,
      params: { tag_id: id },
      success: ticket => dispatchState({ ticket }),
      error: data => this.showErrorsFromResponse(data),
    });
  }

  addTag(id) {
    const {ticket, dispatchState} = this.props;

    API.tickets.addTag({
      id: ticket.id,
      params: { tag_id: id },
      success: ticket => dispatchState({ ticket }),
      error: data => this.showErrorsFromResponse(data),
    });
  }

  renderStatusMenu() {
    const {ticket, managers, triggerDataReload, dispatchState} = this.props;
    const {messageType} = this.state;

    const items = [
      { id: 'draft',    label: 'Черновик' },
      { id: 'open',     label: 'Открыт' },
      { id: 'waiting',  label: 'Отвечен' },
      { id: 'closed',   label: 'Закрыт' },
      { id: 'archived', label: 'В архиве' },
    ]

    const onSuccess = ticket => {
      dispatchState({ ticket });
      triggerDataReload();
    }
    const setStatus = status => API.tickets.setStatus({ id: ticket.id,
                                                        params: { status },
                                                        success: onSuccess,
                                                        error: data => this.showErrorsFromResponse(data) });
    return (
      <DropdownTrigger noUnderline items={items} onSelect={setStatus}>
        <Button outline icon='autorenew'>Сменить статус</Button>
      </DropdownTrigger>
    );
  }

  renderManagerAssignMenu() {
    const {ticket, dispatchState, from_number_search} = this.props;
    const managers = this.props.activeManagers;
    const {messageType} = this.state;

    const itemsForAssignMenu = managers.filter(m => !ticket.manager || m.id != ticket.manager.id)
                                       .map(m => ({ value: m.id, label: m.full_name }));

    itemsForAssignMenu.unshift({ value: 'me', label: <strong className="text-cyan">Мне</strong> });
    ticket.manager && itemsForAssignMenu.unshift({ value: null, label: <strong className="text-cyan">Освободить</strong> });

    const assign = manager_id => API.tickets.assign({ id: ticket.id,
                                                      params: { manager_id, from_number_search },
                                                      success: t => dispatchState({ ticket: t }),
                                                      error: data => this.showErrorsFromResponse(data) });
    return (
      <Select
        options={itemsForAssignMenu}
        onChange={assign}
        simpleValue={true}
        placeholder={'Сменить ответственного…'}
      />
    );
  }

  renderWaitingForColleaguesButton() {
    const {ticket, dispatchState} = this.props;
    const onSuccess = t => {
      dispatchState({ ticket: t });
      API.tickets.autotake({
        success: ({id}) => window.open(`/staff/${id}`, '_blank').focus(),
        error: () => alert('Не получается взять следующий тикет')
      });
    }
    const onClick = v => API.tickets.update({ id: ticket.id,
                                              params: { hidden_from_autotake_at: new Date() },
                                              success: onSuccess,
                                              error: data => this.showErrorsFromResponse(data) });

    const hint = 'На час убирает тикет из очереди для кнопки "взять тикет" и берет следующий';
    return (
      <Tooltip wrap content={hint}>
        <Button onClick={onClick}>
          Жду ответа коллег
        </Button>
      </Tooltip>
    );
  }

  renderScriptMenu() {
    const {messageType, selectedScript} = this.state;
    const {ticket, scripts, triggerDataReload, userSettings} = this.props;

    const [personalScripts, systemScripts] = scripts.reduce((res, elem) => {
      res[elem.personal ? 0 : 1].push(elem);
      return res;
    }, [[], []]);

    const personalItems = personalScripts.map(s => ({ id: s.id, label: s.name }));
    const systemItems = systemScripts.map(s => ({ id: s.id, label: s.name }));

    const preferPersonalScripts = (userSettings && !jQuery.isEmptyObject(userSettings))
      ? userSettings.preferPersonalScripts
      : false;

    const items = [];
    if (preferPersonalScripts) {
      if (personalItems.length > 0) {
        items.push('Личные');
        items.push(...personalItems);
      }
      if (systemItems.length > 0) {
        items.push('Системные');
        items.push(...systemItems);
      }
    } else {
      if (systemItems.length > 0) {
        items.push('Системные');
        items.push(...systemItems);
      }
      if (personalItems.length > 0) {
        items.push('Личные');
        items.push(...personalItems);
      }
    }

    const openScriptModal = scriptId => this.setState({ selectedScript: findById(scriptId, scripts) });

    return (
      <DropdownTrigger noUnderline items={items} onSelect={openScriptModal}>
        <Button outline icon='queue'>Сценарии</Button>
      </DropdownTrigger>
    );
  }

  renderScriptModal() {
    const {ticket, triggerDataReload} = this.props;
    const {selectedScript} = this.state;

    return (
      <staff.ScriptExecution onHide={() => this.setState({ selectedScript: null })}
                                    onSuccess={triggerDataReload}
                                    ticket={ticket}
                                    script={selectedScript} />
    );
  }

  showErrorsFromResponse({responseJSON}) {
    if(responseJSON && responseJSON.errors) {
      this.props.showErrorNotice(responseJSON.errors.join("; \n"));
    } else {
      this.props.showErrorNotice("unknown error");
    }
  }
}

const mapStateToProps = s => Object.assign(fetchObjectSubset(s, ['tags', 'managers', 'activeManagers']),
                                           { scripts: sortedScripts(s), userSettings: s.userSettings });
export default connect(mapStateToProps, null, null, { forwardRef: true })(ControlBlock);
