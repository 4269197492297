import NotificationForm from "./partials/NotificationForm";

const notificationRepo = new repositories.NotificationRepo()

class EditNotificationsPage extends Component {
  state = { desc: {}, date: {}, push: false, push_date: {} }

  componentDidMount() {
    this.getNotificationInfo()
  }

  getNotificationInfo() {
    notificationRepo.getAll().then((response) => {
      this.setState({
        desc: response.description,
        date: response.expires_at,
        push_date: response.mobile_push_sent_at })
    })
  }

  render() {
    const { desc, date, push, push_date, errors } = this.state;

    const onChangeDesc = change => {
      this.setState({ desc: change });
    }

    const onChangeDate = change => {
      this.setState({ date: change });
    }

    const onChangePush = change => {
      this.setState({ push: change });
    }

    const onSave = () => notificationRepo.update({desc: desc, date: date, push: push}).then(
      () => browserHistory.push(`/staff/notifications`),
      (error) => this.showErrorsFromResponse(error)
    )

    return (
      <layouts.Staff>
        <Card stretch>
          <h2>Редактировать уведомление</h2>
          <NotificationForm desc={desc} date={date} push_date={push_date}
              onChangeDesc={onChangeDesc} onChangeDate={onChangeDate}
              onChangePush={onChangePush} onSave={onSave} />
          <Margin size="10" />
          {errors && <ErrorNotice errors={errors} />}
          <Margin size="10" />
        </Card>
      </layouts.Staff>
    )
  }

  showErrorsFromResponse({responseJSON}) {
    let errors = ["unknown error"];
    if(responseJSON && responseJSON.errors) {
      errors = responseJSON.errors;
    }

    clearTimeout(this.state.errorsHidingTimeout);
    this.setState({
      errors,
      errorsHidingTimeout: setTimeout(() => this.hideErrorNotice(), 5000)
    });
  }
}
export default EditNotificationsPage;
