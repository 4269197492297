class RateTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {opinion, text} = this.state

    return (
      <div className='rate-ticket'>
        <div className='rate-ticket__label'>
          Если проблема решена, оцените работу нашей службы поддержки.
          Обратите внимание, что обращение будет перенесено в архив.
        </div>
        <div className="rate-ticket__emotions">
          <RatingIcon opinion='good'
                             size={90}
                             uncolored={opinion != 'good'}
                             onClick={() => this.setState({ opinion: 'good' })} />
          <RatingIcon opinion='neutral'
                             size={90}
                             uncolored={opinion != 'neutral'}
                             onClick={() => this.setState({ opinion: 'neutral' })} />
          <RatingIcon opinion='bad'
                             size={90}
                             uncolored={opinion != 'bad'}
                             onClick={() => this.setState({ opinion: 'bad' })} />
        </div>

        {
          <div>
            <Textarea placeholder="Ваш отзыв" onChange={text => this.setState({ text: text })} value={text} />
            <div className="rate-ticket__controls">
              <Button onClick={() => this.onSubmit()}>СОХРАНИТЬ</Button>
            </div>
          </div>
        }
      </div>
    )
  }

  onSubmit() {
    const {opinion, text} = this.state;

    if(['bad', 'good', 'neutral'].indexOf(opinion) == -1)
      return;

    this.props.onSelect(opinion, text);
  }
}


export default RateTicket;
