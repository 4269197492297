/*
 *
 * Use promise to unify repositories
 * (in case we won't use JQuery in future or some cases)
 *
 */

import cookie from 'cookie'

class TicketRepo {
  getAll(params) {
    if (!params.group_id) {
      params.group_id = cookie.parse(document.cookie).g
    }
    const promise = new Promise(function(resolve, reject) {
      const toEntities = data => ({ tickets: data.tickets.map(e => new entities.Ticket(e)), pagination: data.pagination });
      API.tickets.getAll({
        params,
        success: data => resolve(toEntities(data)),
        error: reject,
      });
    });

    return promise;
  }

  get(id) {
    const promise = new Promise(function(resolve, reject) {
      API.tickets.get({
        id,
        success: data => resolve(new entities.Ticket(data)),
        error: reject,
      });
    });

    return promise;
  }

  getUnreadStatuses(ticketIds) {
    const promise = new Promise(function(resolve, reject) {
      API.tickets.unreadStatus({
        params: { ids: ticketIds },
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }

  rename(id, name) {
    const promise = new Promise(function(resolve, reject) {
      API.tickets.rename({
        id,
        params: { name },
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }

  getAutoCategories(ticketId) {
    const promise = new Promise(function (resolve, reject) {
      API.tickets.getAutoCategories({
        id: ticketId,
        success: data => resolve(data),
        error: reject,
      });
    });

    return promise;
  }
}

export default TicketRepo;
