const StatusIcon = function({status}) {
  switch(status) {
    case 'draft':
      return <Icon green name='edit_note'/>;
    case 'open':
      return <Icon yellow name='help_outline' />;
    case 'waiting':
      return <Icon green name='schedule' />;
    case 'closed':
      return <Icon green name='done' />;
    default:
      throw 'StatusIcon: unexpected status';
  }
}

export default StatusIcon;
