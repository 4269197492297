import PropTypes from 'prop-types';

class Article extends Component {
  static propTypes = {
    article: PropTypes.object.isRequired,
    onSave: PropTypes.func,
  }

  constructor(props) {
    super(props);
    const {article} = props;
    this.state = { content: article.content,
                   title: article.title,
                   published: article.published };
  }

  render() {
    const {article} = this.props;
    const {content, title, published, previewHtml, saveInProgress} = this.state;

    return (
      <div className='staff-article'>
        <div className='staff-article__header'>Заголовок</div>
        <TextInput value={title} onChange={v => this.setState({ title: v })} />
        <div className='staff-article__header'>Текст</div>
        <Textarea value={content} onChange={v => this.setState({ content: v })} />
        <Checkbox label='Опубликовать' checked={published} onChange={v => this.setState({ published: v })} />
        <br /><br />

        <Button onClick={() => this.preview()}>Предпросмотр</Button>
        <br />
        {this.isChanged() && <Button disabled={saveInProgress} onClick={() => this.save()}>Сохранить</Button>}

        {previewHtml && <TrustedHtml className='staff-article__preview' content={previewHtml} />}
      </div>
    )
  }

  preview() {
    this.setState({ previewHtml: this.state.content });
  }

  isChanged() {
    const {article} = this.props;
    return this.state.content != article.content ||
           this.state.title != article.title ||
           this.state.published != article.published;
  }

  save() {
    this.setState({ saveInProgress: true });
    const params = { title: this.state.title,
                     content: this.state.content,
                     published: this.state.published};
    const id = this.props.article.id;
    const repo = new repositories.ArticleRepo();
    if(id) {
      params.id = id
      repo.update(params)
          .then(data => {
            this.setState({ saveInProgress: false });
            this.props.onSave && this.props.onSave(data);
          })
          .catch(data => console.log(data))
    } else {
      repo.create(params)
          .then(data => {
            this.props.article.id = data.id; // HACK: just in case #save will be fired multiple times
            this.setState({ saveInProgress: false });
            this.props.onSave && this.props.onSave(data);
          })
    }
  }
}
export default Article;
