import TagForm from 'app/pages/staff/tags/partials/TagForm'
import cookie from 'cookie'

const tagRepo = new repositories.TagRepo();

class NewTagPage extends Component {
  state = { data: {} }

  componentDidMount() {
    this.setState({ data: {} });
  }

  render() {
    const {data, errors} = this.state;
    data.group_id = cookie.parse(document.cookie).g

    const onChange = change => {
      Object.assign(data, change);
      this.forceUpdate();
    };

    const onSave = () => tagRepo.save(data).then(
      tag => browserHistory.push(`/staff/tags/${tag.id}`),
      data => this.showErrorsFromResponse(data)
    );

    return(
      <layouts.Staff>
        <Card stretch>
          <h2>Новый тег</h2>
          <Margin size='25' />
          {errors && <ErrorNotice errors={errors} />}
          <Margin size="10" />
          <TagForm tag={data} onChange={onChange} onSave={onSave} />
          <Margin size="10" />
          {errors && <ErrorNotice errors={errors} />}
          <Margin size="10" />
        </Card>
      </layouts.Staff>
    );
  }

  showErrorsFromResponse({responseJSON}) {
    let errors = ["unknown error"];
    if(responseJSON && responseJSON.errors) {
      errors = responseJSON.errors;
    }

    clearTimeout(this.state.errorsHidingTimeout);
    this.setState({
      errors,
      errorsHidingTimeout: setTimeout(() => this.hideErrorNotice(), 5000)
    });
  }

  hideErrorNotice() {
    this.setState({ errors: null });
  }
}

export default NewTagPage;
