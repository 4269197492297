import {bemModifiers} from 'app/lib/utils'

class DropdownTrigger extends Component {
  state = { show: false }

  componentDidMount() {
    jQuery(window).mouseup(this.onDocumentMouseup)
  }

  componentWillUnmount() {
    jQuery(window).off('mouseup', this.onDocumentMouseup)
  }

  onDocumentMouseup = (event) => {
    if (this.state.show && !jQuery.contains(this.refs.root, event.target)) {
      this.setState({ show: false })
    }
  }

  render() {
    const {items, right, onSelect, children, noUnderline, itemRenderer} = this.props
    const {show} = this.state

    const blockClass = bemModifiers('dropdown-trigger', [show && 'visible']);
    const triggerClass = bemModifiers('dropdown-trigger__trigger', [noUnderline && 'no-underline']);

    return (
      <div className={blockClass} ref='root'>
        <div className={triggerClass} onClick={() => this.toggleDropdown()}>{children}</div>
        {
          show &&
          <Dropdown itemRenderer={itemRenderer}
                    right={right}
                    items={items}
                    onSelect={item => this.onSelect(item)}
                    trigger={this} />
        }
      </div>
    )
  }

  onSelect(item) {
    this.setState({ show: false });
    this.props.onSelect(item)
  }

  toggleDropdown() {
    this.setState({ show:  !this.state.show && this.props.items.length > 0 });
  }
}

export default DropdownTrigger;
