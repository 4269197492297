import {connect} from 'react-redux'
import {reloadManagers} from 'app/store/helpers'
import qs from 'query-string'

class ManagerStatistic extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.needToRefreshData())
      this.getData();

    if(!this.state.statistics)
      return <PageTransition />;

    const cols = this.days();
    cols.unshift('Тек. месяц')
    cols.unshift(''); // first column
    cols.push('Пр. месяц')

    const urlToPage = n => `/staff/statistics/managers?page=${n}`;
    return (
      <layouts.staff.Statistics>
        <Margin size="20 0">
          <h1>
            Статистика по менеджерам за 7 дней
          </h1>
        </Margin>
        <Margin size="0 0 20 0">
          Месяцы считаются с 28 числа по 27 число следующего месяца. Например, с 28.01 по 27.02<br />
          <strong>Форма для месячной статистики:</strong> первых ответов/ответов/закрыто/% положительных отзывов. <br />
          <strong>Для ежедневной:</strong> первых ответов/всего ответов
        </Margin>

        <Table cols={cols}>
          {this.managersPresentedInStatistics().map(t => this.renderManagerRow(t))}
        </Table>
        <Margin size='8' />
        <Pagination data={this.state.pagination}
                           urlGenerator={urlToPage} />
      </layouts.staff.Statistics>
    );
  }

  managersPresentedInStatistics() {
    const statistics = this.state.statistics;

    return this.props.managers.filter(m => this.managerHasAnyStats(m));
  }

  managerHasAnyStats(manager) {
    const statistics = this.state.statistics;
    if(!statistics.week[0][manager.id])
      return false;

    const sumOfStats = s => s.answers + s.closed + s.first_answers + s.negative + s.positive;
    const statHasNumbers = s => sumOfStats(s) > 0;

    if(statHasNumbers(statistics.current_month[manager.id]))
      return true;
    if(statHasNumbers(statistics.previous_month[manager.id]))
      return true;

    for(let dayStats of statistics.week) {
      if(statHasNumbers(dayStats[manager.id])) {
        return true;
      }
    }

    return false;
  }

  currentPage() {
    return qs.parse(this.props.location.search).page || 1;
  }

  needToRefreshData() {
    return this.currentPage() != this.state.loadedPage;
  }

  getData() {
    const page = this.currentPage();
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.loadedPage = page; // мы в render сейчас
    API.statistics.managers.get({ success: response => this.setState(response),
                                  params: { page: page },
                                  error: data => console.log(data) });

    reloadManagers()
  }

  days() {
    const now = Date.now();
    const millisInDay = 1000 * 60 * 60 * 24;
    const monthNames = ["янв", "фев", "мар", "апр", "мая", "июн", "июл", "авг", "сен", "окт", "нояб", "дек"];
    const result = [6, 5, 4, 3, 2, 1, 0].map(i => new Date(now - i * millisInDay))
                                        .map(d => `${d.getDate()} ${monthNames[d.getMonth()]}`);

    return result;
  }

  renderManagerRow(manager) {
    return (
      <tr key={manager.id}>
        <td><Person person={manager} /></td>
        <td>{this.renderCurrentMonthStats(manager)}</td>
        <td>{this.renderDayStats(manager, 0)}</td>
        <td>{this.renderDayStats(manager, 1)}</td>
        <td>{this.renderDayStats(manager, 2)}</td>
        <td>{this.renderDayStats(manager, 3)}</td>
        <td>{this.renderDayStats(manager, 4)}</td>
        <td>{this.renderDayStats(manager, 5)}</td>
        <td>{this.renderDayStats(manager, 6)}</td>
        <td>{this.renderPreviousMonthStats(manager)}</td>
      </tr>
    );
  }

  renderDayStats(manager, day) {
    const stats = this.managerDayStats(manager, day);
    const rates = stats.total_rates;
    const positivePercentage = rates === 0 ? '-' : Math.floor(stats.positive / rates * 100);
    return (
      <span>
        {stats.first_answers}/{stats.answers}
      </span>
    );
  }

  managerDayStats(manager, day) {
    return this.state.statistics.week[day][manager.id];
  }


  renderCurrentMonthStats(manager) {
    return this.renderMonthStats(this.state.statistics.current_month[manager.id]);
  }

  renderPreviousMonthStats(manager) {
    return this.renderMonthStats(this.state.statistics.previous_month[manager.id]);
  }

  renderMonthStats(stats) {
    const rates = stats.total_rates;
    const positivePercentage = rates === 0 ? '-' : Math.floor(stats.positive / rates * 100);
    return (
      <span>
        {stats.first_answers}/{stats.answers}/{stats.closed}/{positivePercentage}
      </span>
    );
  }
}

const mapStateToProps = s => ({ managers: s.managers });
export default connect(mapStateToProps)(ManagerStatistic);
