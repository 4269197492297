import { connect } from 'react-redux'
import { reloadTags } from 'app/store/helpers'

class TagStatistic extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getData();
  }

  render() {
    if (!this.state.statistics) {
      return <PageTransition />
    }

    const cols = this.days();
    cols.unshift('Тек. месяц');
    cols.unshift(''); // first column
    cols.push('Пр. месяц');

    return (
      <layouts.staff.Statistics>
        <Margin size="20 0">
          <h2>
            Статистика по тегам за 7 дней <small>(поступило/закрыто/первых ответов/% положительных отзывов)</small>
          </h2>
        </Margin>

        <Table cols={cols}>
          {this.tagsPresentedInStatistics().map(t => this.renderTagRow(t))}
        </Table>
      </layouts.staff.Statistics>
    );
  }

  tagsPresentedInStatistics() {
    const weekStatistics = this.state.statistics.week;
    return this.props.tags.filter(t => weekStatistics[0][t.id]);
  }

  getData() {
    API.statistics.tags.get({ success: data => this.setState(data),
                              error: data => console.log(data) });

    reloadTags();
  }

  days() {
    const now = Date.now();
    const millisInDay = 1000 * 60 * 60 * 24;
    const monthNames = ["янв", "фев", "мар", "апр", "мая", "июн", "июл", "авг", "сен", "окт", "нояб", "дек"];
    const result = [6, 5, 4, 3, 2, 1, 0].map(i => new Date(now - i * millisInDay))
                                        .map(d => `${d.getDate()} ${monthNames[d.getMonth()]}`);

    return result;
  }

  renderTagRow(tag) {
    return (
      <tr key={tag.id}>
        <td>{tag.name} <Tag data={tag} /></td>
        <td>{this.renderCurrentMonthStats(tag)}</td>
        <td>{this.renderDayStats(tag, 0)}</td>
        <td>{this.renderDayStats(tag, 1)}</td>
        <td>{this.renderDayStats(tag, 2)}</td>
        <td>{this.renderDayStats(tag, 3)}</td>
        <td>{this.renderDayStats(tag, 4)}</td>
        <td>{this.renderDayStats(tag, 5)}</td>
        <td>{this.renderDayStats(tag, 6)}</td>
        <td>{this.renderPreviousMonthStats(tag)}</td>
      </tr>
    );
  }

  renderDayStats(tag, day) {
    const stats = this.tagDayStats(tag, day);
    const rates = stats.total_rates;
    const positivePercentage = rates === 0 ? '-' : Math.floor(stats.positive / rates * 100);
    return (
      <span>
        {stats.created}/{stats.closed}/{stats.first_answer}/{positivePercentage}
      </span>
    );
  }

  tagDayStats(tag, day) {
    return this.state.statistics.week[day][tag.id];
  }

  renderCurrentMonthStats(tag) {
    return this.renderMonthStats(this.state.statistics.current_month[tag.id]);
  }

  renderPreviousMonthStats(tag) {
    return this.renderMonthStats(this.state.statistics.previous_month[tag.id]);
  }


  renderMonthStats(stats) {
    const rates = stats.total_rates;
    const positivePercentage = rates === 0 ? '-' : Math.floor(stats.positive / rates * 100);
    return (
      <span>
        {stats.created}/{stats.closed}/{stats.first_answer}/{positivePercentage}
      </span>
    );
  }
}

const mapStateToProps = state => ({ tags: state.tags });
export default connect(mapStateToProps)(TagStatistic);
