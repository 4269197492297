class UsersPage extends Component {
  state = {
    id: '',
    user: null,
    dataLoading: false,
    searched: false,
  }

  handleChange = (id) => {
    this.setState({ id });
};

  handleSearch = () => {
    this.setState({ dataLoading: true, searched: true })
    const repo = new repositories.UserRepo();
    repo.get(this.state.id)
      .then((user) => {
        this.setState({
          user,
          dataLoading: false,
        });
      })
      .catch(data => {
        if (data.status === 403) {
          return browserHistory.push('/staff');
        }

        this.setState({dataLoading: false, user: null});
      })
  }

  handleGoToSettings = () => {
    if (this.state.user) {
        browserHistory.push(`/staff/users/${this.state.user.id}`)
    }
  };

  render() {
    const { id, user, searched, dataLoading } = this.state;

    return (
        <layouts.Staff>
        <Card stretch>
            <h2>User ID</h2>
            <Margin size="20"/>
            <TextInput value={id} onChange={this.handleChange} placeholder="Введите ID пользователя"/>
            <Margin size="20"/>
            <Button onClick={this.handleSearch}>Найти</Button>
            <Margin size="20"/>
            { searched && !dataLoading && !user && (
              <div>Пользователь не найден</div>
            ) }
            { user && (
              <Button onClick={this.handleGoToSettings}>
                Перейти на страницу настроек
              </Button>
            ) }
        </Card>
      </layouts.Staff>
    );
  }
}

export default UsersPage;
