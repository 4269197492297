import {findById} from 'app/lib/utils'
import {connect} from 'react-redux'
import {loadUser} from 'app/store/helpers'

const UserById = function({id, short, usersById, doNotLoad}) {
  id = id - 0; // int conversion
  const u = usersById[id];

  // TODO: throw exception?
  if(!u) {
    if(!doNotLoad && !(id in usersById)) {
      loadUser(id);
    }
    return <span>{id}</span>;
  }

  return <Person person={u} short={short} />;
}

const mapStateToProps = s => ({ usersById: s.usersById });
export default connect(mapStateToProps)(UserById);
