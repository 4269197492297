import {bemModifiers} from 'app/lib/utils';
import PropTypes from 'prop-types';

class Person extends Component {
  static propTypes = {
    person: PropTypes.object.isRequired, // manager/user
    short: PropTypes.bool, // name visibility
    onClick: PropTypes.func, // for the whole block
    // name modifiers
    dim: PropTypes.bool,
    white: PropTypes.bool,
  }

  render() {
    const {person, short, hideAvatar} = this.props;
    const {onClick} = this.props;
    const blockClass = bemModifiers('person', [onClick && 'clickable', hideAvatar && 'hide__avatar']);

    return (
      <div className={blockClass} onClick={onClick}>
        <div className="person__content">
          {!hideAvatar && < Avatar person={person} tooltip={short ? person.full_name : null} />}
          {!short && this.renderName()}
        </div>
      </div>
    );
  }

  renderName() {
    const {dim, white} = this.props;
    const modifiers = [dim && 'dim', white && 'white'].filter(e => e);
    return (
      <div className={bemModifiers('person__name', modifiers)}>
        {this.props.person.full_name} {this.props.note}
      </div>
    );
  }
}

export default Person
