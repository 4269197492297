import {bemModifiers} from 'app/lib/utils';

class TextInput extends Component {
  render() {
    const {value, onChange, inline, short, small, type, disabled, error, placeholder} = this.props;
    const modifiers = [inline && 'inline', small && 'small', short && 'short', error && 'error'];
    const className = bemModifiers('text-input', modifiers);

    return (
      <>
        <input value={value || ''}
               onChange={e => onChange(e.target.value)}
               disabled={disabled}
               placeholder={placeholder}
               className={className}
               type={type || 'text'}
        />
      </>
      
    );
  }
}

export default TextInput;

