class NotificationRepo {
  getAll(params) {
    const promise = new Promise(function (resolve, reject) {
      API.notifications.getAll({
        params,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }

  update(notification) {
    const promise = new Promise(function (resolve, reject) {
      API.notifications.update({
        params: {
          "description": notification.desc,
          "expires_at": notification.date,
          "mobile_push_sent_at": notification.push
        },
        success: t => resolve(new entities.Notification(t)),
        error: reject,
      });
    });

    return promise;
  }

  current(params) {
    const promise = new Promise(function (resolve, reject) {
      API.notifications.current({
        params,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }

  create(notification) {
    const promise = new Promise(function (resolve, reject) {
      API.notifications.create({
        params: {
          "description": notification.desc,
          "expires_at": notification.date,
          "mobile_push_sent_at": notification.push
        },
        success: t => resolve(new entities.Notification(t)),
        error: reject
      });
    });

    return promise;
  }

  destroy() {
    return new Promise(function(resolve, reject) {
      API.notifications.delete({
        success: resolve,
        error: reject,
      });
    });
  }
}

export default NotificationRepo;
