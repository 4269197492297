function Plan({name, trial, freePlan, imsider}) {
  if(!name) {
    return null;
  }

  const highlightStyle = { fontWeight: 'bold', color: 'red' };

  let nameElement = name;
  let highLightIf = (sourceName, destName) => sourceName.toLowerCase().startsWith(destName)
  if (highLightIf(name, "стартовый") || freePlan) {
    nameElement = (
      <span style={highlightStyle}>
        {name
          .trim()
          .split(/[\s]+/)[0]
          .toLowerCase()
          .replace(/^\W/, (c) => c.toUpperCase())}
      </span>
    );
  }
  var modifiers = []
  if (trial && !freePlan) modifiers.push('Триал')
  if (imsider) modifiers.push('Imsider')

  return <span>тариф {nameElement}{modifiers.length > 0 ? <span style={highlightStyle}> ({modifiers.join(' ')})</span> : null}</span>;
}

class TicketInfoCard extends Component {
  render() {
    return (
      <Spaced h={14}>
        {this.renderGeneralInfo()}
        <br />
        {this.renderRestoreProductsLink()}
        <br />
        {this.renderAccountLink()}
        <br />
        {this.renderLoginAsUserLink()}
        <br />
        {this.renderEkamAccountLink()}
      </Spaced>
    );
  }

  renderGeneralInfo() {
    const {freePlan, ticket} = this.props;
    const {user, plan, account_main_host, account_subdomain, user_agent, referer, by_owner, trial, imsider} = ticket;
    const {account_country} = ticket;

    const htmlLink = (href, content) => <a target="_blank" rel='noopener noreferrer' href={href}>{content}</a>;
    const info = [];

    info.push({ icon: 'email', content: htmlLink(`https://admins.insales.ru/admins/accounts?q=${encodeURIComponent(user.email)}`, user.email) });
    if(by_owner)
      info.push({ icon: null, content: <span><b>Владелец аккаунта</b></span> });
    info.push({ icon: 'account_box', content: <span>Страна: <strong>{account_country}</strong></span> });
    if(user_agent)
      info.push({ icon: 'account_box', content: <Tooltip wrap content={user_agent}><a>Браузер</a></Tooltip> });
    if(plan)
      info.push({ icon: 'account_box', content: <Plan name={plan} trial={trial} freePlan={freePlan} imsider={imsider}/> });

    if(account_subdomain)
      info.push({ icon: 'account_box', content: htmlLink(`http://${account_subdomain}.myinsales.ru`, account_subdomain) });

    if(account_main_host)
      info.push({ icon: 'account_box', content: htmlLink(`http://${account_main_host}`, account_main_host) });

    if(referer)
      info.push({ icon: null, content: <span>Реф.:<br />{referer}</span> });

    const lines = info.map(({icon, content}) => <IconText iconClass="text-light-gray" icon={icon} margin={15} size={16}>{content}</IconText>);

    return lines.map(e => [e, <br />]);
  }

  renderAccountLink() {
    const {ticket} = this.props;
    const id = ticket.account_id;
    const title = ticket.account_title;
    if(!id)
      return null;

    const url = `https://admins.insales.ru/admins/accounts/${id}`;
    return (
      <IconText iconClass="text-light-gray" icon="account_box" margin={15} size={20}>
        <a href={url} target="_blank" rel='noopener referrer'>{title || 'Аккаунт'}</a>
      </IconText>
    );
  }

  renderEkamAccountLink() {
    const {ticket} = this.props;
    const id = ticket.ekam_account_id;
    if(!id)
      return null;

    const url = `https://admins.ekam.ru/admins/accounts/${id}`;
    return (
      <IconText iconClass="text-light-gray" icon="account_box" margin={15} size={20}>
        <a href={url} target="_blank" rel='noopener referrer'>{ 'EKAM аккаунт: ' + id }</a>
      </IconText>
    );
  }

  renderLoginAsUserLink() {
    const id = this.props.ticket.account_id;
    if(!id)
      return null;

    const url = `https://admins.insales.ru/admins/accounts/login_as_user/${id}`;
    return (
      <IconText iconClass="text-light-gray" icon="account_box" margin={15} size={20}>
        <a href={url} target="_blank" rel='noopener referrer'>Войти от имени пользователя</a>
      </IconText>
    );
  }

  renderRestoreProductsLink() {
    const id = this.props.ticket.account_id;
    if(!id)
      return null;

    const url = `https://restore.insales.ru/restorations/new?restoration_name=products_restoration&account_id=${id}`;
    return (
      <IconText iconClass="text-light-gray" icon="account_box" margin={15} size={20}>
        <a href={url} target="_blank" rel='noopener referrer'>Восстановить товары</a>
      </IconText>
    );
  }
}

export default TicketInfoCard;
