import {isUndefined, iterateObject} from 'app/lib/utils'
import {reloadScripts, reloadCurrentUser, reloadManagers, setPreferPersonalScripts} from 'app/store/helpers'
import {actions} from 'app/store/actions'
import {sortedScripts} from 'app/store/mapping_helpers'
import {connect} from 'react-redux'

// /staff/scripts
class ScriptListPage extends Component {
  state = {
    showScripts: true
  }

  componentDidMount() {
    reloadScripts();
    reloadCurrentUser();
    reloadManagers();
    this.updateMissingSortings();
  }

  componentDidUpdate() {
    this.updateMissingSortings();
  }

  updateMissingSortings() {
    const {scripts, sortings, updateSortings} = this.props;
    const missingScripts = scripts.filter(s => isUndefined(sortings[s.id]));
    if(missingScripts.length == 0) {
      return;
    }
    const newSortings = {};
    let index = Object.keys(sortings).length;
    missingScripts.forEach(s => newSortings[s.id] = index++);
    updateSortings(newSortings);
  }

  onDrop(e, dropOverId) {
    const draggedId = e.dataTransfer.getData('id');
    const {sortings} = this.props;
    let currentPosition = sortings[draggedId];
    let toPosition = sortings[dropOverId] - 1;
    this.changeSorting(draggedId, toPosition - currentPosition);
  }

  hideScripts() {
    this.setState({ showScripts: false });
  }

  showScripts(e) {
    const currentHeaderName = e.currentTarget.innerText.split('\t')[1];
    const newHeaderName = e.dataTransfer.getData('headerName');
    if (currentHeaderName === newHeaderName) {
      this.setState({ showScripts: true });
      return;
    }

    setPreferPersonalScripts(!this.getPreferPersonalScripts());
    this.setState({ showScripts: true });
  }

  getPreferPersonalScripts() {
    const {userSettings} = this.props;
    return (userSettings && !jQuery.isEmptyObject(userSettings)) 
      ? userSettings.preferPersonalScripts 
      : false;
  }

  render() {
    const [personalScripts, systemScripts] = this.props.scripts.reduce((res, elem) => {
      res[elem.personal ? 0 : 1].push(elem);
      return res;
    }, [[], []]);

    return (
      <layouts.Staff>
        <Card stretch>
          <Margin size="20 0"><h2>Сценарии</h2></Margin>

          <Button size="xs" color="green" to={"/staff/scripts/new"}>Новый</Button>
          <Margin size="20" />

          <Table cols={['', 'Название', 'Автор', '']}>
            {this.getPreferPersonalScripts()
              ? [
                this.renderTablePart('Личные', personalScripts),
                this.renderTablePart('Системные', systemScripts)
              ] : [
                this.renderTablePart('Системные', systemScripts),
                this.renderTablePart('Личные', personalScripts)
              ]
            }
          </Table>

          <Margin size="20" />
          <Button size="xs" color="green" to={"/staff/scripts/new"}>Новый</Button>
        </Card>
      </layouts.Staff>
    );
  }

  renderTablePart(headerName, scripts) {
    return ([
      scripts.length > 0 && this.renderTableRowHeader(headerName),
      this.state.showScripts && scripts.map(t => this.renderTableRow(t))
    ]);
  }

  renderTableRowHeader(headerName) {
    return (
      <DraggableTrHeader 
        headerName={headerName}
        colSpan={3}
        getPreferPersonal={this.getPreferPersonalScripts.bind(this)}
        onDragStart={this.hideScripts.bind(this)}
        onDrop={this.showScripts.bind(this)} />
    );
  }

  renderTableRow(script) {
    const {name, manager_id} = script;
    /* eslint-disable react/jsx-key */
    const cells = [
      <span>{name}</span>,
      manager_id && <ManagerById short id={manager_id} />,
      this.renderControlButtons(script),
    ];
    /* eslint-enable react/jsx-key */

    return (
      <DraggableTr
        key={script.id}
        cells={cells}
        onDrop={this.onDrop.bind(this)}
        id={script.id}
      />
    );
  }

  renderControlButtons(script) {
    if(!this.hasWriteAccess(script)) {
      return null;
    }

    const deleteScript = () => {
      if (!confirm(`Вы точно хотите удалить ${script.name}?`)) {
        return;
      }
      
      API.scripts.delete({ id: script.id,
                           success: () => reloadScripts(),
                           error: data => console.log(data) });
    }

    return (
      <Spaced w={3}>
        <IconButton icon='edit' to={`/staff/scripts/${script.id}`} />
        <IconButton icon='delete' color='red' onClick={deleteScript} />
      </Spaced>
    );
  }

  changeSorting(id, step) {
    const {sortings, updateSortings} = this.props;
    const fromIndex = sortings[id];
    const toIndex = fromIndex + step;
    const isBetweenPositions = step > 0
                                 ? (index => index > fromIndex && index <= toIndex)
                                 : (index => index < fromIndex && index >= toIndex);

    const change = {};
    iterateObject(sortings, function(id, index) {
      if(isBetweenPositions(index)) {
        change[id] = index + (step > 0 ? -1 : 1);
      }
    });

    change[id] = toIndex;
    updateSortings(change);
  }

  hasWriteAccess(script) {
    const {currentUser} = this.props;
    return currentUser.admin || currentUser.id == script.manager_id;
  }
}

const mapStateToProps = s => ({ scripts: sortedScripts(s),
                                userSettings: s.userSettings,
                                currentUser: s.currentUser,
                                sortings: s.userSettings.scriptSortings || {} });
const mapDispatchToProps = dispatch => ({
  updateSortings: sortings => dispatch(actions.updateScriptSortings(sortings)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ScriptListPage);
