import {bemModifiers} from 'app/lib/utils'

class DraggableTr extends Component {
  state = {
    draggable: true
  };

  handleDrop(e, id, handler) {
    this.setState({ draggedOver: false });
    handler(e, id);
  }

  handleDragOver(e) {
    e.preventDefault();
    this.setState({ draggedOver: true }); // стилями подсветим верхнюю границу
  }

  render() {
    const {id, children, onDrop, cells} = this.props;
    /*
       Работаем на Drag'n'Drop API HTML5.
       Чтобы они работали, обзательно проставить draggable для элемента.
       - onDragStart срабатывает, когда элемент начинают тащить,
       - onDrop срабатывает на элементе, над которым отпустили перетаскиваемый,
       - onDragOver срабатывает, на элементе, над которым "протаскивают" перетаскиваемый,
       - onDragLeave срабатывает, когда перетаскиваемый элемент перестаёт находиться над этим
    */
    return (
      <tr
        className={this.state.draggedOver ? 'dragged-over' : ''}
        draggable={this.state.draggable /* не даём таскать за обычные колонки */}
        onDragStart={(e) => e.dataTransfer.setData("id", id)}
        onDrop={(e) => this.handleDrop(e, id, onDrop)}
        onDragOver={(e) => this.handleDragOver(e)}
        onDragLeave={() => this.setState({ draggedOver: false })} >
        <td>
          <Icon name='drag_indicator'/>
        </td>
        {cells.map((c, i) =>  <td
                                key={i}
                                onMouseEnter={()=>this.setState({draggable: false})} /* чтобы не включался Drag при, например, выделении */
                                onMouseLeave={()=>this.setState({draggable: true})}>
                                {c}
                              </td>)}
      </tr>
    );
  }
}

export default DraggableTr;
