import {findById} from 'app/lib/utils'
import {connect} from 'react-redux'

// props: small, main, withMarginLeft
const CategoryById = function({ id, categories, ...props }) {
  const category = findById(id, categories);
  if(!category)
    return null;

  return <Tag data={category} {...props} />;
}

const mapStateToProps = s => ({ categories: s.categories });
export default connect(mapStateToProps)(CategoryById);