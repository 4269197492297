import AbstractEntity from './AbstractEntity'
// import {isUndefined} from 'app/lib/utils'

class Call extends AbstractEntity {
  getManagerIds() {
    let result = this.chain || [];
    if(this.manager_id && !result.includes(this.manager_id)) {
      result = result.concat(this.manager_id);
    }
    return result;
  }

  getDurations() {
    let result = this.durations || []
    if(this.manager_id && (!this.chain || !this.chain.includes(this.manager_id))) {
      result = result.concat(this.duration);
    }
    return result;
  }
}

export default Call;
