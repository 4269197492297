// /staff/payments
class PaymentList extends Component {
  state = {}
  componentDidMount() {
    this.getData()
  }

  getData() {
    API.payments.getAll({
      success: data => this.setState({ payments: data }),
      error: data => console.log(data),
    });
  }

  render() {
    const {payments} = this.state;
    if (!payments) {
      return <PageTransition />;
    }

    return (
      <layouts.Staff>
        <Card stretch>
          <Margin size="20 0"><h2>Оплаченные счета</h2></Margin>

          <Table cols={['Счет', 'Сумма', 'Аккаунт', 'Комментарий']}>
            {payments.map(e => this.renderTableRow(e))}
          </Table>
        </Card>
      </layouts.Staff>
    );
  }


  renderTableRow(payment) {
    const {invoice_id, account_id, value, subdomain, comment} = payment;
    /* eslint-disable react/jsx-key */
    const cells = [
      <span>{invoice_id}</span>,
      <span>{value}</span>,
      <Link to={`/staff/tickets?account=${account_id}`}>{subdomain}</Link>,
      <span>{comment}</span>,
    ];
    /* eslint-enable react/jsx-key */

    return (
      <tr key={'' + payment.id}>
        {cells.map((c, i) => <td key={i}>{c}</td>)}
      </tr>
    );
  }
}

export default PaymentList;
