const PermissionDeniedPage = function(_props) {
  return (
    <layouts.Staff>
      <Card stretch>
        <h1>Доступ запрещен</h1>
      </Card>
    </layouts.Staff>
  );
}

export default PermissionDeniedPage;
