// /emails/:id

import SpamMarker from './partials/SpamMarker'

class ShowEmailPage extends Component {
  state = {};

  componentDidMount() {
    this.setState({dataLoading: true})
    this.getData();
  }

  getData() {
    const repo = new repositories.EmailsRepo();
    repo.get(this.props.match.params.id)
        .then(email => this.setState({ email, dataLoading: false }));
  }

  renderEmailHeader() {
    const email = this.state.email;
    const is_spam = email.spam;

    return(
      <div className='email-header'>
        <div className='email-info'>
          <p><b>Тема:</b> {email.subject}</p>
          <p><b>От кого:</b> {email.from}</p>
          <p><b>Кому:</b> {email.to}</p>
        </div>
        <SpamMarker email={email} />
      </div>
    )
  }

  render(){
    const email = this.state.email;
    return (
        <layouts.Staff>
          <Card stretch className='email-card'>
          { email &&
            <div>
              {this.renderEmailHeader()}
              <div className='email-body'>
                {email.body.split("\n").map(
                  (line, key) => {return <span key={key}>{line}<br /></span>}
                )
              }
              </div>
            </div>
          }
          </Card>
        </layouts.Staff>
      )
  }
}


export default ShowEmailPage;
