import {bemModifiers} from 'app/lib/utils';
import Linkify from 'react-linkify';

class Message extends Component {
  render() {
    const {createdAt, html, text, person, personNote, attachments} = this.props;
    const {color, selected, viewed} = this.props;
    const {onClick, onDelete, onEdit, onQuote, onChatGPT} = this.props;

    const withControls = (onDelete || onEdit || onQuote)
    const blockModifiers = [selected && 'selected', onClick && 'clickable', withControls && 'controls'].filter(e => e);
    const blockClass = bemModifiers('message', blockModifiers);
    const textClass = bemModifiers('message__text', [color].filter(e => e));
    const lines = this.parseText(text)

    return (
      <div className={blockClass} onClick={onClick}>
        <SplitSides>
          {person ? <Person person={person} note={personNote} /> : <div />}
          <div className='d-flex align-center'>
            {createdAt ? <div className='message__time ml-2'><TimePassedSince time={createdAt} /> назад</div> : <div />}
            {viewed && <div className="message__viewed ml-2"><Icon name='visibility' /></div>}
          </div>
        </SplitSides>
        <div className={textClass}>
          { lines.map((line, i) => {
            if(line.startsWith("|"))
              return <div className="message__quote" key={i}>{this.wrappedLine(line.substring(1))}</div>
            else
              return <div key={i}>{this.wrappedLine(line)}</div>
            })
          }
          {attachments && attachments.length > 0 && 
            <div className="message__files"><AttachmentList files={attachments} /></div>
          }
        </div>
        {withControls &&
          <div className='message__actions'>
            {onDelete && <div onClick={onDelete} className='message__action mr-4'>Удалить</div>}
            {onEdit && <div onClick={onEdit} className='message__action mr-4'>Редактировать</div>}
            {onQuote && <div onClick={() => this.quote()} className='message__action mr-4'>Цитировать</div>}
            {onChatGPT && <div onClick={onChatGPT} className='message__action'>Ответ от ChatGPT</div>}
          </div>
        }
      </div>
    );
  }

  wrappedLine = (line) => {
    return this.props.html ? <TrustedHtml content={line} /> :
    <Linkify componentDecorator={
      (href,text,key)=>{ return <a href={href} key={key} target="_blank" rel="noopener noreferrer">{text}</a>}
    } >{line}</Linkify>;
  }

  parseText = (text) => {
    const allLines = text.split('\n');
    let lines = [];
    let quote = [];
    allLines.forEach((line) => {
      // группируем цитаты в один элемент
      if(line.startsWith('|')) {
        quote.push(this.parseQuote(line))
      }
      else {
        // если оказались тут и есть цитата, значит, она закончилась, добавляем её в результат
        if (quote.length > 0) {
          lines.push("|" + quote.join("\n"))
          quote = []
        }
        lines.push(line + "\n")
      }
    })
    if (quote.length > 0) lines.push("|" + quote.join("\n"))
    return lines;
  }

  parseQuote = (text) => {
    const flat = (quote) => {
      if (!quote.startsWith('|')) return quote
      return flat(quote.slice(2))
    }
    return flat(text)
  }

  quote() {
    const {onQuote, text} = this.props;
    const quoteText = text.replace(/^/mg, '| '); // add quotation to each line
    onQuote(quoteText);
  }
}

export default Message;
