class Avatar extends Component {
  getAvatar(person) {
    const managerColors = [
      'red', 'light-red', 'orange', 'light-orange', 'yellow', 'light-blue',
      'blue', 'dark-blue', 'purple', 'pink', 'brown', 'dark-brown'
    ];
    const color = person.kind === 'user' ? 'green' : managerColors[person.id % managerColors.length];

    const splitedFullName = person.full_name.split(' ');
    return (
      <div className={`avatar avatar--${color}`}>
        {splitedFullName[0][0]}{ splitedFullName.length == 2 && splitedFullName[1][0] }
      </div>
    );
  }

  render() {
    const {person, tooltip} = this.props;

    if(tooltip) {
      return (
        <Tooltip content={tooltip} center>
          {this.getAvatar(person)}
        </Tooltip>
      );
    } else {
      return this.getAvatar(person);
    }
  }
}

export default Avatar;
