import {formatDate, findById} from 'app/lib/utils'
import PropTypes from 'prop-types'

const PER_PAGE = 100;

class DetailedManagerActivityPoints extends Component {
  static propTypes = {
    manager:    PropTypes.object.isRequired,
    from:       PropTypes.object,
    to:         PropTypes.object,
    onHide:     PropTypes.func.isRequired, // for Modal
    afterSave:  PropTypes.func, // callback triggered after some change is saved. You can reload your data at this point
  }

  state = { inputValues: {}, page: 1, data: null }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    const {lastRequestFilter} = this.state;
    const filterParams = this.filterParams();
    if(JSON.stringify(lastRequestFilter) != JSON.stringify(filterParams)) {
      this.getData();
    }
  }

  getData() {
    const filterParams = this.filterParams();
    this.setState({ lastRequestFilter: filterParams });
    const repo = new repositories.ActivityPointsRepo();
    repo.getAll(filterParams).then(data => this.setState({ data }));
  }

  filterParams() {
    const { from, to, manager } = this.props;
    const { page } = this.state;
    return { from, to, manager_id: manager.id, page };
  }

  render() {
    const {manager, data, onHide} = this.props;

    return (
      <Modal large onHide={onHide}>
        <h2>{manager.full_name}</h2>
        <Margin size="20" />
        {this.renderAddingPointsForm()}
        <Margin size="10" />
        <Table cols={['', '', '', 'Тикет', 'Источник', 'Комментарий', 'Редактор', '']}>
          {this.rows()}
        </Table>

        {this.renderPagination()}
      </Modal>
    );
  }

  renderPagination() {
    const { data } = this.state;
    if(!data || !data.pagination)
      return <>< />;
    return <Pagination data={data.pagination} onChange={n => this.setState({ page: n })} />;
  }

  rows() {
    const pointRows = this.dataToShow().map(change => this.pointsRow(change));
    return pointRows.map(r =>
      <tr key={r.id}>
        <td>{r.createdAt}</td>
        <td>{r.type}</td>
        <td>{r.points}</td>
        <td>{r.ticket}</td>
        <td>{r.source}</td>
        <td>{r.comment}</td>
        <td>{r.lastEditor}</td>
        <td>{r.save}</td>
      </tr>
    );
  }

  dataToShow() {
    const {page, data} = this.state;
    const startIndex = PER_PAGE * (page - 1);
    if(!data) return [];
    return data.points; //this.props.data.slice(startIndex, startIndex + PER_PAGE);
  }

  pointsRow(change) {
    switch(change.type) {
      case 'rate':     return this.ratePointsRow(change);
      case 'message':  return this.answerPointsRow(change);
      case 'call':     return this.callPointsRow(change);
      case 'closing':  return this.closingPointsRow(change);
      case 'custom':   return this.customPointsRow(change);
      default:
        console.log('Unknown type', change);
        return this.basePointsRow(change);
    }
  }

  callPointsRow(change) {
    const result = this.basePointsRow(change);
    result.type = 'Звонок';
    result.source = <Tooltip content={`${change.source.duration} сек`}>{change.source.phone}</Tooltip>;
    return result;
  }

  closingPointsRow(change) {
    const result = this.basePointsRow(change);
    result.type = 'Закрытие';
    result.source = formatDate(change.source.created_at);
    return result;
  }

  customPointsRow(change) {
    const result = this.basePointsRow(change);
    result.type = 'Вручную';
    result.source = <ManagerById id={change.source.id} />;
    return result;
  }

  ratePointsRow(change) {
    const result = this.basePointsRow(change);
    result.type = 'Оценка';
    result.source = <RatingIcon opinion={change.source.value_string} />;
    if(change.source.message) {
      result.source = <Tooltip content={change.source.message}>{result.source}</Tooltip>;
    }
    return result;
  }

  answerPointsRow(change) {
    const result = this.basePointsRow(change);
    result.type = 'Ответ';
    result.source = <Tooltip content={change.source.message}><a>{formatDate(change.source.created_at)}</a></Tooltip>;
    return result;
  }


  basePointsRow(change) {
    const {inputValues} = this.state;
    const {id, points, comment} = change;
    inputValues[id] = inputValues[id] || { points, comment };

    const result = {
      id,
      createdAt: <TimePassedSince reversedView time={change.created_at} />,
      points: <TextInput short small value={inputValues[id].points} onChange={v => this.updateInputValues(id, { points: v })} />,
      comment: <TextInput small value={inputValues[id].comment || ''} onChange={v => this.updateInputValues(id, { comment: v })} />,
      lastEditor: <ManagerById id={change.editor_id} />,
      save: <Button onClick={() => this.save(id)}>Save</Button>,
    };
    if(change.ticket) {
      result.ticket = <LinkToTicket id={change.ticket.id} tooltip={change.ticket.subject} />;
    }

    return result;
  }

  updateInputValues(changeId, data) {
    const {inputValues} = this.state;
    Object.assign(inputValues[changeId], data);
    this.forceUpdate();
  }

  save(id) {
    const {points, comment} = this.state.inputValues[id];
    if(!comment) {
      alert('Comment required!');
      return;
    }
    const repo = new repositories.ActivityPointsRepo();
    repo.update(id, { number: points, comment })
        .then(() => this.props.afterSave && this.props.afterSave())
  }

  renderAddingPointsForm() {
    const {inputValues} = this.state;
    inputValues['form'] = inputValues['form' ] || { points: 1, comment: '' };

    return (
      <div>
        <TextInput short small value={inputValues['form'].points} onChange={v => this.updateInputValues('form', { points: v })} />
        <TextInput inline small value={inputValues['form'].comment} onChange={v => this.updateInputValues('form', { comment: v })} />
        <Button small onClick={() => this.addPoints()}>Add</Button>
      </div>
    );
  }

  addPoints() {
    const {inputValues} = this.state;
    const {points, comment} = inputValues['form'];
    if(!comment) {
      alert('Comment required!');
      return;
    }
    const repo = new repositories.ActivityPointsRepo();
    repo.create({ comment, number: points, manager_id: this.props.manager.id })
        .then(() => this.props.afterSave && this.props.afterSave())
  }
}

export default DetailedManagerActivityPoints;
