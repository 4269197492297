import moment from 'moment'
import {extractFreeWorkType} from "../../lib/utils";

/*
 *
 * Use promise to unify repositories
 * (in case we won't use JQuery in future or some cases)
 *
 */
class FreeWorkRepo {
  getThisMonth(additionalParams) {
    // Moscow start of month
    // ex. client: 30-11-2022 21:00:00 UTC -> server: 01-12-2022 00:00:00 MSK -> db: 30-11-2022 21:00:00 UTC
    const moscowOffsetSec = 3 * 60 * 60;
    const startOfMonth = moment().utc().startOf('month').unix() - moscowOffsetSec;
    const params = Object.assign({}, additionalParams, { from: startOfMonth });
    return this.getAll(params);
  }

  getAll(params) {
    const promise = new Promise(function(resolve, reject) {
      API.freeWorks.getAll({
        params: {...params, ...{free_work_type: extractFreeWorkType(params)}},
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }

  create(ticketId, params) {
    const promise = new Promise(function(resolve, reject) {
      API.freeWorks.create({
        ticketId,
        params: {...params, ...{free_work_type: extractFreeWorkType(params)}},
        success: resolve,
        error: reject,
      });
    });
    return promise;
  }

  delete(id, params) {
    const promise = new Promise(function(resolve, reject) {
      API.freeWorks.delete({
        id,
        params: {...params, ...{free_work_type: extractFreeWorkType(params)}},
        success: resolve,
        error: reject,
      });
    });
    return promise;
  }

}

export default FreeWorkRepo;
