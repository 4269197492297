import PropTypes from 'prop-types';

const PartnerForDropdown = function({partner}) {
  return (
    <div>
      <div className='partner-select__img-container'>
        <img src={partner.image_url} />
      </div>
      <div className='partner-select__main-info'>
        <div className='partner-select__title'>{partner.title}</div>
        <div className='partner-select__city'>{partner.city}</div>
      </div>
      <div className='partner-select__additional-info'>
        {
          partner.external_page_url &&
          <a target='_blank' rel='noopener noreferrer' href={partner.external_page_url}>Посмотреть профиль</a>
        }
      </div>
    </div>
  )
}

const PartnerSelect = function({partners, selected, onSelect}) {
  const itemRenderer = partner => <PartnerForDropdown partner={partner} />
  return (
    <DropdownTrigger itemRenderer={itemRenderer} items={partners.sort((p1, p2) => p1.position - p2.position)} onSelect={onSelect}>
      {selected ? selected.title : 'Выберите партнера'}
    </DropdownTrigger>
  )
}

PartnerSelect.propTypes = {
  partners: PropTypes.array.isRequired,
  selected: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
}

export default PartnerSelect;
