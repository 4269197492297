import AbstractEntity from './AbstractEntity'

class User extends AbstractEntity {
  isMonthlyFreeWorkLimitReached() {
    const limit = this.plan_free_works;
    const done = this.free_works_done_for_account_this_month;
    if(!limit || !done)
      return false;

    return done >= limit;
  }
}

export default User;
