import AbstractEntity from './AbstractEntity'

class Manager extends AbstractEntity {
  isPartner() {
    return !!(this.partner_id || this.partner);
  }

  partnerTitle() {
    return this.partner ? this.partner.title : null;
  }

  partnerId() {
    return this.partner ? this.partner.id : null;
  }
}

export default Manager;
