import React from 'react'
import {Router, Route, Redirect} from 'react-router-dom'
import pages from 'app/pages'

// It seems that <Redirect to /> doesnt work properly with ?query=something so here goes the CRUTCH
const generateRedirect = function(to) {
  return class extends Component {
    displayName = `RedirectTo(${to})`
    componentDidMount() { browserHistory.push(to) }
    render() { return <PageTransition /> }
  }
};

export default <Router history={browserHistory}>
<div>
  <Route exact path="/my" component={generateRedirect('/my/tickets?status=!archived')} />
  <Route path="/my/login" component={pages.my.login} />
  <Route exact path="/my/tickets" component={pages.my.tickets.list} />
  <Route exact path="/my/new" component={pages.my.new} />
  <Route exact path='/my/:id(\d+)' component={pages.my.tickets.show} />

  <Route exact path="/staff/articles" component={pages.staff.articles.list} />
  <Route exact path="/staff/articles/new" component={pages.staff.articles.new} />
  <Route exact path='/staff/articles/:id(\d+)' component={pages.staff.articles.edit} />

  <Route exact path="/staff/chat_gpt_models" component={pages.staff.chat_gpt_models.list} />
  <Route exact path="/staff/chat_gpt_models/new" component={pages.staff.chat_gpt_models.new} />
  <Route exact path="/staff/chat_gpt_models/:id(\d+)" component={pages.staff.chat_gpt_models.edit} />

  <Route exact path="/staff/partners" component={pages.staff.partners.list} />
  <Route exact path='/staff/partners/new' component={pages.staff.partners.new} />
  <Route exact path='/staff/partners/:id(\d+)' component={pages.staff.partners.edit} />

  <Route exact path="/staff/payments" component={pages.staff.payments.list} />
  <Route exact path="/staff/calls" component={pages.staff.calls.list} />
  <Route exact path="/staff/subscriptions" component={pages.staff.subscriptions} />

  <Route exact path="/staff/activity_points" component={pages.staff.activity_points.main} />

  <Route exact path="/staff/answer_templates" component={pages.staff.answer_templates.list} />
  <Route exact path="/staff/answer_templates/new" component={pages.staff.answer_templates.new} />
  <Route exact path='/staff/answer_templates/:id(\d+)' component={pages.staff.answer_templates.edit} />

  <Route exact path="/staff/managers" component={pages.staff.managers.list} />
  <Route exact path="/staff/managers/activity" component={pages.staff.managers.activity} />
  <Route exact path="/staff/managers/rates" component={pages.staff.managers.rates} />
  <Route exact path='/staff/managers/:id(\d+)' component={pages.staff.managers.edit} />

  <Route exact path="/staff/tags" component={pages.staff.tags.list} />
  <Route exact path="/staff/tags/new" component={pages.staff.tags.new} />
  <Route path='/staff/tags/:id(\d+)' component={pages.staff.tags.show} />


  <Route exact path="/staff/statistics" component={pages.staff.statistics.page} />
  <Route exact path="/staff/statistics/managers" component={pages.staff.statistics.managers} />
  <Route exact path="/staff/statistics/tags" component={pages.staff.statistics.tags} />

  <Route exact path="/staff/notifications" component={pages.staff.notifications.page} />
  <Route exact path="/staff/notifications/new" component={pages.staff.notifications.new} />
  <Route exact path="/staff/notifications/edit" component={pages.staff.notifications.edit} />

  <Route exact path="/staff/auto_replies" component={pages.staff.auto_replies.list}/>
  <Route exact path="/staff/auto_replies/new" component={pages.staff.auto_replies.new}/>
  <Route exact path='/staff/auto_replies/:id(\d+)' component={pages.staff.auto_replies.edit}/>

  <Route exact path="/staff/users" component={pages.staff.users.list}/>
  <Route exact path='/staff/users/:id(\d+)' component={pages.staff.users.edit}/>

  <Route exact path="/staff/scripts" component={pages.staff.scripts.list} />
  <Route path='/staff/scripts/:id' component={pages.staff.scripts.edit} />

  <Route exact path="/staff" component={pages.staff.main} />
  <Route path="/staff/login" component={pages.staff.login} />
  <Route exact path="/staff/tickets" component={pages.staff.tickets.list} />
  <Route exact path='/staff/:id(\d+)' component={pages.staff.tickets.show} />

  <Route path='/staff/emails/:id(\d+)' component={pages.staff.emails.show} />
  <Route exact path='/staff/emails' component={pages.staff.emails.list} />
  <Route exact path='/staff/emails/spam' component={pages.staff.emails.list} />

  <Route exact path="/staff/categories" component={pages.staff.categories.list} />
  <Route exact path='/staff/categories/new' component={pages.staff.categories.new} />
  <Route exact path='/staff/categories/:id(\d+)' component={pages.staff.categories.edit} />
</div>
</Router>
