const selectOptions = [
  { id: 'relevance',          label: 'по релевантности' },
  { id: 'priority',           label: 'по приоритету' },
  { id: 'attendance',         label: 'по ожиданию ответа (убыв.)' },
  { id: 'user_message',       label: 'по последнему сообщению (новые)' },
  { id: 'first_reaction',     label: 'по первой реакции (медленные)' },
  { id: 'created_reverse',    label: 'по времени создания (старые)' },
  { id: 'close_time',         label: 'по времени закрытия(убыв.)'}
];

const orderParams = {
  relevance:          { order: 'relevance', reverse: null },
  attendance:         { order: 'attendance', reverse: null },
  priority:           { order: 'priority', reverse: null },
  user_message:       { order: 'user_message', reverse: null },
  created_reverse:    { order: 'created', reverse: true },
  first_reaction:     { order: 'first_reaction', reverse: null },
  close_time:         { order: 'close_time', reverse: null},
};

class TicketOrderSelect extends Component {
  render() {
    const onSelect = id => this.props.onChange(orderParams[id]);
    return (
      <DropdownTrigger items={selectOptions} onSelect={onSelect}>
          {this.selectedItem().label}
      </DropdownTrigger>
    );
  }

  selectedItem() {
    const selectedId = (this.props.current || 'relevance') + (this.props.reverse ? '_reverse' : '')
    let result = selectOptions.find(e => e.id == selectedId);
    // if this ordering is not specified in <select> just return some dummy
    return result ? result : { label: 'сортировка' }
  }
}

export default TicketOrderSelect;
