import Select from 'react-select'
import cookie from 'cookie'
import {connect} from 'react-redux'
import {actions} from 'app/store/actions'
import {isUndefined} from 'app/lib/utils'

class AnswerTemplateSelect extends Component {
  state = {}
  componentDidMount() {
    let params = { group_id: cookie.parse(document.cookie).g };
    API.answerTemplates.getAll({
      params,
      success: data => this.setState(() => {
        this.updateSelectMissingSortings(data);
        return { answerTemplates: data };
      }),
      error: data => console.log(data)
    });
  }

  updateSelectMissingSortings(answerTemplates) {
    const {sortings, updateSortings} = this.props;
    const missingTemplates = answerTemplates.filter(t => isUndefined(sortings[t.id]));
    if (missingTemplates.length === 0) return;

    let lastSortingValue = Object.keys(sortings).length;
    const newSortings = {};
    missingTemplates.forEach(t => { newSortings[t.id] = lastSortingValue++ });
    updateSortings(newSortings);
  }

  render() {
    const answerTemplates = this.state.answerTemplates;
    if(!answerTemplates)
      return null;

    const {onSelect, sortings, userSettings} = this.props;

    const [personalAnswerTemplates, systemAnswerTemplates] = answerTemplates.reduce((res, elem) => {
      res[elem.personal ? 0 : 1].push(elem);
      return res;
    }, [[], []]);

    const personalItems = personalAnswerTemplates.map(t => ({ value: t.id, label: t.title }))
                                                 .sort((a, b) => sortings[a.value] < sortings[b.value] ? -1 : 1);

    const systemItems = systemAnswerTemplates.map(t => ({ value: t.id, label: t.title }))
                                             .sort((a, b) => sortings[a.value] < sortings[b.value] ? -1 : 1);

    const preferPersonalAnswerTemplates = (userSettings && !jQuery.isEmptyObject(userSettings))
      ? userSettings.preferPersonalAnswerTemplates
      : false;

    const options = [];
    if (preferPersonalAnswerTemplates) {
      if (personalItems.length > 0) {
        options.push({ label: 'Личные', disabled: true, className: 'optgroup-header' });
        options.push(...personalItems);
      }
      if (systemItems.length > 0) {
        options.push({ label: 'Системные', disabled: true, className: 'optgroup-header' });
        options.push(...systemItems);
      }
    } else {
      if (systemItems.length > 0) {
        options.push({ label: 'Системные', disabled: true, className: 'optgroup-header' });
        options.push(...systemItems);
      }
      if (personalItems.length > 0) {
        options.push({ label: 'Личные', disabled: true, className: 'optgroup-header' });
        options.push(...personalItems);
      }
    }
    const findById = id => answerTemplates.find(t => t.id == id);

    return (
      <Select
        options={options}
        simpleValue={true}
        onChange={id => onSelect(findById(id))}
        placeholder={'Выбрать шаблон ответа'}
      />
    );
  }
}

const mapStateToProps = state => ({ sortings: state.userSettings.answerTemplateSortings || {},
                                    userSettings: state.userSettings });
const mapDispatchToProps = (dispatch) => ({
  updateSortings: (sortings) => dispatch(actions.updateAnswerTemplateSortings(sortings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnswerTemplateSelect);
