import React, {Component} from 'react';
import {bemModifiers} from 'app/lib/utils';

class SelectInput extends Component {
  render() {
    const {value, onChange, tags, disabled, inline, small, short} = this.props;
    const modifiers = [inline && 'inline', small && 'small', short && 'short'];
    const className = bemModifiers('select-input', modifiers);

    return (
      <select
        value={value || ''}
        onChange={onChange}
        disabled={!!disabled}
        className={className}
      >
        {value === '' && <option disabled value="">Выберите теги</option>}
        {tags.map(tag => (
          <option key={tag.id} value={tag.id}>{tag.name}</option>
        ))}
      </select>
    );
  }
}

export default SelectInput;
