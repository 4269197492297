import {reloadTags} from 'app/store/helpers'
import {assoc} from 'app/lib/utils'

class ScriptExecution extends Component {
  state = { values: {} }

  componentDidMount() {
    this.setState({ values: {} });
    this.getData();
  }

  getData() {
    API.scriptActions.getAll({
      scriptId: this.props.script.id,
      success: actions => this.setState({ actions }),
      error: data => console.log(data),
    });
  }

  render() {
    const {actions} = this.state;
    if(!actions)
      return <PageTransition />;

    const {onHide, ticket, script} = this.props;

    return (
      <div className='script-execution'>
        <Modal>
          <h2>Запуск сценария <strong>{script.name}</strong></h2>
          <Margin size="20" />
          <ol>
            {actions.map(a => <li key={a.id}>{this.renderAction(a)}</li>)}
          </ol>

          <div style={{justifyContent: 'space-between', display: 'flex'}}>
            <Button onClick={this.execute}>Запустить</Button>
            <Button color='gray' onClick={this.props.onHide}>Закрыть</Button>
          </div>
        </Modal>
      </div>
    );
  }

  renderAction(action) {
    const {lastExecParams, execResult, values} = this.state;
    const onChangeValue = (a, v) => this.setState(s => ({ values: assoc(s.values, a.id, v) }));

    if(this.isExecutionDone() && !execResult.ok && execResult.failed_action_id == action.id) {
      return (
        <Tooltip content={execResult.errors.join("; ")}>
          <div className='text-red'>
            <staff.ScriptAction action={action}
                                       value={values[action.id]}
                                       onChangeValue={onChangeValue} />
          </div>
        </Tooltip>
      );
    }

    return <staff.ScriptAction action={action}
                                      value={values[action.id]}
                                      onChangeValue={onChangeValue} />;
  }

  execute = () => {
    const execParams = this.execParams();
    const onSuccess = execResult => {
      this.setState({ execResult, lastExecParams: execParams });
      alert('Успешно!');
      this.props.onSuccess && this.props.onSuccess();
    }

    const onError = ({responseJSON}) => {
      this.setState({ execResult: responseJSON, lastExecParams: execParams });
      console.log(responseJSON);
      alert('Ошибка!');
    }

    API.scripts.execute({
      id: execParams.scriptId,
      params: { ticket_id: execParams.ticketId, values: execParams.values },
      success: onSuccess,
      error: onError,
    });
  }

  isExecutionDone() {
    return JSON.stringify(this.execParams()) == JSON.stringify(this.state.lastExecParams);
  }

  execParams() {
    const ticketId = this.props.ticket.id;
    const scriptId = this.props.script.id;
    const values = this.state.values;

    return { ticketId, scriptId, values };
  }
}

export default ScriptExecution;
