function DimLink(props) {
  const {href, icon, onClick, children} = props;
  return (
    <a className='dim-link' href={href} onClick={onClick}>
      <IconText icon={icon} size={16} margin={5} textClass="dim-link__text">
        {children}
      </IconText>
    </a>
  )
}

export default DimLink;
