import {connect} from 'react-redux';
import {setReverseEventFeed} from 'app/store/helpers';
import pages from 'app/pages'
import ScrollToBottom from "../../../ui/ScrollToBottom";

class TicketPage extends Component {
  state = { ticket: {}, messages: [] }

  constructor(props) {
    super(props);
    this.messagesEndRef = React.createRef()
  }

  componentDidMount() {
    if(!this.isLoadingInProgress() && this.needToUpdateData()) {
      this.getData();
    }
  }

  ticketId() {
    return this.props.match.params.id - 0;
  }

  needToUpdateData() {
    const {ticket, ticketMessagesLoadedFor} = this.state;
    const requiredTicketId = this.ticketId();
    if(this.ticketCantBeLoaded())
      return false;
    return ticket.id != requiredTicketId || ticketMessagesLoadedFor != requiredTicketId;
  }

  isLoadingInProgress() {
    const {ticketLoading, messagesLoading} = this.state;
    return ticketLoading || messagesLoading;
  }

  ticketCantBeLoaded() {
    return this.ticketId() == this.state.cantBeLoaded;
  }

  getData() {
    const id = this.ticketId();
    this.setState({ ticketLoading: true, messagesLoading: true });
    API.tickets.get({ id: id,
                      success: ticket => this.setState({ ticket: ticket }),
                      acceptStatuses: [403, 422],
                      error: data => (data.status == 403 || data.status == 404) && this.setState({ cantBeLoaded: id }),
                      complete: () => this.setState({ ticketLoading: false }) });

    API.messages.getAll({ ticketId: id,
                          success: messages => this.setState({ messages }),
                          acceptStatuses: [403, 422],
                          error: data => console.log("cannot load messages:", data),
                          complete: () => this.setState({ messagesLoading: false,
                                                          ticketMessagesLoadedFor: id }) });
  }

  render() {
    const { ticket, errorNotice, messages} = this.state

    if(this.ticketCantBeLoaded()) {
      return <pages.my.object_cant_be_loaded />;
    }

    if(!ticket.id) {
      return <PageTransition />;
    }

    return (
      <layouts.User>
        <h1>
          {ticket.opinion && <RatingIcon opinion={ticket.opinion} />}
          #{ticket.id} - {ticket.subject}
        </h1>


        <Margin size="15 0 15"><hr /></Margin>
        {errorNotice && <Notice type="error">{errorNotice}</Notice>}
        <Margin size="10" />
        {this.renderControlInputs()}

        {ticket.opinion_text && <OpinionText text={ticket.opinion_text} additionalClasses="mb-2" opinion={ticket.opinion} />}

        {ticket.status == 'closed' && this.renderRateTicketForm()}

        <Switch on={<DimLink icon="sort">Сначала новые</DimLink>}
                off={<DimLink icon="sort">Сначала старые</DimLink>}
                isOn={this.props.reverse}
                onChange={status => setReverseEventFeed(status)} />
        <Margin size="20" />

        <div className='messages' ref={this.messagesEndRef}>
          {this.renderMessages()}
          {messages && messages.length > 5 &&
          <ScrollToBottom refEnd={this.messagesEndRef} />
          }
        </div>
      </layouts.User>
    );
  }


  renderControlInputs() {
    const {ticket, messageType} = this.state;
    if(ticket.status == 'archived') {
      return null;
    }

    const canBeClosed = ticket.status == 'open' || ticket.status == 'waiting';
    const toggleMessageType = type => this.setState({ messageType: messageType == type ? null : type })

    return (
      <div>
        <Spaced w={15} h={15}>
          <Button onClick={() => toggleMessageType('message')}>Ответить/уточнить</Button>
          {canBeClosed && this.renderCloseTicketButton()}
          <Button onClick={() => toggleMessageType('complaint')} outline>Пожаловаться</Button>
        </Spaced>

        <Margin size="15" />
        <hr />

        <MessageForm ref={'messageForm'}
                            formId={`user-message-form-${ticket.id}`}
                            visible={messageType}
                            label={messageType == 'message' ? 'Текст сообщения' : 'Текст жалобы'}
                            onSubmit={data => this.submitMessageForm(data)} />
      </div>
    );
  }

  renderCloseTicketButton() {
    const {id} = this.state.ticket;
    const close = () => API.tickets.close({ id,
                                            success: ticket => this.setState({ ticket }),
                                            error: data => this.showErrorNotice(data) });
    const onClick = () => confirm("Закрыть тикет?") && close();
    return <Button color="green" onClick={onClick}>Вопрос решен</Button>;
  }


  renderRateTicketForm() {
    const rate = (opinion, text) => API.tickets.rate({ id: this.state.ticket.id,
                                                       params: { opinion, text },
                                                       success: ticket => this.setState({ ticket }),
                                                       error: data => this.showErrorFromResponse(data) });
    return <user_ui.RateTicket onSelect={rate} />;
  }

  renderMessages() {
    const messages = this.state.messages.slice();
    if(this.props.reverse)
      messages.reverse();

    if(messages.lenght == 0) {
      return null;
    }
    const color = e => e.kind == 'complaint' ? 'red' : (e.manager ? 'blue' : null);
    const message = event => <Message key={event.id}
                                      color={color(event)}
                                      text={event.message}
                                      person={event.user || event.manager}
                                      attachments={event.attachments}
                                      html={event.html}
                                      createdAt={event.created_at} />;


    return messages.map(e => message(e));
  }

  quote(txt) {
    this.setState({ messageType: 'message' })
    const messageForm = this.refs.messageForm

    const currentText = messageForm.getText()
    const newText = currentText.trim() ? `${currentText}\n\n${txt}\n\n` : `${txt}\n\n`
    messageForm.setText(newText);
    messageForm.focus();
  }

  submitMessageForm({message, fileIds}) {
    const {messageType, ticket} = this.state;

    const onSuccess = () => { this.refs.messageForm.reset(); this.getData(); }
    if(messageType == 'message') {
      API.tickets.addMessage({ id: ticket.id,
                               params: { message, file_ids: fileIds },
                               success: onSuccess,
                               error: data => this.showErrorFromResponse(data) });
    } else {
      API.tickets.addComplaint({ id: ticket.id,
                                 params: { message, file_ids: fileIds },
                                 success: onSuccess,
                                 error: data => this.showErrorFromResponse(data) });
    }
  }

  showErrorFromResponse({responseJSON}) {
    this.showErrorNotice(responseJSON.errors.join("; \n"));
  }

  showErrorNotice(text) {
    clearTimeout(this.state.errorNoticeHidingTimeout);
    this.setState({ errorNotice: text || 'Произошла неизвестная ошибка',
                    errorNoticeHidingTimeout: setTimeout(() => this.hideErrorNotice(), 5000) });
  }

  hideErrorNotice() {
    this.setState({ errorNotice: null });
  }
}

const mapStateToProps = s => ({ reverse: s.userSettings.reverseEventFeed });
export default connect(mapStateToProps)(TicketPage);
