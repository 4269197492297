import {connect} from 'react-redux'
import {reloadManagers} from 'app/store/helpers'
import filesize from 'app/lib/filesize'
import jQuery from 'jquery'

const csrfToken = jQuery('meta[name="csrf-token"]').attr('content');

const repo = new repositories.ChatGPTModelRepo();

class ChatGPTModelFilter extends Component {
  state = {
    manager_id: null,
    from: null,
    to: null,
    text: '',
    errors: null,
    processing: false,
  }

  componentDidMount() {
    reloadManagers()
  }

  onChangeManager = (manager_id) => this.setState({ manager_id });

  onChangeDate = ({from, to}) => this.setState({ from, to });

  onChangeText = (text) => this.setState({ text });

  getTicketIds = () => {
    this.setState({ errors: null, processing: true });

    const { from, to, manager_id } = this.state;
    repo.getTicketIds({ manager_id, from, to }).then(
      ticket_ids => this.setState({ processing: false, text: ticket_ids.join(',') }),
      data => this.setState({ errors: this.parsedErrors(data), processing: false }),
    );
  };

  parsedErrors = ({responseJSON}) => (
    responseJSON && responseJSON.errors || ["Произошла ошибка"]
  )

  render() {
    const { managers } = this.props;
    const { manager_id, from, to, text, processing, errors } = this.state;

    return (
      <>
        <h2>Создание обучающего файла</h2>
        <Margin size="10" />
        <h4>Выбор тикетов</h4>
        <ManagerSelect
          nullLabel='Выбрать менеджера (необязательно)'
          managers={managers}
          current={manager_id}
          onChange={this.onChangeManager}
        />
        <TimeFilter from={from} to={to} onChange={this.onChangeDate} />
        {errors && (
          <>
            <Margin size="10" />
            <ErrorNotice errors={errors} />
          </>
        )}
        <Margin size="10" />
        <Button size='xs' onClick={this.getTicketIds} disabled={processing}>Применить</Button>
        <Margin size="10" />
        <form method='post' action='/api/chat_gpt_models/download_training_file' target='_blank'>
          <LabeledInput label={'Идентификаторы тикетов'}>
            <Textarea name='ticket_ids' value={text} onChange={this.onChangeText}/>
          </LabeledInput>
          <Margin size="5" />
          <input type='hidden' name="authenticity_token" value={`${csrfToken}`}/>
          <Button size='xs' disabled={text.length === 0}>Скачать предварительный файл</Button>
        </form>
      </>
    )
  }
}

const mapStateToProps = state => ({
  managers: state.managers.filter((manager) => !manager.admin),
});

export default connect(mapStateToProps)(ChatGPTModelFilter);
