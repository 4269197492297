import {bemModifiers} from 'app/lib/utils';

function LabeledInput({label, error, children}) {
  const modifiers = ['label', error && 'error'].filter(e => e);
  const className = bemModifiers('labeled-input', modifiers);

  return (
    <div className='labeled-input'>
      <div className={className}>{label}</div>
      {children}
      {
        error &&
        <Margin size="10 0 10 0">
          <Notice type="error">{error}</Notice>
        </Margin>
      }
    </div>
  )
}

export default LabeledInput;
