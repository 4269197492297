const selectOptions = [
  { id: 'none',      label: 'статус' },
  { id: 'open',      label: 'открыт' },
  { id: 'waiting',   label: 'отвечен' },
  { id: 'closed',    label: 'закрыт' },
  { id: 'archived',  label: 'в архиве' },
  { id: 'draft',     label: 'черновик'},
];

class StatusSelect extends Component {
  render() {
    const defaultStatus = 'none';
    const selectedId = this.props.current || defaultStatus;
    const selected = selectOptions.find(e => e.id == selectedId);
    const onSelect = id => this.props.onChange(id == defaultStatus ? null : id);
    return (
      <DropdownTrigger items={selectOptions} onSelect={onSelect}>
          {selected.label}
      </DropdownTrigger>
    );
  }
}

export default StatusSelect;
