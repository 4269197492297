import { store } from 'app/store'
import cookie from 'cookie'

/*
 *
 * Use promise to unify repositories
 * (in case we won't use JQuery in future or some cases)
 *
 */
class TagRepo {
  getAll(params = {}) {
    if (!params.group_id) {
      params.group_id = cookie.parse(document.cookie).g
    }
    const promise = new Promise(function(resolve, reject) {
      const mapToEntities = tags => tags.map(t => new entities.Tag(t));
      API.tags.getAll({
        params,
        success: tags => resolve(mapToEntities(tags)),
        error: reject
      });
    });

    return promise;
  }


  get(id) {
    const promise = new Promise(function(resolve, reject) {
      API.tags.get({
        id,
        success: t => resolve(new entities.Tag(t)),
        error: reject,
      });
    });

    return promise;
  }

  save(tag) {
    return tag.id ? this.update(tag) : this.create(tag);
  }

  update(tag) {
    const promise = new Promise(function(resolve, reject) {
      API.tags.update({
        id: tag.id,
        params: tag,
        success: t => resolve(new entities.Tag(t)),
        error: reject,
      });
    });

    return promise;
  }

  create(tag) {
    const promise = new Promise(function(resolve, reject) {
      API.tags.create({
        params: tag,
        success: t => resolve(new entities.Tag(t)),
        error: reject,
      });
    });

    return promise;
  }

  store = {
    getChargeableTag() {
      return store.getState().tags.find(t => t.short_name.match(/^платные\s+доработки/i));
    }
  }
}

export default TagRepo;
