export default (bytes) => {
  const kb = 1024
  const mb = kb * 1024

  const kbs = bytes / kb
  const mbs = bytes / mb

  if (mbs > 1) {
    return `${Math.round(mbs * 100) / 100} MB`
  } else {
    return `${Math.round(kbs * 100) / 100} KB`
  }
}
