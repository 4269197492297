import {bemModifiers} from 'app/lib/utils'

function Table({cols, compact, children}) {
  return (
    <div className="table-wrapper">
      <table className={bemModifiers('table', [compact && 'compact'])}>
        <thead>
          <tr>{cols.map((col, i) => <th key={i}>{col}</th>)}</tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  )
}

export default Table;
