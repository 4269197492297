class CallRepo {
  getAll(params) {
    const promise = new Promise(function(resolve, reject) {
      const prepareData = function(data) {
        data.calls = data.calls.map(c => new entities.Call(c));
        return data;
      }

      API.calls.getAll({
        params,
        success: data => resolve(prepareData(data)),
        error: reject,
      });
    });

    return promise;
  }
}

export default CallRepo;
