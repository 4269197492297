class ChatGPTModelSelect extends Component {
  render() {
    const onSelect = id => this.props.onChange(id);
    return (
      <DropdownTrigger items={this.items()} onSelect={onSelect}>
        {this.selectedItemLabel()}
      </DropdownTrigger>
    );
  }

  selectedItemLabel() {
    const id = this.selectedItemId();
    let item = this.items().find(item => id == item.id);
    if(!item) {
      item = this.items().find(item => null === item.id);
    }
    return item.label
  }

  selectedItemId() {
    const current = this.props.current;
    if(!current)
      return 'all';

    return current.id ? current.id : current;
  }


  items() {
    const result = this.props.models.map(m => ({ id: m.id, label: m.name }));
    result.unshift({ id: null, label: this.props.nullLabel || 'Все модели' });
    return result;
  }
}

export default ChatGPTModelSelect;
