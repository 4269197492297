const errorMessage = `Произошла ошибка. Проверьте заполненные поля.`

const fileErrorMessages = {
    'file-invalid-type': "Недопустимый тип",
    'file-too-large': "Слишком большой файл. Если необходимо загрузить файл размером более 20 мб, используйте облачные хранилища (например, Яндекс.Диск или Google Drive). В обращении прикрепите ссылку на файл.",
    'file-too-small': "Пустой",
    'too-many-files': "Слишком много файлов"
}

export { errorMessage, fileErrorMessages };
