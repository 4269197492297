import AbstractEntity from './AbstractEntity'

class Ticket extends AbstractEntity {
  rename(name) {
    const repo = new repositories.TicketRepo();
    return repo.rename(this.id, name);
  }
}

export default Ticket;
