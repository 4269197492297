import {boolValue} from 'app/lib/utils'
import scrollToTop from 'app/lib/scrollToTop'

class PartnersPage extends Component {
  state = { partners: [], formData: [] }

  componentDidMount() {
    this.getData();
  }

  getData() {
    if(this.state.dataLoading)
      return;

    this.setState({ dataLoading: true });
    const repo = new repositories.PartnerRepo();
    repo.getAll()
        .then(partners => this.setState({ partners, dataLoading: false }))
  }

  render() {
    const {partners} = this.state;

    return (
      <layouts.Staff>
        <Card stretch>
          <h2>Партнеры</h2>
          <Margin size='20' />

          <Button size='xs' color='green' to='/staff/partners/new'>Создать</Button>
          <Margin size='10' />

          <Table cols={['Позиция', 'ID', 'Название', 'Категории', 'Страница', '']}>
            {partners.map(partner => this.renderTableRow(partner))}
          </Table>

          <Margin size='10' />
          <Button onClick={() => this.onSubmit()}>Сохранить</Button>
          <Margin size='10' />
          <Button size='xs' color='green' to='/staff/partners/new'>Создать</Button>
        </Card>
      </layouts.Staff>
    );
  }

  renderTableRow(partner) {
    const categories = partner.getCategories();
    const pageUrl = partner.external_page_url
    const pageLink = pageUrl && <a href={pageUrl} target='_blank' rel='noopener noreferrer'>Ссылка</a>
    const {formData} = this.state
    let partner_from_form = formData.find(partner_from_form => partner_from_form.id == partner.id)
    let form_not_empty = partner_from_form && partner_from_form["position"] !== undefined
    let empty_string = partner_from_form && partner_from_form["position"] == ""
    let partner_position = (form_not_empty || empty_string) ? partner_from_form["position"] : partner.position

    return (
      <tr key={partner.id}>
        <td><TextInput small short
                       value={partner_position}
                       onChange={(position) => this.setFormData({ id: partner.id, position: Number(position) })} /></td>
        <td>{partner.id}</td>
        <td>{partner.title}</td>
        <td>{categories.map(c => <div key={c}>{partner.translateCategory(c)}</div>)}</td>
        <td>{pageLink}</td>
        <td>
          <Button size="xs" color="blue" to={`/staff/partners/${partner.id}`}>Редактировать</Button>
        </td>
      </tr>
    )
  }

  setFormData = (data) => {
    const index = this.state.formData.findIndex(obj => obj.id == data.id)
    if (index >= 0) {
      const newFormData = [...this.state.formData ]
      newFormData.splice(index, 1, data)
      this.setState({ formData: [...newFormData] })
    } else {
      this.setState({ formData: [...this.state.formData, data] })
    }
  }

  onSubmit() {
    const repo = new repositories.PartnerRepo();
    const params = Object.assign(this.state.formData);
    repo.updatePosition(params)
        .then(partners_position => this.onUpdateSuccess(partners_position),
              error => this.onUpdateError(error));

    this.setState({ showSuccess: false, showError: false });
  }

  onUpdateSuccess(partners_position) {
    this.setState({ showSuccess: true });
    scrollToTop();
  }

  onUpdateError(error) {
    console.log(error)
    this.setState({ showError: true });
    scrollToTop();
  }
}

export default PartnersPage;
