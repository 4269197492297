import {bemModifiers} from 'app/lib/utils';

class Tag extends Component {
  render() {
    const {id, short_name, name} = this.props.data;
    const {highlight, small, main, onClick, onDelete, withMarginLeft} = this.props;

    const modifiers = [
      highlight && 'highlight',
      small && 'small',
      main && 'main',
      onClick && 'clickable',
      withMarginLeft && 'withMarginLeft'
    ].filter(e => e);

    return (
      <div className={bemModifiers('tag', modifiers)} onClick={this.props.onClick} >
        {short_name || name}
        {onDelete && <div className="tag__delete" onClick={() => onDelete(id)}>&times;</div>}
      </div>
    );
  }
}

export default Tag;

