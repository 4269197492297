import {bemModifiers} from 'app/lib/utils'

class Modal extends Component {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this);
    this.onKeyup = this.onKeyup.bind(this);
  }

  componentDidMount() {
    const width = jQuery(window).width()
    jQuery('html').css({ overflowY: 'hidden' })
    jQuery(document.body).css({ marginLeft: width - jQuery(window).width() })

    this.refs.root.focus()
    jQuery(this.refs.root).scrollTop(0)
    jQuery(document).keyup(this.onKeyup)
  }

  componentWillUnmount() {
    jQuery(document.body).css({ marginLeft: 0 })
    jQuery('html').css({ overflowY: 'scroll' })
    jQuery(document).off('keyup', this.onKeyup)
  }

  render() {
    const {children, large} = this.props
    const contentClass = bemModifiers('modal__content', [large && 'large']);

    return (
      <div ref='root' className='modal' onClick={this.onClick} tabIndex='0'>
        <div ref="content" className={contentClass}>
          <Card>{children}</Card>
        </div>
      </div>
    );
  }

  onClick({target}) {
    if (!jQuery.contains(this.refs.content, target)) {
      this.props.onHide()
    }
  }

  onKeyup({keyCode}) {
    keyCode == 27 && this.props.onHide();
  }
}

export default Modal;
