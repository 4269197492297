import {connect} from 'react-redux'
import {queryParams} from 'app/lib/utils'

const pathPrefix = currentUser => currentUser.kind == 'user' ? '/my/' : '/staff/';
const LinkToTicket = function({id, label=`#${id}`, currentUser, params, tooltip}) {
  const kind = currentUser.kind;
  if(kind != 'manager' && kind != 'user') {
    return <span>{label}</span>;
  }

  let link = pathPrefix(currentUser) + id;
  if(params) {
    link += '?' + queryParams(params);
  }

  let result = <Link to={link}>{label}</Link>;
  if(tooltip) {
    result = <Tooltip content={tooltip}>{result}</Tooltip>;
  }
  return result;
}

const mapStateToProps = s => ({ currentUser: s.currentUser });
export default connect(mapStateToProps)(LinkToTicket);
