class TicketTable extends Component {
  state = { selected: [] }

  componentDidMount() {
    this.setState({ selected: [], showGroupAnswer: false });
  }

  renderRow(ticket) {
    const {user, id} = ticket;
    const {selected} = this.state;
    const linkParams = this.props.searchedByNumber ? { from_number_search: true } : null;

    /* eslint-disable react/jsx-key */
    const cells = [
      <Checkbox checked={selected.indexOf(id) != -1} onChange={v => this.onSelectChange(id, v)} />,
      <TicketSubject ticket={ticket} bold={this.isTicketUnread(ticket)} linkParams={linkParams} />,
      <TicketActivityStatus ticket={ticket} />,
      <TagList tags={ticket.tags} small />,
      <TagList tags={ticket.categories} small />,
      ticket.manager && <Person person={ticket.manager} />, // TODO: add viewers
      id,
      <small>{ticket.old_number}</small>,
      ticket.user_space_events_count,
      <Link to={`/staff/tickets?user=${user.id}`} target='_blank'>{user.full_name}</Link>
    ]
    /* eslint-enable react/jsx-key */

    return (
      <tr key={id}>
        {cells.map((c, i) => <td key={i}>{c}</td>)}
      </tr>
    )
  }

  isTicketUnread(ticket) {
    const statuses = this.props.unreadStatuses || {};
    return statuses[ticket.id] === 'unread';
  }

  render() {
    const cols = ['', 'Тема', 'Статус', 'Теги', 'Категории', 'Менеджер', 'Номер', 'Омни', 'Сообщений', 'Клиент'];
    const {showGroupAnswer} = this.state;

    return (
      <div>
        <Button additionalClasses='mb-4' onClick={() => this.showGroupAnswerModal()}>Групповой ответ</Button>
        {showGroupAnswer && <staff.TicketGroupAnswer tickets={this.selectedTickets()} onHide={() => this.hideGroupAnswerModal()} />}
        <TableOrNoContent cols={cols} loadingInProgress={this.props.loadingInProgress}>
          {(this.props.tickets || []).map((t) => this.renderRow(t))}
        </TableOrNoContent>
        <Button onClick={() => this.showGroupAnswerModal()}>Групповой ответ</Button>
      </div>
    );
  }

  showGroupAnswerModal() {
    this.setState({ showGroupAnswer: true });
  }

  hideGroupAnswerModal() {
    this.setState({ showGroupAnswer: false });
  }

  selectedTickets() {
    const {selected} = this.state;
    return this.props.tickets.filter(t => selected.indexOf(t.id) != -1);
  }

  onSelectChange(ticketId, value) {
    const deselect = prevState => ({ selected: prevState.selected.filter(id => id != ticketId) })
    const select = prevState => ({ selected: prevState.selected.concat(ticketId) })
    this.setState(value ? select : deselect);
  }
}

export default TicketTable;
