const localCache = {
  get(key) { return this.values()[key] },
  set(data) { this.write(Object.assign(this.values(), data)) },
  // "private" methods. Do not use them
  values() { return JSON.parse(localStorage.getItem('store') || '{}') },
  write(object) { localStorage.setItem('store', JSON.stringify(object)) },
}

/* For reducers */
function withWritingToLocalCache(state, change) {
  localCache.set(change)
  return Object.assign({}, state, change);
}

export { localCache, withWritingToLocalCache };
