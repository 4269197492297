import {connect} from 'react-redux'

const repo = new repositories.ChatGPTModelRepo();

class ChatGPTModelForm extends Component {
  state = {
    errors: null,
    uploading: false,
  }

  addFile = (file) => {
    this.props.onChangeFile(file)
  };

  deleteFile = () => {
    this.props.onChangeFile(null)
  };

  setUploading = (uploading) => this.setState({ uploading });

  runFineTune = () => {
    this.setState({ requestTraining: true });

    repo.runFineTune(this.props.model.id).then(
      (data) => {
        this.props.onReload && this.props.onReload();
        this.setState({ requestTraining: false });
      },
      (data) => this.setState({ errors: this.parsedErrors(data), requestTraining: false }),
    )
  }

  parsedErrors = ({responseJSON}) => responseJSON && responseJSON.errors || ["Произошла ошибка"];

  render() {
    const { onChange, model: { name } } = this.props;
    const { requestTraining } = this.state;
    const { id, training_file_id, training_file, status } = this.props.model;

    const trainingStarted = status !== 'new';

    return (
      <div>
        <h4>Название *</h4>
        <TextInput required value={name} onChange={(value) => onChange('name', value)} />
        <Margin size="10" />
        {
          id && (
            <>
              <Margin size="10" />
              <h4>Загрузите проверенный обучающий файл</h4>
              <AttachmentList
                files={training_file && [training_file] || []}
                single
                onUpload={!trainingStarted && this.addFile}
                onDelete={!trainingStarted && this.deleteFile}
                buttonLabel={'Выбрать файл'}
                setUploading={this.setUploading}
                meta={{
                  check_chat_gpt_file: true
                }}
              />
              <Margin size="10" />
              {status === 'pending' && <Notice type='done'>Обучение может занять от 15 минут до нескольких часов в зависимости от объёма данных и загруженности сервиса GPT.</Notice>}
              <Margin size="10" />
            </>
          )
        }
        <Button
          size='xs'
          onClick={this.props.onSubmit}
          disabled={this.props.processing || !name}
        >
          {id ? 'Сохранить' : 'Создать'}
        </Button>
        {id && (
          <Button
            size='xs'
            color='green'
            onClick={this.runFineTune}
            disabled={requestTraining || trainingStarted || !training_file_id}
          >
            Начать обучение
          </Button>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  managers: state.managers.filter((manager) => !manager.admin),
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(ChatGPTModelForm);
