import {findById} from 'app/lib/utils'
import {connect} from 'react-redux'

const typeSelectItems = [
  { id: 'add_tag',         label: 'Добавить тег' },
  { id: 'remove_tag',      label: 'Удалить тег' },
  { id: 'add_message',     label: 'Написать сообщение' },
  { id: 'add_note',        label: 'Оставить заметку' },
  { id: 'set_status',      label: 'Установить статус' },
  { id: 'change_assignee', label: 'Сменить ответственного' },
];

const TypeSelect = ({ current, onChange }) =>
  <DropdownTrigger items={typeSelectItems} onSelect={id => onChange({ action_type: id, action_value: null })}>
    {findById(current, typeSelectItems).label}
  </DropdownTrigger>;

const OrderWeightSelect = ({ current, onChange }) =>
  <DropdownTrigger items={[1, 2, 3, 4, 5, 6].map(i => ({ id: i, label: i }))} onSelect={n => onChange({ order_weight: n })}>
    {current || 'Не выбрана'}
  </DropdownTrigger>;

const ScriptActionForm = function(props) {
  const {action, onChange, onDelete, tags, managers} = props;
  const changeValue = v => onChange({ action_value: v });

  const value = action.action_value;
  const type = action.action_type;
  let valueComponent;
  if(type === 'add_tag' || type === 'remove_tag') {
    valueComponent = <staff.TagSelect current={value} tags={tags} onChange={changeValue} />;
  } else if(type === 'set_status') {
    valueComponent = <StatusSelect current={value} onChange={changeValue} />;
  } else if(type === 'change_assignee') {
    valueComponent = <ManagerSelect nullLabel='Освободить' managers={managers} current={value} onChange={changeValue} />;
  } else {
    const color = type === 'add_note' ? 'yellow' : null
    valueComponent = <Textarea color={color} value={value || ''} onChange={changeValue} />;
  }

  return (
    <div className='script-action-form'>
      <TypeSelect current={action.action_type} onChange={onChange} />
      <Margin size='8' />
      {valueComponent}
      <Margin size='16' />
      Сортировка: <OrderWeightSelect current={action.order_weight} onChange={onChange} />
      <Margin size='8' />
      <a onClick={onDelete}>удалить</a>
    </div>
  )
}

const mapStateToProps = s => ({ managers: s.managers, tags: s.tags });
export default connect(mapStateToProps)(ScriptActionForm);
