import spinnerImgSrc from 'app/assets/spinner.svg'

class Spinner extends Component {
  render() {

    return (
      <div className='spinner'>
        <img className='spinner__img' src={spinnerImgSrc} />
      </div>
    );
  }
}

export default Spinner;
