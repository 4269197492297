const AddMessage = function(props) {
  const {action, value, onChange} = props;
  const text = typeof(value) === 'string' ? value : action.action_value
  return (
    <div>
      Добавить сообщение:
      <Textarea value={text} onChange={onChange} />
    </div>
  );
}

export default AddMessage;
