class EditUserPage extends Component {
  state = {
    user: null,
    featureFlags: {},
    dataLoading: false,
    errors: {},
    showSuccess: false,
    showError: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({dataLoading: true});

    const repo = new repositories.UserRepo();

    repo.get(this.props.match.params.id)
      .then((user) => {
        this.setState({
          user,
          featureFlags: user.feature_flags || {},
          dataLoading: false,
        })
      })
      .catch(data => {
        if (data.status === 403) {
          return browserHistory.push('/staff');
        }

        this.setState({dataLoading: false});
      })
  }

  getCheckboxChangeHandler = (name) => (value) => {
    this.setState((state) => ({
        featureFlags: {
          ...state.featureFlags,
          [name]: {
            ...state.featureFlags[name],
            value,
          },
        },
    }));
  };

  handleSave = () => {
    const { user, featureFlags } = this.state;
    const repo = new repositories.UserRepo();

    const mappedFeatureFlags = Object.keys(featureFlags)
      .reduce((acc, name) => {
        acc[name] = featureFlags[name].value;
        return acc;
      }, {});

    const params = {
      id: user.id,
      user: mappedFeatureFlags,
    };

    repo.update(params)
      .then(data => {
        this.setState({ showSuccess: true, showError: false, errors: {} });
      })
      .catch(data => {
        this.setState({ errors: data.responseJSON.errors, showError: true, showSuccess: false });
        console.log(this.state);
      })
  };

  render() {
    const { dataLoading, user, featureFlags, showSuccess, showError } = this.state;

    return (
      <layouts.Staff>
        <Card stretch>
          <h2>Пользователь {user?.full_name}</h2>
          <Margin size="20"/>
          { dataLoading && <div>Загрузка...</div> }
          { showSuccess && <Notice type="done">Успешно сохранено</Notice> }
          { showError && <Notice type="error">Произошла ошибка</Notice> }
          <Margin size="5"/>
          { Object.keys(featureFlags).map((name) => (
                <div key={name}>
                    <Checkbox label={featureFlags[name].admin_description}
                      checked={featureFlags[name].value}
                      onChange={this.getCheckboxChangeHandler(name)}
                    />
                </div>
            ))}
            <Margin size="20"/>
            <Button onClick={this.handleSave}>Сохранить</Button>
        </Card>
      </layouts.Staff>
    );
  }
}

export default EditUserPage;
