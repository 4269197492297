import {reloadManagers, reloadTags, reloadCategories} from 'app/store/helpers'

class StatisticExecutor extends Component {
  state = {}

  componentDidMount() {
    this.getData();
    reloadManagers();
    reloadTags();
    reloadCategories();
  }

  getData() {
    const repo = new repositories.StatisticsRepo();
    repo.getTypes().then(types => this.setState({ types }),
                         data => console.log(data));
  }

  render() {
    const {types} = this.state;
    if(!types)
      return null;

    return (
      <div>
        <DropdownTrigger items={this.optionsForTypeSelect()} onSelect={sn => this.onTypeSelect(sn)}>
          {this.selectedTypeName() || 'Выберите тип статистики'}
        </DropdownTrigger>
        <Margin size='8' />
        {this.renderParameters()}
        <Margin size='8' />
        {
          this.selectedType() &&
          <Button onClick={() => this.execute()}>Выполнить</Button>
        }
      </div>
    )
  }

  renderParameters() {
    if(!this.selectedType())
      return null

    const {parameters} = this.state;
    const onChange = change => this.setState({ parameters: Object.assign(parameters, change) });

    return <staff.statistic.Parameters values={parameters}
                                              onChange={onChange}
                                              statisticType={this.selectedType()} />;
  }

  optionsForTypeSelect() {
    return this.state.types.map(t => ({ id: t.system_name, label: t.name }));
  }

  onTypeSelect(systemName) {
    this.setState({ parameters: {}, selected: systemName });
  }

  selectedTypeName() {
    const s = this.selectedType();
    return s ? s.name : null;
  }

  selectedType() {
    const {types, selected} = this.state;
    if(!selected)
      return null;
    return types.filter(t => t.system_name == selected)[0];
  }

  execute() {
    if(0 < this.blankRequiredParams().length) {
      alert('Required: ' + this.blankRequiredParams().join(', '));
      return;
    }

    const {parameters} = this.state;
    const type = this.selectedType();

    const {onComplete} = this.props;
    const repo = new repositories.StatisticsRepo();
    repo.get(type.system_name, parameters)
        .then(onComplete, data => this.showErrorsFromResponse(data));
  }

  blankRequiredParams() {
    const {parameters} = this.state;
    const type = this.selectedType();

    const result = [];
    for(let param of type.requiredParams()) {
      if(!parameters[param] || parameters[param] === [])
        result.push(param);
    }

    return result;
  }

  showErrorsFromResponse({responseJSON}) {
    let errors = ["unknown error"];
    if(responseJSON && responseJSON.errors) { errors = responseJSON.errors; }
    alert("Ошибка! " + errors.join('; '));
  }
}

export default StatisticExecutor;
