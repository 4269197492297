import PropTypes from 'prop-types'

/*
 * Shows prompt modal on click and displays itself as children
 */
class PopupPrompt extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    onOk: PropTypes.func.isRequired,
    initialInput: PropTypes.string,
  }

  constructor(props) {
    super(props);
    this.state = { input: props.initialInput || '' };
  }

  render() {
    const {children, text} = this.props;
    const {show} = this.state;
    return (
      <div className='popup-prompt'>
        <div className='popup-prompt popup-prompt__children' onClick={() => this.showModal()}>{children}</div>
        {show && this.renderModal()}
      </div>
    );
  }

  renderModal() {
    const {text, onOk} = this.props;
    const {input} = this.state;

    const onSubmit = e => {
      e.preventDefault();
      this.hideModal();
      onOk(input);
    }

    return (
      <Modal onHide={() => this.hideModal()}>
        <h2>{text}</h2>
        <form onSubmit={onSubmit}>
          <TextInput value={input} onChange={v => this.setState({ input: v })} />
          <Margin size="10" />
          <Button small disabled={!input}>Ok</Button>
        </form>
      </Modal>
    )
  }

  showModal() {
    this.setState({ show: true });
  }

  hideModal() {
    this.setState({ show: false });
  }
}

export default PopupPrompt;
