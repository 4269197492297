import {formatDate} from 'app/lib/utils';
import {connect} from 'react-redux'
import {reloadManagers} from 'app/store/helpers'
import qs from 'query-string'

const ManagerCall = ({call}) =>
  <div className='manager-call'>
    <div className='manager-call__heading'>
      {formatDate(call.answered_at)} поступил звонок. &nbsp;
      <span className='manager-call__duration'>
        Продолжительность: {call.duration} сек
      </span>
    </div>
    <Spaced w={20}>
      {call.getManagerIds().map(id => <ManagerById key={id} id={id} />)}
    </Spaced>
  </div>;


// /staff/managers/activity
class ActivityFeedPage extends Component {
  constructor() {
    super();
    this.state = { from: null, to: null };
  }

  componentDidMount() {
    this.getData()
  }
  componentDidUpdate() {
    if(!this.state.loadingInProgress && this.needToUpdateData())
      this.getData();
  }

  getData() {
    this.setState({ loadingInProgress: true });
    reloadManagers();
    const params = this.apiRequestParams();

    API.managers.activityFeed.get({
      params: params,
      success: ({activities, pagination}) => this.setState({ activities, pagination }),
      error: data => console.log(data),
      complete: () => this.setState({ lastRequestParams: params, loadingInProgress: false }),
    });
  }

  render() {
    if (!this.state.activities) {
      return <PageTransition />;
    }

    const {managers, currentUser} = this.props;
    const {activities, from, to} = this.state;

    const isPartner = currentUser.kind == 'manager' && currentUser.isPartner();

    return (
      <layouts.Staff>
        <Card stretch>
          <Margin size="20 0">
            <h2>Лента активности</h2>
          </Margin>

          {!isPartner && this.renderManagerFilter()}
          <Margin size="15" />call
          <TimeFilter from={from} to={to} onChange={({from, to}) => this.setState({ from, to })}/>

          <Margin size="20 0" />

          {activities.map(a => this.renderActivity(a))}
          <Margin size='8' />
          {this.renderPagination()}
        </Card>
      </layouts.Staff>
    );
  }

  needToUpdateData() {
    return JSON.stringify(this.state.lastRequestParams) != JSON.stringify(this.apiRequestParams());
  }

  apiRequestParams() {
    const query = qs.parse(this.props.location.search)
    const {manager} = query;
    const page = query.page || 1;
    const {from, to} = this.state;
    return { manager_ids: [manager], page, from, to };
  }

  renderManagerFilter() {
    const generateUrl = manager => manager ? `/staff/managers/activity?manager=${manager}` : '/staff/managers/activity';
    const onChange = id => browserHistory.push(generateUrl(id));

    return <staff.ActiveManagerSelect withNull
                                             selected={qs.parse(this.props.location.search).manager - 0}
                                             onChange={onChange} />;
  }

  renderActivity(activity) {
    switch(activity.activity_type) {
      case 'ticket_event': return this.renderAnswer(activity);
      case 'call': return <ManagerCall key={`call_${activity.id}`} call={new entities.Call(activity)} />;
      default: console.log("can't render activity", activity)
    }
  }

  renderAnswer(answer) {
    const findManager = id => this.props.managers.find(m => m.id == id);
    const linkToTicket = ticket => `/staff/${ticket.id}`;
    const ticket = answer.ticket;
    let personNote;
    if(ticket) {
      personNote = (
        <span>
          &nbsp; - &nbsp;
          {formatDate(answer.created_at)} к тикету
          &nbsp; <Link to={linkToTicket(ticket)}>{answer.ticket.subject}</Link>
          <Margin size='4' />
          <TagList small tags={ticket.tags} />
          <Margin size='8' />
        </span>
      );
    } else {
      personNote = (
        <span>
          ({formatDate(answer.created_at)} к удаленному тикету)
        </span>
      );
    }
    const color = answer.kind == 'note' ? 'yellow' : 'grey';
    return (
      <div key={answer.id} >
        <Message text={answer.message} person={findManager(answer.manager_id)} personNote={personNote} color={color}/>
      </div>
    );
  }

  renderPagination() {
    const manager = qs.parse(this.props.location.search).manager;
    const baseUrl = `/staff/managers/activity?page=:page${manager ? ('&manager=' + manager) : ''}`;
    const urlGenerator = (page) => baseUrl.replace(':page', page);
    return <Pagination data={this.state.pagination} urlGenerator={urlGenerator} />
  }
}

const mapStateToProps = s => ({ managers: s.activeManagers, currentUser: s.currentUser });
export default connect(mapStateToProps)(ActivityFeedPage);
