/*
 * Functions for using in `mapStateToProps` for transforming data from store.
 */

const sortedScripts = function(state) {
  const comparator = function(a, b) {
    const sortings = state.userSettings.scriptSortings || {};
    const aSort = sortings[a.id];
    const bSort = sortings[b.id];
    if(!aSort) {
      return -1;
    }
    if(!bSort) {
      return 1;
    }

    return aSort < bSort ? -1 : 1;
  }
  return state.scripts.sort(comparator);
}

const sortedAnswerTemplates = function(state) {
  const comparator = function(a, b) {
    const sortings = state.userSettings.answerTemplateSortings || {};
    const aSort = sortings[a.id];
    const bSort = sortings[b.id];
    if(!aSort) {
      return -1;
    }
    if(!bSort) {
      return 1;
    }

    return aSort < bSort ? -1 : 1;
  }
  return state.answerTemplates.sort(comparator);
}

export {sortedScripts, sortedAnswerTemplates};
