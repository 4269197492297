import { connect } from 'react-redux'
import { reloadCurrentUser, reloadTags, reloadGroups } from 'app/store/helpers'
import scrollToTop from 'app/lib/scrollToTop'

class ManagerEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    if(!this.state.managerLoadingInProgress)
      this.getData();
  }

  componentDidUpdate() {
    const manager = this.state.manager;
    const loadedManagerId = manager && manager.id;
    const requestedManagerId = this.props.match.params.id - 0;

    if(!this.state.managerLoadingInProgress && requestedManagerId != loadedManagerId) {
      this.getData();
    }
  }

  getData() {
    const logArgument = arg => console.log(arg);
    reloadCurrentUser();
    reloadTags();
    reloadGroups();
    this.setState({ password: null, managerLoadingInProgress: true });
    const repo = new repositories.ManagerRepo();
    repo.get(this.props.match.params.id)
        .then(m => this.setManager(m), logArgument);
  }

  render() {
    if (!this.state.manager) {
      return <PageTransition />;
    }

    const {manager, showSuccess, showError, formData} = this.state;
    const errors = this.state.errors || {};

    const currentUser = this.props.currentUser;
    const groups = this.props.groups.map((group) => {
      return { id: group.id, label: group.title }
    })
    const currentGroup = groups.find( group => group.id == formData.group_id)
    const currentUserHimself = currentUser.id == manager.id;

    return (
      <layouts.Staff>
        <Card stretch>
          {showSuccess && <Notice type="done">Менеджер сохранен</Notice>}
          {showError && <Notice type="error">Произошла ошибка</Notice>}
          <Margin size="20" />
          <SplitSides>
            <Person person={manager} big />
            <div>{manager.email}</div>
          </SplitSides>
          {manager.isPartner() && this.renderPartnerLabel(manager)}
          <Margin size="10" />
          <Checkbox label="Админ" checked={formData.admin || false}
                           disabled={!currentUser.admin}
                           onChange={v => this.setFormData({ admin: v })} />
          <Margin size="4" />
          <Checkbox label="Показывать в таблице активности"
                           checked={formData.show_in_activity_ranking}
                           disabled={!currentUser.admin}
                           onChange={v => this.setFormData({ show_in_activity_ranking: v })} />
          <Margin size="4" />
          <Checkbox label="Неактивный (скрыт во многих селектах и пр)"
                           checked={formData.inactive}
                           disabled={!currentUser.admin}
                           onChange={v => this.setFormData({ inactive: v })} />
          <Margin size="4" />
          <Checkbox label='Работа только через кнопку "Взять тикет"'
                           disabled={!currentUser.admin}
                           checked={formData.working_via_autotake_only || false}
                           onChange={v => this.setFormData({ working_via_autotake_only: v })} />
          <Margin size="4" />
          <Checkbox label="Заблокирован"
                           disabled={!currentUser.admin}
                           checked={formData.archived_at != null}
                           onChange={v => this.setBlock(v)}/>
          <Margin size="4" />
          <Checkbox label="Отключить уведомления по email"
                           disabled={!currentUser.admin && !currentUserHimself}
                           checked={formData.disable_email_notifications}
                           onChange={v => this.setFormData({ disable_email_notifications: v })}/>

          <Margin size="20" />
          <Spaced h={40}>
            <LabeledInput label="email">
              <TextInput value={formData.email}
                                disabled={!currentUser.admin || !manager.isPartner()}
                                onChange={v => this.setFormData({ email: v })} />
            </LabeledInput>
            <LabeledInput label="ФИО" error={errors.full_name && errors.full_name.join('; ')}>
              <TextInput value={formData.full_name}
                                disabled={!currentUser.admin && !currentUserHimself}
                                onChange={v => this.setFormData({ full_name: v })} />
            </LabeledInput>
            {(formData.signature !== '' || currentUser.admin) &&
              <LabeledInput label="Подпись" error={errors.content && errors.content.join('; ')}>
                <Textarea value={formData.signature}
                                disabled={!currentUser.admin}
                                onChange={v => this.setFormData({ signature: v })} />
              </LabeledInput>
            }
            <LabeledInput label="группа">
              <DropdownTrigger className='test-class' items={ groups } onSelect={id => this.setFormData({ group_id: id })}>
                <div className='appbar__menu-inner'>
                  <div className='appbar__menu-title'>
                    <span>{ currentGroup && currentGroup.label }</span>
                  </div>
                  <div className='appbar__menu-icon'>
                    <Icon name="arrow_drop_down" className="valign-middle" />
                  </div>
                </div>
              </DropdownTrigger>
            </LabeledInput>
          </Spaced>
          <Margin size="30 0" />
          {(currentUser.admin || currentUserHimself) && this.renderPasswordBlock()}
          <Margin size="16" />
          {(currentUser.admin || currentUserHimself) && this.renderTelegramTokenBlock()}
          <Margin size="30" />
          {this.renderAssignments()}

          <Button onClick={() => this.onSubmit()} disabled={!currentUser.admin && !currentUserHimself}>СОХРАНИТЬ</Button>
        </Card>
      </layouts.Staff>
    );
  }

  renderPartnerLabel(manager) {
    const title = manager.partnerTitle() || 'Да (ошибка: название отсутствует)';
    const id = manager.partnerId();
    return (
      <div>
        Партнер: {title} {id && `(id: ${id})`}
      </div>
    )
  }

  renderAssignments() {
    const tags = this.props.tags;
    const {formData, manager} = this.state;
    const currentUser = this.props.currentUser;
    const setFormAssignment = (tagId, kind) => this.setFormData({ assignments: Object.assign(formData.assignments, { [tagId]: kind }) });
    const renderRow = function(tag) {
      const kind = formData.assignments[tag.id];
      return (
        <tr key={tag.id}>
          <td><Tag data={tag} /></td>
          <td>
            <Spaced w={40}>
              <Radio name={tag.id}
                     label="Нет"
                     onChange={() => setFormAssignment(tag.id, null)}
                     disabled={!currentUser.admin}
                     checked={!kind} />
              <Radio name={tag.id}
                     label="На присмотр"
                     onChange={() => setFormAssignment(tag.id, 'viewing')}
                     disabled={!currentUser.admin}
                     checked={kind == 'viewing'} />
              <Radio name={tag.id}
                     label="На разбор"
                     onChange={() => setFormAssignment(tag.id, 'solving')}
                     disabled={!currentUser.admin}
                     checked={kind == 'solving'} />
            </Spaced>
          </td>
        </tr>
      );
    }
    return (
      <Table cols={['Тег', 'Права']}>
        {tags.map(tag => renderRow(tag))}
      </Table>
    );
  }

  renderPasswordBlock() {
    const generatePassword = () => API.managers.generatePassword({
      id: this.state.manager.id,
      success: ({password}) => this.setState({ password })
    });
    const {password} = this.state;

    return (
      <div>
        <Button onClick={generatePassword} color='green'>Сгенерировать пароль</Button>
        {password && <span>&nbsp; {password}</span>}
      </div>
    );
  }

  renderTelegramTokenBlock() {
    const {manager} = this.state;
    const generateToken = () => API.managers.generateTelegramToken({
      id: this.state.manager.id,
      success: ({telegram_token}) => {
        this.setState({ manager: Object.assign(this.state.manager, { telegram_token }) })
      }
    });

    return (
      <div>
        Telegram-токен: {manager.telegram_token}
        <br />
        <Button small onClick={generateToken}>Сгенерировать новый</Button>
        <p>
          Бот нужен для разных уведомлений.
          <a href='https://telegram.me/insales_tickets_bot' target='_blank' rel='noopener noreferrer'>@insales_tickets_bot</a>
        </p>
      </div>
    );
  }

  setManager(manager) {
    const formData = {
      email: manager.email,
      full_name: manager.full_name,
      signature: manager.signature,
      admin: manager.admin,
      archived_at: manager.archived_at,
      inactive: manager.inactive,
      working_via_autotake_only: manager.working_via_autotake_only,
      show_in_activity_ranking: manager.show_in_activity_ranking,
      disable_email_notifications: manager.disable_email_notifications,
      group_id: manager.group_id,
      assignments: {},
    };
    for(let ma of manager.manager_assignments) {
      formData.assignments[ma.tag_id] = ma.kind;
    }

    this.setState({ manager, formData, managerLoadingInProgress: false });
  }

  setFormData(data) {
    this.setState({ formData: Object.assign(this.state.formData, data) });
  }

  setBlock(check) {
    if (check) {
      this.setFormData({ archived_at: new Date() })
    } else {
      this.setFormData({ archived_at: null })
    }
  }

  onSubmit() {
    const repo = new repositories.ManagerRepo();
    const onError = e => {
      console.log(e);
      this.onUpdateError(e.responseJSON);
    }
    repo.update(this.state.manager.id, this.state.formData)
        .then(m => this.onUpdateSuccess(m), onError);

    this.setState({ showSuccess: false, showError: false, errors: {} });
  }

  onUpdateSuccess(manager) {
    this.setManager(manager);
    this.setState({ showSuccess: true });
    scrollToTop();
  }

  onUpdateError(response) {
    this.setState({ showError: true, errors: response && response.errors  });
    scrollToTop();
  }
}

const mapStateToProps = state => ({ currentUser: state.currentUser, tags: state.tags, groups: state.groups });
export default connect(mapStateToProps)(ManagerEdit);
