import qs from 'query-string'
import SpamMarker from './partials/SpamMarker'

class EmailsList extends Component {
  state = {};

  componentDidMount() {
    this.setState({scope: qs.parse(this.props.location), dataLoading: true});
    this.getData();
  }

  currentPage() {
    return qs.parse(this.props.location.search).page || 1;
  }

  needToRefreshData() {
    return this.currentPage() != this.state.page;
  }

  getData() {
    const repo = new repositories.EmailsRepo();
    const query = qs.parse(this.props.location.search);
    const page = query && query.page ? query.page : 1;


    repo.getAll({page: page})
        .then(data => this.setState(
          {
            emails: data.emails,
            pagination: data.pagination,
            dataLoading: false,
            page: this.currentPage()
          }));
  }

  render() {
    const emails = this.state.emails;

    if(this.needToRefreshData()) {
      this.getData();
    }

    return (
      <layouts.Staff>
        <Card stretch>
          <div id='emails-list'>
            <Table cols={['Тема', 'От кого', 'Кому', '']}>
              {emails && emails.map(email => this.generateEmailRow(email))}
            </Table>
            <Margin size='8' />
            <Pagination
              data={this.state.pagination}
              urlGenerator={(n) => '/staff/emails?page=' + n}
              onChange={(n) => this.setState({page: n})}/>
          </div>
        </Card>
      </layouts.Staff>
    )
  }

  generateEmailRow(email) {
    return(
      <tr onClick={() => window.open('/staff/emails/' + email.id, '_self')} key={email.id}>
        <td>
          <p>{email.subject}</p>
        </td>
        <td>
          <p>{email.from}</p>
        </td>
        <td>
          <p>{email.to}</p>
        </td>
        <td>
          <SpamMarker email={email} />
        </td>
      </tr>
    )
  }
}
export default EmailsList;
