import cookie from 'cookie'

class ManagerRepo {
  getAll(params = {}) {
    if (!params.group_id) {
      params.group_id = cookie.parse(document.cookie).g
    }
    const promise = new Promise(function(resolve, reject) {
      const toEntities = data => data.map(s => new entities.Manager(s));

      API.managers.getAll({
        params,
        success: data => resolve(toEntities(data)),
        error: reject,
      });
    });

    return promise;
  }

  get(id) {
    const promise = new Promise(function(resolve, reject) {
      API.managers.get({
        id,
        success: m => resolve(new entities.Manager(m)),
        error: reject,
      });
    });

    return promise;
  }

  update(id, params) {
    const promise = new Promise(function(resolve, reject) {
      API.managers.update({
        id,
        params,
        success: m => resolve(new entities.Manager(m)),
        error: reject,
      });
    });

    return promise;
  }
}

export default ManagerRepo;
