import {connect} from 'react-redux'
import {reloadChatGPTModels} from 'app/store/helpers'

class TicketEventChatGPTModal extends Component {
  state = {
    message: '',
    errors: null,
    processing: false,
    chat_gpt_model_id: null,
  }

  componentDidMount() {
    reloadChatGPTModels()
    this.setState({ message: this.props.event.message });
  }

  parsedErrors = ({ responseJSON, status }) => {
    let errors = []

    if (status === 403) {
      errors = ['Доступ запрещён.']
      return
    } else {
      errors = responseJSON && responseJSON.errors || ["Произошла ошибка"]
    }

    return errors;
  }

  onSuccess = () => {
    this.setState({ processing: false })
    this.props.onHide()
  }

  onSubmit = () => {
    const { id, ticket_id } = this.props.event;
    const { message, chat_gpt_model_id } = this.state;
    this.setState({ errors: null, processing: true });

    API.ticketEvents.createChatGPTNote({
      ticketId: ticket_id,
      id,
      success: this.onSuccess,
      error:  (data) => this.setState({ processing: false, errors: this.parsedErrors(data) }),
      params: { message, chat_gpt_model_id }
    });
  }

  onChange = (value) => this.setState({ message: value })

  onChangeModel = (chat_gpt_model_id) => this.setState({ chat_gpt_model_id });

  render() {
    const { onHide, chatGPTModels } = this.props;
    const { message, errors, processing, chat_gpt_model_id } = this.state;

    return (
      <Modal onHide={onHide}>
        <h2>Chat GPT</h2>
        <div className='message-form'>
          <Margin size='10' />
          <ChatGPTModelSelect
            nullLabel='Выбрать модель'
            models={chatGPTModels}
            current={chat_gpt_model_id}
            onChange={this.onChangeModel}
          />
          <Margin size='10' />
          <LabeledInput label={'Вы можете отредактировать текст перед отправкой в chatgpt, обязательно удалите персональные данные во избежании утечек. Время обработки запроса примерно минута. Чтобы увидеть заметку после отправки перезагрузите страницу.'}>
            <Textarea value={message} onChange={this.onChange}/>
          </LabeledInput>
          <Margin size='10' />
          {errors && <ErrorNotice errors={errors} />}
          <div className="message-form__controls">
            <Button disabled={!message || !chat_gpt_model_id || processing} onClick={this.onSubmit}>ОТПРАВИТЬ</Button>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  chatGPTModels: state.chatGPTModels.filter((model) => model.fine_tuned_model && model.status === 'succeeded'),
});

export default connect(mapStateToProps)(TicketEventChatGPTModal);
