import moment from 'moment'

const selectOptions = [
  { id: 'allTime',      label: 'за все время' },
  { id: 'today',        label: 'сегодня' },
  { id: 'yesterday',    label: 'вчера' },
  { id: 'thisWeek',     label: 'эта неделя' },
  { id: 'prevWeek',     label: 'прошлая неделя' },
  { id: 'thisMonth',    label: 'этот месяц' },
  { id: 'prevMonth',    label: 'прошлый месяц' },
];

const now = moment();
const today = now.clone().startOf('day');
const yesterday = today.clone().subtract(1, 'day');
const thisWeek = now.clone().startOf('week');
const prevWeek = thisWeek.clone().subtract(1, 'week');
const thisMonth = now.clone().startOf('month');
const prevMonth = thisMonth.clone().subtract(1, 'month');

const optionValues = {
  allTime:     { from: null, to: null },
  today:       { from: today.unix(), to: null },
  yesterday:   { from: yesterday.unix(), to: today.unix() },
  thisWeek:    { from: thisWeek.unix(), to: null },
  prevWeek:    { from: prevWeek.unix(), to: thisWeek.unix() },
  thisMonth:   { from: thisMonth.unix(), to: null },
  prevMonth:   { from: prevMonth.unix(), to: thisMonth.unix() },
}

class TimeFilter extends Component {
  render() {
    const {onChange, from, to} = this.props;

    return (
      <div>
        <DropdownTrigger items={selectOptions} onSelect={id => onChange(optionValues[id])}>
          {this.selectedItemLabel()}
        </DropdownTrigger>
        &nbsp;&nbsp;
        <TimestampPicker inline value={from} onChange={v => onChange({ from: v, to })}/>
        &nbsp;&nbsp;
        <TimestampPicker inline value={to} onChange={v => onChange({ from, to: v })} />
      </div>
    );
  }

  selectedItemLabel() {
    const from = this.props.from || null;
    const to = this.props.to || null;
    if(!from && !to)
      return selectOptions.find(e => e.id == 'allTime').label;

    for(let optionId in optionValues) {
      const values = optionValues[optionId];
      if(values.from == from && values.to == to)
        return selectOptions.find(o => o.id == optionId).label;
    }

    return 'Особый интервал';
  }
}

export default TimeFilter;
