// TODO: I don't like using `style` property at all.
//       Can we do something about it?

class Spaced extends Component {
  componentDidMount = this.setMargins
  componentDidUpdate = this.setMargins

  setMargins() {
    jQuery(this.refs.root).find('> *').css({
      margin: `${this.vMargin()} ${this.hMargin()}`,
      verticalAlign: this.props.w && 'middle',
    })
  }

  render() {
    const {className, children} = this.props

     const style = {
       margin: `-${this.vMargin()} -${this.hMargin()}`,
       border: '1px solid transparent'
     }

     return <div ref="root" className={className} style={style}>{children}</div>
  }

  vMargin() {
    const result = ((this.props.h || 0) + 1) / 2;
    return `${result}px`;
  }

  hMargin() {
    const result = ((this.props.w || 0) + 1) / 2;
    return `${result}px`;
  }
}

export default Spaced;
