const mapToEntities = users => users.map(u => new entities.User(u));

class UserRepo {
  getAll(params) {
    const promise = new Promise(function(resolve, reject) {
      API.users.getAll({
        params,
        success: data => resolve(Object.assign(data, { users: mapToEntities(data.users) })),
        error: reject,
      });
    });

    return promise;
  }

  get(id) {
    const promise = new Promise(function(resolve, reject) {
      API.users.get({
        id,
        success: m => resolve(new entities.User(m)),
        error: reject,
      });
    });

    return promise;
  }

  update(params) {
    const promise = new Promise(function (resolve, reject) {
      API.users.update({
        id: params.id,
        params,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }
}

export default UserRepo;
