import {bemModifiers, fetchObjectSubset} from 'app/lib/utils';

function Icon(props) {
  const {name, size, className = '', onClick, title} = props;
  const style = { fontSize: size };
  const keys = ['yellow', 'red', 'green', 'clickable'];
  const modifiers = keys.filter(k => props[k]);
  const blockClass = `material-icons ${className} ` + bemModifiers('icon', modifiers);

  return (
    <div className={blockClass} onClick={onClick} style={style} title={title}>
      {name}
    </div>
  );
}

export default Icon;
