const AddNote = function(props) {
  const {action, value, onChange} = props;
  const text = typeof(value) === 'string' ? value : action.action_value;
  return (
    <div>
      Добавить заметку:
      <Textarea color='yellow' value={text} onChange={onChange} />
    </div>
  );
}

export default AddNote;
