import { fileErrorMessages } from "../lib/messages";

class AttachmentList extends Component {
  state = { uploading: [], error: null, openedCarousel: false, carouselStartIndex: 1 }

  renderError() {
    const { error } = this.state

    return (
      <Modal onHide={() => this.setState({ error: null })}>
        <h2>Не удается загрузить файл</h2>
        <Margin size='20 0 0 0'>
          <Notice type="error">{error}</Notice>
        </Margin>
      </Modal>
    )
  }

  images = () => {
    return this.props.files.filter(file => file.content_type?.includes('image'))
  }

  onClick = (e, { id, content_type }) => {
    if (content_type?.includes('image')) {
      e.preventDefault()

      const carouselStartIndex = this.images().findIndex((image) => image.id === id)

      this.setState({ openedCarousel: true, carouselStartIndex })
    }
  }

  onHideCarousel = () => {
    this.setState({ openedCarousel: false })
  }

  renderFiles = () => {
    const { onDelete } = this.props;
    const { uploading, openedCarousel, carouselStartIndex } = this.state;
    const files = this.props.files || [];
    const images = this.images()

    return (
      <>
        {files.map(f => <Attachment onDelete={onDelete} key={f.url} file={f} onClick={(e) => this.onClick(e, f)} />)}
        {uploading.map(f => <Attachment key={f.uploadingId} file={f} />)}
        {images.length > 0 && openedCarousel && (
          <ImageSlider onHide={this.onHideCarousel}
                       images={images}
                       startIndex={carouselStartIndex}
          />
        )}
      </>
    )
  }

  render() {
    const { onUpload, single } = this.props;
    const { error } = this.state;
    
    return (
      <div className='attachment-list'>
        <input className='attachment-list__input'
               type="file"
               multiple={!single}
               ref='input'
               onChange={() => this.addFilesFromInput()} />

        {onUpload && this.renderUploadButton()}
        {this.renderFiles()}
        {error && this.renderError()}
      </div>
    );
  }

  renderUploadButton() {
    return (
      <a className="attachment-list__add-file" onClick={() => this.refs.input.click()}>
        <IconText icon="attach_file" size={16} margin={10}>{this.props.buttonLabel || 'Прикрепить файлы'}</IconText>
      </a>
    )
  }

  addFilesFromInput() {
    const inputFiles = this.refs.input.files;
    this.props.setUploading && this.props.setUploading(true);
    Array.prototype.forEach.call(inputFiles, f => this.uploadFile(f));
    this.refs.input.value = ''; // clear input
  }

  uploadFile(f) {
    const fileState = { name: f.name,
                        size: f.size,
                        uploadingId: `${f.name}_${f.size}_${+ new Date()}`,
                        progress: 0 }
    this.state.uploading.push(fileState); // NOTE: component won't rerender at this point

    const repo = new repositories.AttachmentRepo();
    const promise = repo.upload(f, percent => {
      fileState.progress = percent;
      this.forceUpdate();
    }, this.props.meta || {});
    promise.then(
      xhr => this.onUploadComplete(fileState, xhr),
      xhr => this.onUploadFail(fileState, xhr)
    );
  }

  onUploadComplete(uploadedFile, xhr) {
    const {uploadingId} = uploadedFile;
    this.setState(s => ({ uploading: s.uploading.filter(f => f.uploadingId != uploadingId) }));
    const file = Object.assign(
      { name: uploadedFile.name, size: uploadedFile.size },
      JSON.parse(xhr.responseText)
    );
    this.props.onUpload(file);
    this.props.setUploading && this.props.setUploading(this.state.uploading.length > 0);
  }

  onUploadFail(uploadedFile, xhr) {
    const {uploadingId} = uploadedFile;
    this.setState(s => ({ uploading: s.uploading.filter(f => f.uploadingId != uploadingId) }));

    let error = `${xhr.status} ${xhr.statusText}`
    if(xhr.responseJSON && xhr.responseJSON.errors) {
      error = xhr.responseJSON.errors.join("\n");
    } else if (xhr.status == 413) {
      error = fileErrorMessages['file-too-large']
    } else if (xhr.responseText) {
      try {
        const json = JSON.parse(xhr.responseText)
        error = json?.errors?.join("\n");
      } catch (_) {}
    }

    this.setState({ error });
  }
}

export default AttachmentList;
