import { connect } from 'react-redux'
import { reloadCurrentUser, reloadGroups } from 'app/store/helpers'
import cookie from 'cookie'

class Staff extends Component {
  componentDidMount() {
    reloadCurrentUser();
    reloadGroups();
  }

  render() {
    if (this.props.groups && this.props.currentUser && !cookie.parse(document.cookie).g) {
      document.cookie = cookie.serialize('g', this.props.currentUser.group_id || 1, { maxAge: 60 * 60 * 24 * 7, path: '/' })
    }

    const {side, children} = this.props;

    return (
      <div className='staff'>
        <DocumentTitle />

        <layouts.partials.Appbar person={this.props.currentUser} groups={this.props.groups} logoUrl="/staff" withSearch />
        <Margin size="20 0">
          <layouts.partials.SiteContent>
            <layouts.partials.staff.MainMenu currentUser={this.props.currentUser} />
            {side ? <TwoCols side={side}>{children}</TwoCols> : children}
          </layouts.partials.SiteContent>
        </Margin>
      </div>
    );
  }
}
const mapStateToProps = state => ({ groups: state.groups, currentUser: state.currentUser });
export default connect(mapStateToProps)(Staff);

