import {iterateObject} from 'app/lib/utils';

/*
 * props:
 * * tags - [Array<tag>]
 * * onSelect - [function(tag)] Callback for tag onclick event
 * * highlight - [Array<id>] ids of tags that should be highlighted
 * * small - [flag] add `small` to Tag component
 */
class TagList extends Component {
  isHighlighted(tag) {
    if(!tag.id || !this.props.highlight) {
      return false;
    }
    const highlight = this.props.highlight.map(e => e - 0); // convert to ints
    return highlight.includes(tag.id - 0)
  }
  renderTag(tag) {
    const {onSelect, onDelete} = this.props;
    return (
      <Tag key={tag.id}
           data={tag}
           small={this.props.small}
           onClick={onSelect && (() => onSelect(tag))}
           onDelete={onDelete}
           highlight={this.isHighlighted(tag)} />
    )
  }
  render() {
    const tags = this.props.tags || [];
    return (
      <div className='tag-list'>
        {tags.map((t, i) => this.renderTag(t))}
        {this.props.onAdd && this.renderAddTagButton()}
      </div>
    );
  }

  renderAddTagButton() {
    const {tags, availableTags, onAdd} = this.props;
    const selectedTagsIds = tags.map(t => t.id);
    const unselectedTags = (availableTags || []).filter(t => selectedTagsIds.indexOf(t.id) == -1);

    const items = [];
    iterateObject(this.groupTags(unselectedTags), function(name, group) {
      items.push(name);
      items.push(...group.map(t => ({ id: t.id, label: t.shortName || t.name })));
    });

    return (
      <DropdownTrigger onSelect={onAdd} items={items}>
        <div className='tag-list__add'>+</div>
      </DropdownTrigger>
    );
  }

  groupTags(tags) {
    const result = {};
    for(let t of tags) {
      const g = t.groupName()
      result[g] = result[g] || [];
      result[g].push(t);
    }

    return result;
  }
}

export default TagList;
