import {connect} from 'react-redux'
import {fetchObjectSubset} from 'app/lib/utils'

class EditAutoReplyPage extends Component {
  state = {}

  componentDidMount() {
    this.getData();
  }

  getData() {
    const {currentUser} = this.props;
    if (!currentUser || this.state.dataLoading)
      return;

    this.setState({dataLoading: true});
    const repo = new repositories.AutoReplyRepo();
    repo.get(this.props.match.params.id)
      .then(autoReply => this.setState({autoReply, dataLoading: false}))
      .catch(data => {
        if (data.status === 403)
          return browserHistory.push('/staff');
        this.setState({dataLoading: false});
      })
  }

  onSave = (updatedAutoReply) => {
    this.setState({autoReply: updatedAutoReply, dataLoading: false});
  }

  render() {
    const autoReply = this.state.autoReply;
    const isLoading = this.state.dataLoading;

    return (
      <layouts.Staff>
        <Card stretch>
          <h2>Авто-ответы</h2>
          <Margin size="20"/>
          {isLoading ? <div>Загрузка...</div> : (
            autoReply ? (
              <staff.AutoReply onSave={this.onSave} autoReply={autoReply}/>
            ) : 'Нет данных для отображения'
          )}
        </Card>
      </layouts.Staff>
    );
  }
}

const mapStateToProps = s => fetchObjectSubset(s, ['currentUser']);
export default connect(mapStateToProps)(EditAutoReplyPage);
