import PropTypes from 'prop-types';

class AccountNote extends Component {
  static propTypes = {
    note: PropTypes.string,
    account_id: PropTypes.number.isRequired,
    onSave: PropTypes.func.isRequired, // triggers when saving was successful
  }

  constructor(props) {
    super(props);
    this.state = { note: props.note || '' }
  }

  render() {
    const {note, saveInProgress} = this.state;
    const {currentUser} = this.props;
    return (
      <div>
        {currentUser.admin && <Textarea small value={note} onChange={v => this.setState({ note: v })} /> || <span>{note}</span>}
        {this.isNoteChanged() && <Button disabled={saveInProgress} onClick={() => this.save()}>Сохранить</Button>}
      </div>
    )
  }

  isNoteChanged() {
    if(!this.props.note) {
      return this.state.note;
    }

    return this.state.note != this.props.note;
  }

  save() {
    this.setState({ saveInProgress: true });
    const repo = new repositories.AccountRepo();
    repo.update({ id: this.props.account_id, note: this.state.note })
        .then(this.props.onSave)
        .catch(data => console.log(data))
  }
}
export default AccountNote;
