import {bemModifiers} from 'app/lib/utils'

class TicketSubject extends Component {
  render() {
    const {ticket, bold, linkParams} = this.props;
    const className = bemModifiers('ticket-subject', [bold && 'bold']);
    const lastUserMessage = ticket.last_user_message && ticket.last_user_message.message;
    return (
      <div className={className}>
        {this.renderOpinion()}
        <Tooltip ticketMessage wrap content={lastUserMessage}>
          <LinkToTicket id={ticket.id} label={ticket.subject} params={linkParams} />
        </Tooltip>
      </div>
    )
  }

  renderOpinion() {
    const {opinion, opinion_text} = this.props.ticket;
    if(!opinion)
      return;

    if(opinion_text) {
      return (
        <Tooltip content={opinion_text}>
          <RatingIcon opinion={opinion} />
        </Tooltip>
      );
    } else {
      return <RatingIcon opinion={opinion} />;
    }
  }
}

export default TicketSubject;
