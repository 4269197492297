import {connect} from 'react-redux'
import {fetchObjectSubset} from 'app/lib/utils'
import {reloadTags} from 'app/store/helpers'

class SubscriptionsPage extends Component {
  state = {}

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    if(this.props.currentUser.id != this.state.subscriberId)
      this.getData();
  }

  getData() {
    reloadTags();

    const {currentUser} = this.props;
    if(!currentUser)
      return;

    const repo = new repositories.SubscriptionRepo();
    this.setState({ dataLoading: true, subscriberId: currentUser.id });
    const promise = repo.getAll(currentUser.id);
    promise.then(subscriptions => this.setState({ subscriptions, dataLoading: false }))
           .catch(data => {
             if(data.status === 403)
               return browserHistory.push('/staff');
             this.setState({ dataLoading: false, subscriberId: null });
             // TODO: show error notice
           });
  }

  render() {
    const subscriptions = this.state.subscriptions || [];

    return (
      <layouts.Staff>
        <Card stretch>
          <h2>Подписки</h2>
          <Margin size="20" />

          <Table cols={['', 'Тип', 'Объект', '']}>
            {subscriptions.map(s => this.renderTableRow(s))}
          </Table>
        </Card>
      </layouts.Staff>
    );
  }

  renderTableRow(subscription) {
    return (
      <tr key={subscription.id}>
        <td>{subscription.via}</td>
        <td>{subscription.kind}</td>
        <td>{this.renderTargetObject(subscription)}</td>
        <td>
          <Button onClick={() => subscription.destroy().then(() => this.getData())}>Удалить</Button>
        </td>
      </tr>
    );
  }

  renderTargetObject(subscription) {
    switch(subscription.kind) {
      case 'tag':      return <TagById id={subscription.target_id} />;
      case 'ticket':   return <LinkToTicket id={subscription.target_id} />;
      default:         return subscription.target_id;
    }
  }
}

const mapStateToProps = s => fetchObjectSubset(s, ['currentUser', 'tags']);
export default connect(mapStateToProps)(SubscriptionsPage);
