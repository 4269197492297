/*
 * Allows you to iterate array over and over.
 * Example:
 *
 *     const ring = new RingList([1, 2, 3], 2);
 *     [ring.next(), ring.next(), ring.next(), ring.next()] // [3, 1, 2, 3]
 */
class RingList {
  constructor(data, startIndex = 0) {
    this.data = data;
    this.index = startIndex;
  }

  next() {
    if(this.index >= this.data.length)
      this.index = 0;

    const result = this.data[this.index];
    this.index = this.index + 1;
    return result;
  }
}

export default RingList;
