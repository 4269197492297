const managerName = (m) => m && m.full_name || 'менеджер';
const rateTypeTranslations = { good: 'положительный', bad: 'отрицательный', neutral: 'нейтральный' }

class TicketEvent extends Component {
  handlers = {
    view: () => 'просмотрен',
    add_tag: () => <span>{this.renderTag()} &nbsp; добавлен</span>,
    delete_tag: () => <span>{this.renderTag()} &nbsp; удален</span>,
    close: () => 'решён',
    system_close: () => 'Закрыт системой',
    took: () => 'взят',
    assign: () => <div><strong>{managerName(this.objectById(this.props.managers))}</strong> назначен(а)</div>,
    execute_script: () => <div>Сценарий <strong>{this.props.event.data.script_name}</strong> применён</div>,
    free: () => <div><strong>{this.props.event.value_id && managerName(this.objectById(this.props.managers))}</strong> снят(а)</div>,
    open: () => 'открыт',
    to_waiting: () => 'переведен в ожидание',
    to_archived: () => 'перенесен в архив',
    autotook: () => 'взят через кнопку',
    rating: () => `оставлен ${rateTypeTranslations[this.props.event.value_string]} отзыв`,
    fork: () => <div>отсоединен в {this.renderLinkToTicket()}</div>,
    forked_from: () => <div>отсоединен от {this.renderLinkToTicket()}</div>,
    joined_by: () => <div>Объединен с {this.renderLinkToTicket()}</div>,
    join: () => <div>Присоединен к {this.renderLinkToTicket()}</div>,
    rename: () => <div> Переименован в &quot;{this.props.event.data.new}&quot;</div>
  }

  render() {
    const event = this.props.event;
    const handler = this.handlers[event.kind];
    const person = event.user || event.manager;

    const handlersColor = {
      view: 'text-gray',
      add_tag: 'text-cyan',
      execute_script: 'text-cyan',
      delete_tag: 'text-yellow',
      close: 'text-cyan',
      system_close: 'text-yellow',
      took: 'text-cyan',
      assign: 'text-cyan',
      free: 'text-yellow',
      open: 'text-yellow',
      to_waiting: 'text-yellow',
      to_archived: 'text-yellow',
      autotook: 'text-cyan',
      rating: 'text-cyan',
      fork: 'text-yellow',
      forked_from: 'text-yellow',
      joined_by: 'text-cyan',
      join: 'text-cyan',
      rename: 'text-yellow'
    }

    return (
      <div className='ticket-event'>
        <div className="ticket-event__content">
          <div className="ticket-event__person">
            {person && <Person person={person} short />}
          </div>
          <div className="ticket-event__descr">
            <span className={handlersColor[event.kind]}>
              {handler ? handler.call(this) : 'что-то произошло'}
            </span>
            {event.message && this.renderMessage()}
            <div className="ticket-event__time"><TimePassedSince time={event.created_at} /> назад</div>
          </div>
        </div>
      </div>
    );
  }

  renderTag() {
    const tag = this.objectById(this.props.tags);
    return tag ? <Tag data={tag} small /> : 'тег';
  }

  renderLinkToTicket() {
    const id = this.props.event.value_id;
    return <Link to={`/staff/${id}`}>#{id}</Link>;
  }

  renderMessage() {
    const event = this.props.event;
    return <Message text={event.message} attachments={event.attachments} />
  }

  objectById(coll) {
    return (coll || []).filter(x => x.id == this.props.event.value_id)[0];
  }
}

export default TicketEvent;
