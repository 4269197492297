import { connect } from 'react-redux'
import { reloadCurrentUser } from 'app/store/helpers'
import NotificationRepo from '../models/repositories/NotificationRepo';
/*
 * <layouts.User />
 */

const notificationRepo = new NotificationRepo

class User extends Component {
  state = { notification: {} }

  componentDidMount() {
    reloadCurrentUser();
    this.getNotificationInfo();
  }

  getNotificationInfo() {
    notificationRepo.current().then((response) => {
      this.setState({ notification: response })
    })
  }

  render() {
    const { children } = this.props
    const links = this.links();
    const tabs = [
      { id: links.not_archived, label: 'Мои обращения' },
      { id: links.waiting, label: 'Ждут моего ответа' },
      { id: links.closed, label: 'Решенные' },
      { id: links.archived, label: 'Архив' },
    ]
    

    return (
      <div className="user-layout">
        <DocumentTitle />
        {Object.keys(this.state.notification).length ? <div className='notification'>{this.state.notification.description}</div> : <></>}
        <layouts.partials.Appbar person={this.props.currentUser} logoUrl="/my" />
        <layouts.partials.SiteContent small>
          <CardHeader tabs={tabs} current={this.currentTab()} nav />
          <Card stretch>
            {children}
          </Card>
        </layouts.partials.SiteContent>
      </div>
    )
  }

  links() {
    return {
      not_archived: '/my/tickets?status=!archived',
      waiting: '/my/tickets?status=waiting',
      closed: '/my/tickets?status=closed',
      archived: '/my/tickets?status=archived',
    };
  }

  currentTab() {
    const links = this.links();

    switch (this.props.statusFilter) {
      case '!archived':
        return links.not_archived;
      case 'waiting':
        return links.waiting;
      case 'closed':
        return links.closed;
      case 'archived':
        return links.archived;
    }

    return null;
  }
}

const mapStateToProps = state => ({ currentUser: state.currentUser });
export default connect(mapStateToProps)(User);
