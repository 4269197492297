import React from 'react'
import StickyBox from "react-sticky-box";

function TwoCols({side, children}) {
  return (
    <div className='two-cols'>
      <div className='two-cols__main'>
        {children}
      </div>
      {side &&
        <StickyBox offsetTop={20} offsetBottom={20}>
          <div className='two-cols__side'>{side}</div>
        </StickyBox>
      }
    </div>
  );
}

export default TwoCols;
