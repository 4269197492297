class ChatGPTModelRepo {
  getAll(params = {}) {
    const promise = new Promise(function(resolve, reject) {
      const mapToEntities = chatGPTModels => chatGPTModels.map(t => new entities.AbstractEntity(t));
      API.chatGPTModels.getAll({
        params,
        success: chatGPTModels => resolve(mapToEntities(chatGPTModels)),
        error: reject
      });
    });

    return promise;
  }

  get(id) {
    const promise = new Promise(function(resolve, reject) {
      API.chatGPTModels.get({
        id,
        success: chatGPTModel => resolve(new entities.AbstractEntity(chatGPTModel)),
        error: reject,
      });
    });

    return promise;
  }

  update(params) {
    const promise = new Promise(function(resolve, reject) {
      API.chatGPTModels.update({
        id: params.id,
        params,
        success: chatGPTModel => resolve(new entities.AbstractEntity(chatGPTModel)),
        error: reject,
      });
    });

    return promise;
  }

  create(params) {
    const promise = new Promise(function(resolve, reject) {
      API.chatGPTModels.create({
        params,
        success: chatGPTModel => resolve(new entities.AbstractEntity(chatGPTModel)),
        error: reject,
      });
    });

    return promise;
  }

  getTicketIds(params) {
    const promise = new Promise(function(resolve, reject) {
      API.chatGPTModels.getTicketIds({
        params,
        success: ids => resolve(ids),
        error: reject,
      });
    });

    return promise;
  }

  destroy(id) {
    return new Promise(function(resolve, reject) {
      API.chatGPTModels.delete({
        id,
        success: resolve,
        error: reject,
      });
    });
  }

  runFineTune(id) {
    const promise = new Promise(function(resolve, reject) {
      API.chatGPTModels.runFineTune({
        id,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }
}

export default ChatGPTModelRepo;
