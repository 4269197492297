class UserTicketStatus extends Component {
  render() {
    return (
      <small className={this.textStyle()} style={{whiteSpace: 'nowrap'}}>
        {this.statusText()} <TimePassedSince time={this.statusChangeTime()} />
      </small>
    );
  }

  statusText() {
    const translations = {
      complete: 'решён',
      archived: 'в архиве',
      in_progress: 'в обработке',
      waiting_for_user: 'ждёт вашего ответа',
    }

    return translations[this.props.ticket.status_for_user];
  }

  textStyle() {
    const styles = {
      complete: 'text-cyan',
      archived: 'text-gray',
      in_progress: 'text-gray',
      waiting_for_user: 'text-yellow',
    }
    return styles[this.props.ticket.status_for_user];
  }

  statusChangeTime() {
    const {ticket} = this.props;

    switch(ticket.status_for_user) {
      case 'in_progress':
        return ticket.user_attended_at;
      case 'waiting_for_user':
        return ticket.manager_attended_at;
    }

    return ticket.status_changed_at
  }
}

export default UserTicketStatus;
