import { connect } from 'react-redux'

class StaffMainPage extends Component {
  componentDidMount() {
    const status = this.props.ticketStatusFilter;
    browserHistory.push(`/staff/tickets?status=${status}&order=priority&solvable=me`)
  }

  render() {
    return <PageTransition />;
  }
}

const mapStateToProps = s => ({ ticketStatusFilter: s.userSettings.ticketStatusFilter || 'open' });
export default connect(mapStateToProps)(StaffMainPage);
