import PropTypes from 'prop-types';
import NumericTextInput from "../NumericTextInput";
import SelectInput from "../SelectInput";

class AutoReply extends Component {
  static propTypes = {
    autoReply: PropTypes.object.isRequired,
    onSave: PropTypes.func,
  }

  constructor(props) {
    super(props);
    const {autoReply} = props;

    this.state = {
      content: autoReply.content,
      title: autoReply.title,
      delay_in_days: autoReply.delay_in_days,
      active: autoReply.active,
      tags: [],
      selectedTagId: autoReply.tag_id || null,
      errors: {},
      showSuccess: false,
      showError: false
    };
  }

  componentDidMount() {
    this.fetchTags();
  }

  fetchTags() {
    API.tags.getAll({
      params: {},
      success: (response) => {
        const tags = response.map(tag => ({
          id: tag.id,
          name: tag.name
        }));
        this.setState({tags: tags});
      },
      error: (error) => {
        console.error('Ошибка загрузки тегов:', error);
      }
    });
  }


  handleTagClick = () => {
    this.setState({selectedTagId: null});
  };

  render() {
    const {content, title, delay_in_days, active, errors, showSuccess, showError} = this.state;

    const handleTagChange = (e) => {
      this.setState({selectedTagId: e.target.value});
    };

    return (
      <div className='staff-article'>
        {showSuccess && <Notice type="done">Успешно</Notice>}
        {showError && <Notice type="error">Произошла ошибка</Notice>}

        <LabeledInput label="Название" error={errors.title && errors.title.join('; ')}>
          <TextInput value={title} onChange={v => this.setState({title: v})}/>
        </LabeledInput>

        <Margin size='16' />

        <LabeledInput label="Сообщение" error={errors.content && errors.content.join('; ')}>
          <Textarea value={content} onChange={v => this.setState({content: v})}/>
        </LabeledInput>

        <Margin size='16' />

        <LabeledInput label="Тэг">
          <SelectInput
            placeholder="Выберите тег"
            tags={this.state.tags}
            value={this.state.selectedTagId || ''}
            onChange={handleTagChange}
          />
        </LabeledInput>

        <Margin size='16' />

        <LabeledInput label="Задержка в днях" error={errors.delay_in_days && errors.delay_in_days.join('; ')}>
          <NumericTextInput value={delay_in_days} onChange={v => this.setState({delay_in_days: v})}/>
        </LabeledInput>

        <Margin size='16' />
        <Checkbox label='Активен' checked={active} onChange={v => this.setState({active: v})}/>
        <Margin size='16' />
        <Button onClick={() => this.save()}>Сохранить</Button>
      </div>
    )
  }

  isChanged() {
    const {autoReply} = this.props;
    return this.state.content != autoReply.content ||
      this.state.title != autoReply.title ||
      this.state.delay_in_days != autoReply.delay_in_days ||
      this.state.active != autoReply.active;
  }

  save() {
    const params = {
      title: this.state.title,
      content: this.state.content,
      delay_in_days: this.state.delay_in_days,
      tag_id: this.state.selectedTagId,
      active: this.state.active
    };
    const id = this.props.autoReply.id;
    const repo = new repositories.AutoReplyRepo();
    if (id) {
      params.id = id
      repo.update(params)
        .then(data => {
          this.props.onSave && this.props.onSave(data);
          this.setState({ showSuccess: true, showError: false, errors: {} });
        })
        .catch(data => {
          data.responseJSON.errors
            && this.setState({ errors: data.responseJSON.errors, showError: true, showSuccess: false })
          console.log(data)
        })
    } else {
      repo.create(params)
        .then(data => {
          this.props.autoReply.id = data.id; // HACK: just in case #save will be fired multiple times
          this.props.onSave && this.props.onSave(data);
          this.setState({ showSuccess: true, showError: false, errors: {} });
        })
        .catch(data => {
          data.responseJSON.errors
            && this.setState({ errors: data.responseJSON.errors, showError: true, showSuccess: false })
          console.log(data)
        })
    }
  }
}

export default AutoReply;
