function IconText(props) {
  const {icon, children} = props;
  const {textClass, iconClass} = props;
  const {size, margin, right} = props;

  const iconEl = <Icon className={`icon-text__icon ${iconClass}`} name={icon} size={size} />;
  const style = { [`margin${right ? 'Right' : 'Left'}`]: margin };
  return (
    <div className='icon-text'>
      {!right && iconEl}
      <div className={`icon-text__text ${textClass}`} style={style}>
        {children}
      </div>
      {right && iconEl}
    </div>
  )
}

export default IconText;
