import React from 'react'
import {bemModifiers} from 'app/lib/utils'

function Tab({data, current, onClick}) {
  const {id, label} = data;
  const className = bemModifiers('tabs__tab', [current == id && 'current']);
  return <div className={className} onClick={() => onClick(id)}>{label}</div>;
}

function Tabs({tabs, current, onClick}) {
  return (
    <div className='tabs'>
      {tabs.map(t =>  <Tab key={t.id} data={t} current={current} onClick={onClick} />)}
    </div>
  )
}

export default Tabs;
