import {findById} from 'app/lib/utils'
import {connect} from 'react-redux'
import Select from 'react-select'

/*
 * Works with ids but shows them from redux store
 */
class ManagerMultiselect extends Component {
  render() {
    const {activeManagers} = this.props;
    return (
      <div className='manager-multiselect'>
        <div>
          <Button small onClick={() => this.selectAll()}>Выделить всех</Button>
          &nbsp;
          <Button small onClick={() => this.deselectAll()}>Отменить выделение</Button>
        </div>
        <Margin size='8' />
        {this.renderSelect()}
      </div>
    )
  }

  renderSelect() {
    const {onChange, activeManagers} = this.props;
    const selected = this.props.selected || [];
    const selectedWithId = id => selected.concat(id);
    const selectedWithoutId = id => selected.filter(e => id != e);
    const options = activeManagers.map(m => ({ label: m.full_name, value: m.id }));

    return <Select closeOnSelect={undefined}
                   multi
                   onChange={opts => onChange(opts.map(e => e.value))}
                   options={options}
                   placeholder="Менеджеры"
                   removeSelected={undefined}
                   value={selected} />
  }

  selectAll() {
    const {onChange, activeManagers} = this.props;
    onChange(activeManagers.map(m => m.id));
  }

  deselectAll() {
    this.props.onChange([]);
  }
}

const mapStateToProps = s => ({ activeManagers: s.activeManagers });
export default connect(mapStateToProps)(ManagerMultiselect);
