import TreeNode from './tree-node'

class Tree extends Component {
  render() {
    const {items, onChange, value, itemComponentRender} = this.props

    const item = { id: null, name: 'Корневая категория', sorting_weight: 0 }

    const itemsIndex = items.reduce((index, item) => {
      const parent_id = item.parent_id || 'null'

      index[parent_id] ||= []
      index[parent_id].push(item)
      return index
    }, {})

    return (
      <div>
        <TreeNode item={item} items={itemsIndex} level={0} onChange={onChange} value={value} itemComponentRender={itemComponentRender} />
      </div>
    )
  }
}
export default Tree;
