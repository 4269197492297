import React from 'react'
import {bemModifiers} from 'app/lib/utils'

const hr = index => <div className='dropdown__hr' key={`hr_${index}`} />;
function dropdownItem(item, renderer, onSelect) {
  return (
    <div className='dropdown__item'
         key={item.key || item.id}
         onClick={() => onSelect(item.id)}>
      {renderer(item)}
    </div>
  );
}

function resolveItem(item, index, itemRenderer, onSelect) {
  if(item == 'hr') {
    return hr(index);
  } else if(typeof item == 'string') {
    return <div key={`header_${item}`} className='dropdown__header'>{item}</div>;
  } else {
    return dropdownItem(item, itemRenderer, onSelect);
  }
}

function DropdownOverlay({trigger}) {
  return (
    <div className = 'dropdown__overlay'
         onClick = {() => trigger.toggleDropdown()} />
    )
}

/*
 * Every item should be object with id and label or string.
 * Strings will be displayed as headers except when it's `'hr'`.
 * If string == `'hr'` it will be displayed as horizontal line.
 * Optional parameter `itemRenderer` may be rendering function
 * that will render item (it's inner div, to be precise)
 */
function Dropdown({items, onSelect, itemRenderer, right, trigger}) {
  if(!itemRenderer) {
    itemRenderer = item => item.label;
  }

  items = items.map((e, i) => resolveItem(e, i, itemRenderer, onSelect));
  const blockClass = bemModifiers('dropdown', [right ? 'right' : 'left']);

  return (
    <div>
      {/* для сворачивания при клике в произвольном месте экрана */}
      {trigger && <DropdownOverlay trigger={trigger} />}
      <div className={blockClass}>
        {items}
      </div>
    </div>
  )
}

export default Dropdown;
