import StatisticExecutor from 'app/pages/staff/statistics/partials/StatisticExecutor'
import StatisticView from 'app/pages/staff/statistics/partials/StatisticView'

class StatisticsPage extends Component {
  state = {}

  render() {
    const {statistic} = this.state;
    return (
      <layouts.staff.Statistics>
        <h1>Статистика</h1>
        <Margin size="20" />
        <StatisticExecutor onComplete={st => this.setState({ statistic: st })} />
        <Margin size="40" />
        {statistic && <StatisticView key={JSON.stringify(statistic)} statistic={statistic} />}
      </layouts.staff.Statistics>
    )
  }
}

export default StatisticsPage;
