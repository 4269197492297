import { connect } from 'react-redux'
import { setMessageFormCache } from 'app/store/helpers'
import Dropzone from 'react-dropzone'
import { fileErrorMessages } from '../lib/messages';

class MessageForm extends Component {
  constructor(props) {
    super(props);
    const event = props.event || {};
    this.attachmentList = React.createRef();
    this.textarea = React.createRef();
    this.signatureSeparator = '\n\n--\n';
    this.state = {
      message: event.message || '',
      files: event.attachments || [],
      filesErrors: [],
      status: 'waiting',
      uploadingAttachment: false
    };
  }

  reset() {
    this.setState({ message: '', files: [], status: 'waiting' });
    this.cacheText();
  }

  focus() {
    // this.refs.textarea.focus();
    this.textarea.current.focus();
  }

  getText() {
    let message = this.state.message;

    const indexOfSignatureSeparator = message.lastIndexOf(this.signatureSeparator);

    if (indexOfSignatureSeparator !== -1) {
      message = message.substr(0, indexOfSignatureSeparator);
    }

    return message;
  }

  setText(txt) {
    const isManager = this.props.currentUser.kind == 'manager';

    const { currentSignature } = this.getAttributes(isManager);

    this.setState({ message: txt + currentSignature });
  }

  componentDidUpdate() {
    const { formId } = this.props;
    if (formId && formId != this.state.lastAppliedCacheId) {
      this.setTextFromCache();
    }
  }

  componentDidMount() {
    this.cachingInterval = setInterval(() => this.cacheText(), 3000);

    this.textarea.current.refs.element.addEventListener('paste', this.handleFilePaste)
  }

  componentWillUnmount() {
    clearInterval(this.cachingInterval);
    this.textarea.current.refs.element.removeEventListener('paste', this.handleFilePaste);
  }

  handleFilePaste = (event) => {
    const data = event?.clipboardData

    if (data.files.length > 0) {
      Array.from(data.files).forEach((file) => this.attachmentList.current.uploadFile(file))
    }
  }

  setTextFromCache() {
    const { messageFormCache, formId } = this.props;
    const cacheValue = messageFormCache[formId];
    this.setState({ lastAppliedCacheId: formId, message: cacheValue || '' });
  }

  setUploading = (value) => {
    if (value != this.state.uploadingAttachment) {
      this.setState({ uploadingAttachment: value })
    }
  }

  getAttributes(isManager) {
    const { visible, event, currentUser } = this.props;
    const { message } = this.state;

    let [currentSignature, currentMessage, newMessage] = ['', message, message];

    if (isManager) {
      const indexOfSignatureSeparator = message.lastIndexOf(this.signatureSeparator);

      const signatureSeparatorPresent = indexOfSignatureSeparator !== -1;
      const managerHasSignature = currentUser.signature !== '';
      const isMessage = visible === 'message';

      if (event) {
        if (signatureSeparatorPresent) {
          currentSignature = message.substr(indexOfSignatureSeparator);
        }
      } else if (managerHasSignature && isMessage) {
        currentSignature = this.signatureSeparator + currentUser.signature;
      }

      if (signatureSeparatorPresent) {
        currentMessage = message.substr(0, indexOfSignatureSeparator);
      } else {
        newMessage += currentSignature;
      }
    }

    return { currentSignature, currentMessage, newMessage };
  }

  renderErrors() {
    const { filesErrors } = this.state

    return (
      <Modal onHide={() => this.setState({ filesErrors: [] })}>
        <h2>Не удается загрузить следующие файлы</h2>
        {
          filesErrors.map((error) => (
            <Margin size='20 0 0 0' key={error}>
              <Notice type="error">{error}</Notice>
            </Margin>
          ))
        }
      </Modal>
    )
  }

  render() {
    const { label, visible, error, onSubmit, currentUser, disabledSubmit, withStatusSelect, color, additionalClasses = null} = this.props;
    const { files, filesErrors, uploadingAttachment } = this.state;

    const status = withStatusSelect ? this.state.status : null;

    const isManager = currentUser.kind == 'manager';

    const { currentSignature, currentMessage, newMessage } = this.getAttributes(isManager);

    const className = ['message-form', additionalClasses].filter(Boolean).join(' ')

    const submitForm = () => onSubmit({ message: newMessage, fileIds: files.map(f => f.id), status: status });

    // TODO: maxSize лучше брать с бека (с поправкой на заголовки и паддинг), client_max_body_size там 30M
    return (
      <>
        <Dropzone
          onDrop={(a, r, e) => this.onDrop(a, r, e)}
          maxFiles={10}
          minSize={1} maxSize={29000000}
          noClick={true} noKeyboard={true}
        >
          {({ getRootProps, isDragActive, isDragReject }) => (
            <div style={{ display: visible ? '' : 'none' }}>
              <div className={className} {...getRootProps({ style: { outline: 'none' } })}>
                <LabeledInput label={label} error={error}>
                  <Textarea ref={this.textarea} onChange={v => this.setState({ message: v + currentSignature })}
                    value={currentMessage}
                    error={error}
                    color={
                      isDragActive && 'green' ||
                      isDragReject && 'red' ||
                      color
                    } />
                </LabeledInput>
                {isDragActive && <Notice type="info">Отпустите для загрузки файла</Notice>}
                {isDragReject && <Notice type="error">Файл не подходит</Notice>}

                {isManager &&  
                  <div className="my-2">
                    <staff.AnswerTemplateSelect className='mt-2' onSelect={t => this.applyAnswerTemplate(t)} />
                  </div>
                }

                <div className="message-form__controls">
                  <AttachmentList ref={this.attachmentList} files={files}
                    onDelete={f => this.deleteFile(f)}
                    onUpload={f => this.addFile(f)}
                    setUploading={this.setUploading} />
                  <div>
                    {withStatusSelect && <StatusSelect current={status} onChange={s => this.setState({ status: s })} />}
                    <Margin size='10' inline />
                    <Button onClick={submitForm} disabled={disabledSubmit || uploadingAttachment}>ОТПРАВИТЬ</Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
        {filesErrors.length > 0 && this.renderErrors()}
      </>
    );
  }

  applyAnswerTemplate(template) {
    const isManager = this.props.currentUser.kind == 'manager';

    const { currentSignature, currentMessage, newMessage } = this.getAttributes(isManager);

    this.setState({ message: currentMessage + template.content + currentSignature });
  }

  onDrop(acceptedFiles, fileRejections) {
    acceptedFiles.forEach(
      file => this.attachmentList.current.uploadFile(file)
    )
  
    if (fileRejections && fileRejections.length > 0) {
      const filesErrors = fileRejections.map(
        r => `${r.file.name}: ${r.errors.map(f => fileErrorMessages[f.code] || f.message).join(', ')}`
      )

      this.setState({ filesErrors })
    }
    console.log("fileRejections", fileRejections)
  }

  addFile(file) {
    this.setState(prevState => ({ files: prevState.files.concat(file) }))
  }

  deleteFile(file) {
    this.setState(prevState => ({ files: prevState.files.filter(f => f != file) }))
  }

  cacheText() {
    const { formId } = this.props;
    if (!formId)
      return;
    setMessageFormCache(formId, this.state.message);
  }
}

const mapStateToProps = s => ({ currentUser: s.currentUser, messageFormCache: s.messageFormCache });
export default connect(mapStateToProps, null, null, { forwardRef: true })(MessageForm);
