import cookie from 'cookie'

/*
 *
 * Use promise to unify repositories
 * (in case we won't use JQuery in future or some cases)
 *
 */
class ScriptRepo {
  getAll(params = {}) {
    if (!params.group_id) {
      params.group_id = cookie.parse(document.cookie).g
    }
    const promise = new Promise(function(resolve, reject) {
      const mapToEntities = scripts => scripts.map(t => new entities.AbstractEntity(t));
      API.scripts.getAll({
        params,
        success: scripts => resolve(mapToEntities(scripts)),
        error: reject
      });
    });

    return promise;
  }
}

export default ScriptRepo;
