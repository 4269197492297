
class CurrentUserRepo {
  get() {
    const entity = data => data.kind == 'user' ? new entities.User(data) : new entities.Manager(data);

    const promise = new Promise(function(resolve, reject) {
      API.me.get({
        success: data => resolve(entity(data)),
        error: reject,
      });
    });

    return promise;
  }

  updateSettings(settings) {
    const promise = new Promise(function(resolve, reject) {
      API.me.updateSettings({
        params: settings,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }
}

export default CurrentUserRepo;
