import {linkToTicket} from 'app/lib/utils';

class TicketGroupAnswer extends Component {
  state = { success: [], error: [] }

  componentDidMount() {
    this.setState({ success: [], error: [], disableSubmit: true });
  }
  render() {
    const {onHide, ticket} = this.props;
    const {errorMessage} = this.state;

    return (
      <div className='ticket-fork'>
        <Modal onHide={onHide}>
          <h2>Групповой ответ</h2>
          {errorMessage && <Notice type="error">{errorMessage}</Notice>}
          <Margin size="20" />
          {this.renderTicketList()}
          {/* <Button onClick={() => alert('TODO')}>Ответ</Button> */}
          <Margin size="20" />
          {this.renderMessageForm()}
          {errorMessage && <Notice type="error">{errorMessage}</Notice>}
        </Modal>
      </div>
    );
  }

  renderTicketList() {
    return (
      <div>
        <h3>Тикеты</h3>
        {this.props.tickets.map(t => <div key={t.id}>{this.renderTicketLabel(t)}</div>)}
      </div>
    )
  }

  renderTicketLabel(t) {
    const {success, error} = this.state;
    const successful = success.indexOf(t.id) != -1;
    const hasError = error.indexOf(t.id) != -1;
    let status = null;
    if(successful)
      status = <Icon name='done' green />;
    if(hasError)
      status = <Icon name='error' red />;

    return <span>{status}<LinkToTicket id={t.id} /> - {t.subject}</span>;
  }

  renderMessageForm() {
    const tickets = this.props.tickets;
    const that = this;
    const onSubmit = function({message, status, fileIds}) {
      that.setState({ disabledSubmit: true });
      const onSuccess = ticket => that.setState(prevState => ({ success: prevState.success.concat(ticket.id) }));
      const onError = function(ticket, data) {
        console.log("Error:", ticket, data);
        that.setState(prevState => ({ error: prevState.error.concat(ticket.id) }))
      }

      for(let t of tickets) {
        API.tickets.addMessage({ id: t.id,
                                 params: { message, status, file_ids: fileIds, version: t.version },
                                 success: data => onSuccess(t),
                                 error: data => onError(t, data) });
      }
    }

    return <MessageForm label='Текст сообщения'
                               onSubmit={onSubmit}
                               visible
                               withStatusSelect
                               disabledSubmit={this.state.disabledSubmit} />;
  }

  showError(message) {
    this.setState({ errorMessage: message });
  }

  hideError() {
    this.setState({ errorMessage: null });
  }
}

export default TicketGroupAnswer;
