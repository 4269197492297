import {connect} from 'react-redux'
import {reloadManagers, reloadTags} from 'app/store/helpers'
import {boolValue} from 'app/lib/utils'

class TagListPage extends Component {
  state = { tags: [], subscriptions: [], openTicketsCount: {} }

  componentDidMount() {
    reloadManagers();
    reloadTags();
    API.tags.openTicketsCount({
      success: openTicketsCount => this.setState({ openTicketsCount }),
      error: data => console.log(data),
    })
    this.getSubscriptions();
  }

  componentDidUpdate() {
    const {subscriptionsLoaded, subscriptionsLoading} = this.state;
    if(!subscriptionsLoaded && !subscriptionsLoading)
      this.getSubscriptions();
  }

  getSubscriptions() {
    const {currentUser} = this.props;
    if(!currentUser.id)
      return;

    const repo = new repositories.SubscriptionRepo();
    this.setState({ subscriptionsLoading: true });
    const promise = repo.getAll(currentUser.id, { kind: 'tag' });
    promise.then(subscriptions => this.setState({ subscriptions, subscriptionsLoaded: true, subscriptionsLoading: false }));
  }

  render() {
    const {tags} = this.props;

    return (
      <layouts.Staff>
        <Card stretch>
          <h2>Теги</h2>
          <Margin size="20" />

          <Button size="xs" to="/staff/tags/new">Создать</Button>
          <Margin size="20" />

          <Table cols={['', 'Время ответа (первый, последующие)', 'Тикетов', 'Публичный', 'Решают', '']}>
            {
              tags.map(t => this.renderRow(t))
            }
          </Table>
        </Card>
      </layouts.Staff>
    )
  }

  renderRow(tag) {
    const {openTicketsCount} = this.state;
    /* eslint-disable react/jsx-key */
    const items = [
      <Tag data={tag} onClick={() => browserHistory.push(`/staff/tickets?tags=${tag.id}`)} />,
      tag.first_response_time && <div>{tag.first_response_time} ч.<br />{tag.common_response_time} ч.</div>,
      <Link to={`/staff/tickets?status=open&tags=${tag.id}`}>{openTicketsCount[tag.id] || 0}</Link>,
      boolValue(tag.public),
      this.countTagAssignedManagers(tag),
      <Spaced h={10} w={10}>
        {this.props.currentUser.admin && <Button size="xs" color="blue" to={"/staff/tags/" + tag.id}>Редактировать</Button>}
        {this.props.currentUser.admin && <Button size="xs" color="red" onClick={() => this.deleteTag(tag)}>Удалить</Button>}
        {this.renderSubscriptionMenu(tag)}
      </Spaced>,
    ];
    /* eslint-enable react/jsx-key */
    return (
      <tr key={tag.id}>{items.map((e, i) => <td key={i}>{e}</td>)}</tr>
    );
  }

  renderSubscriptionMenu(tag) {
    const {subscriptions} = this.state;
    const emailSubscription = subscriptions.find(s => s.target_id == tag.id && s.via == 'email');
    const tgSubscription = subscriptions.find(s => s.target_id == tag.id && s.via == 'telegram');

    const unsubscribe = via => {
      const sub = subscriptions.find(s => s.target_id == tag.id && s.via == via);
      sub && sub.destroy().then(() => this.getSubscriptions());
    }

    const subscribe = via => {
      const repo = new repositories.SubscriptionRepo();
      repo.create(this.props.currentUser.id, { via, kind: 'tag', target_id: tag.id }).then(() => this.getSubscriptions());
    }

    return <SubscriptionMenu email={!!emailSubscription}
                                    telegram={!!tgSubscription}
                                    subscribe={subscribe}
                                    unsubscribe={unsubscribe} />;
  }

  deleteTag(tag) {
    if (!confirm(`Вы точно хотите удалить ${tag.short_name}?`)) {
      return;
    }

    API.tags.delete({ id: tag.id,
                      success: () => reloadTags(),
                      error: data => console.log(data) });
  }

  countTagAssignedManagers(tag) {
    const {managers} = this.props;
    const hasSolvingAssignment = m => m.manager_assignments.find(a => a.tag && a.tag.id == tag.id && a.kind == 'solving');
    return managers.filter(hasSolvingAssignment).length;
  }
}

const mapStateToProps = s => ({ tags: s.tags, managers: s.managers, currentUser: s.currentUser });
export default connect(mapStateToProps)(TagListPage);
