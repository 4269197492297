import {connect} from 'react-redux'
import {fetchObjectSubset} from 'app/lib/utils'
import {reloadTags} from 'app/store/helpers'
import scrollToTop from 'app/lib/scrollToTop'

class EditPartnerPage extends Component {
  state = {}

  componentDidMount() {
    this.getData();
  }

  getData() {
    const {currentUser} = this.props;
    if(!currentUser || this.state.dataLoading)
      return;

    this.setState({ dataLoading: true });
    const repo = new repositories.PartnerRepo();
    repo.get(this.props.match.params.id)
        .then(partner => this.setPartner(partner))
        .then(() => this.setState({ dataLoading: false }))
        .catch(data => {
          if(data.status === 403)
            return browserHistory.push('/staff');
          this.setState({ dataLoading: false });
        })
  }

  render() {
    const {partner, formData, showError, showSuccess} = this.state;
    if(!partner || !formData) {
      return <PageTransition />
    }

    const openNewTab = url => window.open(url, '_blank');

    return (
      <layouts.Staff>
        <Card stretch>
          <h2>{partner.title}</h2>
          <div>
            <TagList tags={partner.tags} onSelect={t => openNewTab(`/staff/tags/${t.id}`)} />
          </div>
          <Margin size="10" />
          <div>
            {partner.managers.map(m => <Person key={m.id} person={m} onClick={() => openNewTab(`/staff/managers/${m.id}`)}/>)}
          </div>
          <Margin size="20" />

          {showSuccess && <Notice type="done">Успешно</Notice>}
          {showError && <Notice type="error">Произошла ошибка</Notice>}

          <LabeledInput label="Название">
            <TextInput value={formData.title} onChange={v => this.setFormData({ title: v })} />
          </LabeledInput>


          <LabeledInput label="Изображение (URL)">
            <TextInput value={formData.image_url} onChange={v => this.setFormData({ image_url: v })} />
          </LabeledInput>

          <LabeledInput label="Страница (URL)">
            <TextInput value={formData.external_page_url} onChange={v => this.setFormData({ external_page_url: v })} />
          </LabeledInput>

          <LabeledInput label="Город">
            <TextInput value={formData.city} onChange={v => this.setFormData({ city: v })} />
          </LabeledInput>

          <Margin size='10' />

          <div>
            <Spaced w='5'>
              <Checkbox label="Дизайн" checked={formData.design}
                        onChange={v => this.setFormData({ design: v })} />


              <Checkbox label="Интеграция" checked={formData.integration}
                        onChange={v => this.setFormData({ integration: v })} />


              <Checkbox label="Продвижение" checked={formData.seo}
                        onChange={v => this.setFormData({ seo: v })} />
            </Spaced>
          </div>

          <br />
          <Button onClick={() => this.onSubmit()}>СОХРАНИТЬ</Button>
        </Card>
      </layouts.Staff>
    );
  }

  setPartner(partner) {
    this.setState({ partner });
    this.setFormData(partner);
  }

  setFormData(data) {
    const allowedFields = ['title', 'image_url', 'external_page_url', 'city',
                           'design', 'integration', 'seo'];
    data = fetchObjectSubset(data, allowedFields);
    this.setState({ formData: Object.assign(this.state.formData || {}, data) });
  }

  onSubmit() {
    const repo = new repositories.PartnerRepo();
    const params = Object.assign({ id: this.state.partner.id }, this.state.formData);
    repo.update(params)
        .then(partner => this.onUpdateSuccess(partner),
              error => this.onUpdateError(error));

    this.setState({ showSuccess: false, showError: false });
  }


  onUpdateSuccess(partner) {
    this.setPartner(partner);
    this.setState({ showSuccess: true });
    scrollToTop();
  }

  onUpdateError(error) {
    console.log(error)
    this.setState({ showError: true });
    scrollToTop();
  }
}

const mapStateToProps = s => fetchObjectSubset(s, ['currentUser']);
export default connect(mapStateToProps)(EditPartnerPage);
