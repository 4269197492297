import {bemModifiers, isArray} from 'app/lib/utils'

function Tab({data, nav, current, onTabChange}) {
  const {id, label} = data;
  const className = bemModifiers('card-header__tab', [current == id && 'current']);
  if(nav) {
    return <Link className={className} to={id}>{label}</Link>;
  } else {
    return <div className={className} onClick={() => onTabChange(id)}>{label}</div>;
  }
}

function DropdownTab({label, items, nav, onTabChange}) {
  let onSelect;
  if(nav) {
    onSelect = path => browserHistory.push(path);
  } else {
    onSelect = id => onTabChange(id);
  }

  return (
    <DropdownTrigger noUnderline items={items} onSelect={onSelect}>
      <div className='card-header__tab'>{label || '...'}</div>
    </DropdownTrigger>
  );
}

function CardHeader(props) {
  const {current, onTabChange, nav, addon} = props;
  const tabs = props.tabs.map(t => {
    if(t.type == 'dropdown') {
      return <DropdownTab key='whatever' label={t.label} items={t.items} nav={nav} onTabChange={onTabChange} />;
    } else {
      return <Tab key={t.id} data={t} nav={nav} current={current} onTabChange={onTabChange} />
    }
  });

  return (
    <div className='card-header'>
      <div className='card-header__tabs'>
        {tabs}
      </div>

      <div className='card-header__addon'>
        {addon}
      </div>
    </div>
  )
}


export default CardHeader;
