class EmailRepo {
  get(id) {
    const promise = new Promise(function(resolve, reject) {
      API.emails.get({
        id,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }

  getAll(params) {
    const promise = new Promise(function(resolve, reject) {
      API.emails.getAll({
        params,
        success:resolve,
        error: reject,
      });
    });

    return promise;
  }

  toggleSpam(id, params) {
    const promise = new Promise(function(resolve, reject) {
      API.emails.toggleSpam({
        id,
        params,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }
}

export default EmailRepo;
