import ChatGPTModelForm from './partials/ChatGPTModelForm'

const repo = new repositories.ChatGPTModelRepo();

class ChatGPTModelNew extends Component {
  state = {
    model: {
      name: '',
    },
    errors: null,
    processing: false,
  }

  onSubmit = () => {
    this.setState({ processing: true, errors: null });

    repo.create(this.state.model).then(
      model => {
        this.setState({ processing: false });
        browserHistory.push(`/staff/chat_gpt_models/${model.id}`);
      },
      data => this.setState({ errors: this.parsedErrors(data), processing: false }),
    );
  }

  onChange = (name, value) => {
    this.setState({
      model: {
        ...this.state.model,
        [name]: value,
      },
    });
  };

  parsedErrors = ({responseJSON}) => responseJSON && responseJSON.errors || ["Произошла ошибка"];

  render() {
    const { model, errors, processing } = this.state;

    return (
      <layouts.Staff>
        <Card stretch>
          <h2>Создание модели Chat GPT</h2>
          <Margin size="10" />
          {errors && <ErrorNotice errors={errors} />}
          <Margin size="20" />
          <ChatGPTModelForm
            model={model}
            processing={processing}
            onSubmit={this.onSubmit}
            onChange={this.onChange}
          />
          <Margin size="10" />
        </Card>
      </layouts.Staff>
    )
  }
}

export default ChatGPTModelNew;
