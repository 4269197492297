import { connect } from 'react-redux'
import { reloadCurrentUser } from 'app/store/helpers'
import { findById } from 'app/lib/utils'
import qs from 'query-string'
import CategoryRepo from "../../models/repositories/CategoryRepo";
import Select from "react-select";
import DocumentationArticlesRepo from '../../models/repositories/DocumentationArticlesRepo';

class NewTicketPage extends Component {
  constructor(props) {
    super(props);
    this.avaliableGroups = {
      1: 'insales',
      2: 'ekam'
    };

    const partnerIdParam = qs.parse(this.props.location.search).partner;
    const groupId = parseInt(qs.parse(this.props.location.search)?.g?.toString()) || 1;
    this.state = {
      errors: {},
      articles: [],
      selectedPartnerId: partnerIdParam,
      groupId: groupId,
      categories: [],
      categoriesOnLevel: {
        0: []
      },
      selectedCategoryOnLevel: {
        0: null
      },
      hints: [],
      hintsTimer: null
    };
  }

  componentDidMount() {
    reloadCurrentUser()
      .then(() => this.getData())
      .catch((data) => data.status === 403 || console.log(data));
  }

  getData() {
    this.getArticles();
    this.getTags();
    this.getPartners();
    this.getCategories();
  }

  getCategories() {
    const repo = new CategoryRepo();
    repo.getAll({ public_only: true }).then(
      (categories) => {
        const preparedCategories = categories.map((c) => ({
          value: c.id,
          label: c.name,
          tagId: c.public_tag_id,
          parentId: c.parent_id,
          sortingWeight: c.sorting_weight
        }));
        this.setState({ categories: preparedCategories.sort((c1, c2) => c1.sortingWeight - c2.sortingWeight) });
      },
      (data) => console.log(data)
    );
  }

  getTags() {
    const repo = new repositories.TagRepo();
    repo.getAll({ group_id: this.state.groupId }).then(
      tags => {
        this.setState({ tags })
        if (qs.parse(this.props.location.search).tag_id) {
          this.selectTag(qs.parse(this.props.location.search).tag_id)
        }
      }
    )
  }

  getArticles() {
    const groupId = this.state.groupId;
    const repo = new repositories.ArticleRepo();
    const groupFaq = this.checkFreeInsalesGroup() ? { group_handle: 'insales-free' } : { group_id: groupId };

    repo.getAll({ group_id: groupId }).then(articles => this.setState({ articles }))
    repo.faq(groupFaq)
        .then(faq => this.setState({ faq }))
        .catch(data => data.status === 403 || console.log(data))
  }

  getPartners() {
    const checkIfPartnerExists = () => {
      if(this.state.selectedPartnerId && !this.selectedPartner()) {
        this.setState({ disableForm: true, errors: { base: ['Партнер не существует'] } });
      }
    };

    const repo = new repositories.PartnerRepo();
    repo.getAll()
        .then(partners => this.setState({ partners }))
        .then(checkIfPartnerExists)
        .catch(data => console.log(data));
  }

  checkFreeInsalesGroup() {
    const isInsales =  this.getCurrentGroup()?.handle === 'insales' || this.state.groupId === "1";
    return isInsales && this.props.currentUser.is_free_plan;
}

  getCurrentGroup() {
    const {currentUser} = this.props;
    if (!currentUser.id) return;
    return currentUser.allowed_groups.find((group) => group.id == this.state.groupId)
  }

  changeTab(link) {
    browserHistory.push(link);
    this.setState({ selectedTagId: null, categoriesOnLevel: {}, selectedCategoryOnLevel:{} });
  }

  getTabsForCurrentGroup(currentGroup) {
    let tabs = [];
    const currentTabRelatedToInsales = (group) => group?.handle === "insales";

    const supportTab = {
      id: "support",
      label: (
        <div className='tabs__item'>
          <div className='tabs__title'>Новый тикет</div>
          <div className='tabs__caption'>Обращение в поддержку {currentGroup && currentGroup.title}</div>
        </div>
      ),
    };
    tabs.push(supportTab);

    // Временно убираем таб - возможно потом вернем
    // const partnersTab = {
    //   id: "partners",
    //   label: (
    //     <div className='tabs__item'>
    //       <div className='tabs__title'>Обращение к сертифицированным партнерам {currentGroup && currentGroup.title}</div>
    //       <div className='tabs__caption'>Платные услуги</div>
    //     </div>
    //   ),
    // };
    // if (currentTabRelatedToInsales(currentGroup)) tabs.push(partnersTab);

    return tabs;
  }

  render() {
    const { articles, tags, categoriesOnLevel } = this.state;
    const currentGroup = this.getCurrentGroup();
    const selectedTag = this.selectedTag();
    const articleForTag = selectedTag && selectedTag.article_id && findById(selectedTag.article_id, articles);

    const tabs = this.getTabsForCurrentGroup(currentGroup);

    const links = {
      support: `/my/new?g=${currentGroup?.id}&t=insales`,
      partners: `/my/new?g=${currentGroup?.id}&t=partners`,
    };

    if (!tags) {
      return <PageTransition />;
    }

    const hasCategoriesForTag = () => (categoriesOnLevel[0]?.length > 0);

    return (
      <layouts.User>
        <Tabs tabs={tabs} current={this.currentTab()} onClick={(id) => this.changeTab(links[id])} />

        <Margin size="20" />
        {this.renderCurrentTab(this.currentTab())}
        {articleForTag && <Margin size="30 0 30 0">{this.renderTagArticle(articleForTag)}</Margin>}

        <Margin size="10" />

        <div ref={(ref) => this.categoryRef = ref}>
          {hasCategoriesForTag() && this.renderCategories()}
        </div>

        <Margin size="15" />
        {this.renderTicketForm()}

        <Margin size="35" />
        {this.renderGroupFaq()}
      </layouts.User>
    );
  }

  renderCategories() {
    const renderSelectCategory = (level) => {
      const { categoriesOnLevel, selectedCategoryOnLevel } = this.state;
      if (categoriesOnLevel[level].length === 0) return false;
      return (
        <React.Fragment key={level}>
          <Margin size="20" />
          <Select
            placeholder="Выберите категорию обращения"
            value={selectedCategoryOnLevel[level]}
            options={categoriesOnLevel[level]}
            onChange={(v) => {
              let categoriesByParent = this.state.categories.filter((c) => c.parentId === v?.value);

              // При удалении или выборе вышестоящей категории обновляем состояние всех потомков
              if ((!v || level < this.lastCategory() - 1)) {
                let newSelectedCategoryOnLevel = {};
                let newCategoriesOnLevel = {};
                let nonEmptyLevels = Object.keys(categoriesOnLevel).filter((l) => parseInt(l) <= level);
                for (const l of nonEmptyLevels) {
                  newSelectedCategoryOnLevel[l] = selectedCategoryOnLevel[l];
                  newCategoriesOnLevel[l] = categoriesOnLevel[l];
                }
                newSelectedCategoryOnLevel[level] = v
                newCategoriesOnLevel[level + 1] = categoriesByParent;
                this.setState({
                  selectedCategoryOnLevel: newSelectedCategoryOnLevel,
                  categoriesOnLevel: newCategoriesOnLevel
                });
                return;
              }

              // При выборе категории создаем селект с потомками выбранной категории
              this.setState({
                selectedCategoryOnLevel: Object.assign({}, selectedCategoryOnLevel, { [level]: v, [level + 1]: null}),
                categoriesOnLevel: Object.assign({}, categoriesOnLevel, { [level + 1]: categoriesByParent })
              });

              if (categoriesByParent?.length === 0) {
                setTimeout(() => {
                  const newTicketMessageForm = document.querySelector('.new-ticket-message-form')
                  if (newTicketMessageForm) {
                    newTicketMessageForm.scrollIntoView({ behavior: "smooth", block: "start" })
                  }
                }, 0);
              }
            }}
          />
        </React.Fragment>
      );
    };

    const currentCategoriesLevels = Object.keys(this.state.categoriesOnLevel);
    return [<h4 key="categories">ВАЖНО! Выбирайте категории правильно, чтобы Ваш вопрос решился быстрее</h4>].concat(
      currentCategoriesLevels.map((level) => renderSelectCategory(parseInt(level)))
    );
  }

  lastCategory() {
    return Math.max(...Object.keys(this.state.categoriesOnLevel).map(Number));
  }

  currentTab() {
    const query = qs.parse(this.props.location.search);
    if (query.t === "partners") {
      return "partners";
    }
    return "support";
  }

  renderCurrentTab(currentTab) {
    const renderPartnersTab = () => {
      const { partners, selectedPartnerCategory } = this.state;
      if (!partners) {
        return null;
      }

      // HACK: check for selected partner because user could come to page with ?partner=123
      if (!selectedPartnerCategory && !this.selectedPartner()) {
        return <Spaced h={20}>{this.renderPartnersCategories()}</Spaced>;
      }

      const filteredPartners = partners.filter((p) => p.hasCategory(selectedPartnerCategory));

      return (
        <div>
          <Spaced h={20}>{this.renderPartnersCategories()}</Spaced>
          <Margin size="10" />
          <PartnerSelect
            partners={filteredPartners}
            selected={this.selectedPartner()}
            onSelect={(id) => this.selectPartner(id)}
          />
        </div>
      );
    };

    const renderSupportTab = () => {
      return <Spaced h={20}>{this.renderSupportPublicTags()}</Spaced>;
    };

    if (currentTab === "partners") {
      return renderPartnersTab();
    }
    return renderSupportTab();
  }

  renderGroupFaq() {
    const { faq } = this.state;
    return faq && <TrustedHtml content={faq.content} />;
  }

  renderTagArticle(article) {
    return (
      <div>
        <TrustedHtml content={article.content} />
      </div>
    );
  }

  onSubjectChange(value) {
    this.setState({ subject: value })

    clearTimeout(this.state.hintsTimer)

    const newTimer = setTimeout(() => {
      const repo = new DocumentationArticlesRepo();
       repo.getAll(this.state.subject).then((success) => {
          this.setState({ hints: success['articles'] })
       })
    }, 1000)


    this.setState({ hintsTimer: newTimer })
  }

  renderTicketForm() {
    const { subject, disableForm, errors } = this.state;
    const { currentUser } = this.props;
    const freeWorkType = this.checkFreeInsalesGroup() ? ["обращений", "консультации"] : ["доработок", "доработки"];
    const selectedPartner = this.selectedPartner();
    const selectedTag = this.selectedTag();

    const renderMessageForm = () => {
      return (
        <div className="new-ticket-message-form">
          {errors.base && <Notice type="error">{errors.base.join("\n")}</Notice>}
          <LabeledInput label="Заголовок *" error={errors.subject}>
            <TextInput value={subject}
                       onChange={(value) => this.onSubjectChange(value)}
                       error={errors.subject}
            />
            
            <ul>
              {this.state.hints.length > 0 &&
                <strong>Возможно, вы найдете ответ в следующих статьях:</strong>
              }
              {this.state.hints.map((hint) => (
                <li><a href={"https://insales.ru" + hint.url} target="_blank" rel="noopener noreferrer">{hint.title}</a>
                </li>
              ))}
            </ul>
          </LabeledInput>
          <MessageForm additionalClasses='mt-4-zi-0'
                       visible={true}
                       error={errors.message}
                       label="Текст сообщения *"
                       disabledSubmit={disableForm}
                       onSubmit={this.onSubmit}
          />
        </div>)
    };

    return (
      (selectedTag || selectedPartner) && (
        <Spaced h={40}>
          {((this.checkFreeInsalesGroup() && !this.isSelectedTagRelatedToPayment()) ||
              this.isSelectedTagRelatedToFreeWorks()) &&
            currentUser &&
            currentUser.isMonthlyFreeWorkLimitReached() && (
              <Notice type="warning">
                Вы израсходовали свой ежемесячный лимит бесплатных {freeWorkType[0]} по тарифу.
                <br />
                Обращаем Ваше внимание, что последующие {freeWorkType[1]} будут выполняться на платной основе.
              </Notice>
            )}
          {(this.selectedPartner() || this.lastCategoryIsChoosen()) && renderMessageForm()}
        </Spaced>
      )
    );
  }

  lastCategoryIsChoosen() {
    const { categoriesOnLevel, selectedCategoryOnLevel } = this.state;
    const lastCategory = this.lastCategory();
    return (
      (categoriesOnLevel[0]?.length === 0) || // Если для тега нет категорий
      (categoriesOnLevel[lastCategory]?.length === 0 && selectedCategoryOnLevel[lastCategory - 1]) // Если есть
    );
  }

  selectedPartner() {
    const {partners, selectedPartnerId} = this.state;
    if(!partners)
      return null;

    return partners.find(p => p.id == selectedPartnerId);
  }

  selectedTag() {
    const {selectedTagId} = this.state;
    return selectedTagId && findById(selectedTagId, this.state.tags);
  }

  isSelectedTagRelatedToFreeWorks() {
    const selectedTag = this.selectedTag();
    // HACK: it's better to control it somehow via backend
    return selectedTag && selectedTag.name.toLowerCase().match(/верстка/);
  }

  isSelectedTagRelatedToPayment() {
    const paymentTags = ["коммерческие", "оплате/документам"];
    return paymentTags.some((tag) => this.selectedTag()?.name?.toLowerCase().search(tag) !== -1);
  }

  renderSupportPublicTags() {
    const radioForTag = tag => <Radio checked={tag.id == this.state.selectedTagId}
                                      label={tag.name}
                                      value={tag.name}
                                      description={tag.description}
                                      onChange={() => this.selectTag(tag.id)} />;

    const excludedTagsForFreePlan = ["верстка"];
    const checkTag = (tagName) =>
      excludedTagsForFreePlan.some((excludedTag) => tagName.toLowerCase().search(excludedTag) !== -1);
    return this.state.tags.flatMap((tag) =>
      !(checkTag(tag.name) && this.checkFreeInsalesGroup()) ? [<Margin size="0 0 10px 0" key={tag.id}>{radioForTag(tag)}</Margin>] : []
    );
  }

  renderPartnersCategories() {
    const radioForCategory = category =>
      <Radio checked={category == this.state.selectedPartnerCategory}
             label={entities.Partner.translateCategory(category)}
             onChange={() => this.selectPartnerCategory(category)} />;

    return entities.Partner.getCategoriesList().map(c =><div key={c}>{radioForCategory(c)}</div>)
  }

  selectTag(id) {
    const categoriesByTagId = this.state.categories.filter((c) => c.tagId === id && c.parentId === null);
    this.setState({
      selectedPartnerCategory: null,
      selectedTagId: id,
      selectedPartnerId: null,
      selectedCategoryOnLevel: { 0: null },
      categoriesOnLevel: { 0: categoriesByTagId },
    });

    setTimeout(()=> {
      this.categoryRef.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 100)
  }

  selectPartnerCategory(c) {
    this.setState({ selectedPartnerCategory: c, selectedTagId: null, selectedPartnerId: null });
  }

  selectPartner(id) {
    this.setState({ selectedTagId: null, selectedPartnerId: id });
  }

  onSubmit = ({message, fileIds}) => {
    const {selectedTagId, subject, selectedPartnerId, selectedCategoryOnLevel} = this.state;
    const {referer} = qs.parse(this.props.location.search);
    const categoryIds = Object.values(selectedCategoryOnLevel).reduce((acc, c) => {
      if (c?.value) acc.push(c.value);
      return acc;
    }, []);
    const params = { message,
                     subject,
                     referer,
                     file_ids: fileIds,
                     tag_ids: selectedTagId,
                     category_ids: categoryIds,
                     partner_id: selectedPartnerId };
    this.setState({ disableForm: true });
    API.tickets.create({
      params,
      success: response => browserHistory.push('/my/' + response.ticket.id),
      error: data => this.setState({ errors: data.responseJSON.errors_details, disableForm: false })
    });
  }
}

const mapStateToProps = state => ({ tags: state.tags, currentUser: state.currentUser });
export default connect(mapStateToProps)(NewTicketPage);
