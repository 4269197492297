import {boolValue, findById, queryParams} from 'app/lib/utils'
import {connect} from 'react-redux'
import {reloadManagers} from 'app/store/helpers'
import DetailedManagerActivityPoints from './partials/DetailedManagerActivityPoints'
import moment from 'moment'
import qs from 'query-string'
import cookie from 'cookie'

// /staff/activity_points
class ActivityPointsPage extends Component {
  state = {}

  componentDidMount() {
    reloadManagers();
    const filter = this.filterParams();
    if(!filter.from && !filter.to) {
      this.applyFilter({ from: moment().startOf('month').unix() });
      return;
    }
    this.getData();
  }

  componentDidUpdate() {
    const {lastRequestFilter} = this.state;
    const filterParams = this.filterParams();
    if(JSON.stringify(lastRequestFilter) != JSON.stringify(filterParams)) {
      this.getData();
    }
  }

  getData() {
    const filterParams = this.filterParams();
    this.setState({ lastRequestFilter: filterParams });
    const repo = new repositories.ActivityPointsRepo();
    repo.getStats(filterParams).then(data => this.setState({ data }));
  }

  render() {
    const modalProps = this.modalProps();

    return (
      <layouts.Staff>
        <Card stretch>
          {/*<h1>Активность</h1>*/}
          {modalProps && <DetailedManagerActivityPoints {...modalProps} afterSave={() => this.getData()} />}
          <TimeFilter from={this.filterParams().from} to={this.filterParams().to} onChange={f => this.applyFilter(f)} />
          <Margin size='16' />
          <Table cols={['Manager', 'Сообщения', 'Закрытые тикеты', 'Оценки', 'Звонки', 'Другое', 'Итог']}>
            {this.tableRows()}
          </Table>
        </Card>
      </layouts.Staff>
    );
  }

  applyFilter(params) {
    const current = this.filterParams();
    const nextFilter = Object.assign({}, current, params);
    const query = queryParams(nextFilter);
    browserHistory.push(`/staff/activity_points?${query}`);
  }

  filterParams() {
    const {from, to} = qs.parse(this.props.location.search);
    const group_id = cookie.parse(document.cookie).g
    return { from, to, group_id };
  }

  tableRows() {
    const {data} = this.state;
    if(!data) {
      return null;
    }
    const numberWithSign = n => n <= 0 ? n : `+${n}`;
    return data.map(r =>
      <tr key={r.manager_id} onDoubleClick={() => this.setState({ showDetailsFor: r.manager_id })}>
        <td><Link to={`/staff/managers/${r.manager_id}`}><ManagerById id={r.manager_id} /></Link></td>
        <td>{numberWithSign(r.messages)}</td>
        <td>{numberWithSign(r.closed)}</td>
        <td>{numberWithSign(r.rates)}</td>
        <td>{numberWithSign(r.calls)}</td>
        <td>{numberWithSign(r.other)}</td>
        <td>{r.total}</td>
      </tr>
    );
  }

  modalProps() {
    const {showDetailsFor} = this.state;
    if(!showDetailsFor)
      return;

    const manager = findById(showDetailsFor, this.props.managers);
    const onHide = () => this.setState({ showDetailsFor: null });
    const { from, to } = this.filterParams();
    return { manager, onHide, from, to };
  }
}

const mapStateToProps = s => ({ managers: s.activeManagers });
export default connect(mapStateToProps)(ActivityPointsPage);
