import {connect} from 'react-redux'
import {findById} from 'app/lib/utils'

function renderFreeWork({data, managers, onDelete}) {
  const worker = findById(data.manager_id, managers);

  return (
    <div>
      {data.title}
      <br />
      {worker && <div><small>{worker.full_name}</small></div>}
      <small>
        <TimePassedSince time={data.created_at} /> назад.
        &nbsp;
        {onDelete && <a onClick={onDelete}>Удалить</a>}
      </small>
    </div>
  );
}

const mapStateToProps = s => ({ managers: s.managers });
const FreeWork = connect(mapStateToProps)(renderFreeWork);

class FreeWorksInfo extends Component {
  state = {
    showAccountWorks: false,
    freeWorkType: this.props.freePlan
      ? ["обращения", "обращений", "обращение"]
      : ["доработки", "доработок", "доработку"]
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    const ticket = this.props.ticket || {};
    const {loadedForAccount} = this.state;
    if(loadedForAccount != ticket.account_id) {
      this.getData();
    }
  }

  getData(force = false) {
    const {account_id} = this.props.ticket;
    if(!account_id && !force)
      return;
    const repo = new repositories.FreeWorkRepo();

    repo.getThisMonth({ account_id: account_id, freePlan: this.props.freePlan})
        .then(works => this.setState({ loadedForAccount: account_id, doneThisMonthForAccount: works }))
        .catch(data => console.log(data))
  }

  render() {
    const {ticket} = this.props;
    const {doneThisMonthForAccount, loadedForAccount, freeWorkType} = this.state;

    return (
      <div className='free-works-modal'>
        {this.needToShowAccountWorks() && this.renderAccountWorks()}

        {
          ticket.account_id && loadedForAccount == ticket.account_id &&
          <staff.ThisMonthFreeWorksNumber done={doneThisMonthForAccount.length}
                                          freeWorkType={freeWorkType[1]}
                                          available={ticket.plan_free_works} />
        }
        <Margin size="20" />

        <strong>Все {freeWorkType[0]} в рамках тикета</strong>
        <br/>
        <small><a onClick={() => this.showAccountWorks()}>Показать {freeWorkType[0]} по аккаунту за месяц</a></small>
        <ol>
          {ticket.free_works.map(w => <li key={w.id}><FreeWork data={w} onDelete={() => this.delete(w)} /></li>)}
        </ol>

        {this.renderNewFreeWorkForm()}
      </div>
    );
  }

  needToShowAccountWorks() {
    const {showAccountWorks, doneThisMonthForAccount, loadedForAccount} = this.state;
    const {account_id} = this.props.ticket;

    return showAccountWorks && loadedForAccount == account_id && doneThisMonthForAccount;
  }

  showAccountWorks() {
    this.setState({ showAccountWorks: true });
  }

  renderAccountWorks() {
    const {doneThisMonthForAccount} = this.state;
    return (
      <Modal onHide={() => this.setState({ showAccountWorks: false })}>
        <h2>Бесплатные {this.state.freeWorkType[0]} за месяц</h2>
        <ol>
          {doneThisMonthForAccount.map(w => <li key={w.id}><LinkToTicket id={w.ticket_id} /><br /><FreeWork data={w} /></li>)}
        </ol>
      </Modal>
    )
  }

  renderNewFreeWorkForm() {
    const {title, errorMessage, ajaxInProgress, doneThisMonthForAccount} = this.state;
    const {ticket} = this.props;
    const limit = ticket.plan_free_works;
    const limitReached = limit && doneThisMonthForAccount && doneThisMonthForAccount.length >= limit;

    const onSubmit = e => {
      e.preventDefault();
      this.create();
    }
    return (
      <form onSubmit={onSubmit}>
        <strong>Добавить {this.state.freeWorkType[2]}</strong>
        <Margin size="20" />
        {errorMessage && <Notice type="error">{errorMessage}</Notice>}
        Название:
        <TextInput value={title || ''} onChange={v => this.setState({ title: v })} />
        <Margin size="10" />
        <Button color={limitReached && 'red'} disabled={ajaxInProgress}>Добавить</Button>
      </form>
    )
  }

  create() {
    const {ticket, afterChange} = this.props;
    const {title} = this.state;

    this.setState({ title: '', errorMessage: null, ajaxInProgress: true });

    const disableProgress = () => this.setState({ ajaxInProgress: false });
    const onSuccess = () => {
      disableProgress();
      afterChange();
      this.getData();
    }
    const onError = (data) => {
      this.showErrorFromResponse(data);
      disableProgress();
    }

    const repo = new repositories.FreeWorkRepo();
    repo.create(ticket.id, { title: title, freePlan: this.props.freePlan })
        .then(onSuccess,
              onError);
  }

  delete(work) {
    const {afterChange} = this.props;
    const repo = new repositories.FreeWorkRepo();
    repo.delete(work.id, { freePlan: this.props.freePlan })
        .then(() => { this.getData(true); afterChange() },
              data => this.showErrorFromResponse(data));
  }

  showErrorFromResponse(data) {
    const message = data.responseJSON.errors.join('; ');
    this.showError(message);
  }

  showError(message) {
    this.setState({ errorMessage: message });
  }
}

export default FreeWorksInfo;
