import scrollToTop from 'app/lib/scrollToTop'
import Tree from 'app/ui/tree/tree'
import qs from "query-string";
import {findById} from 'app/lib/utils';
import {errorMessage} from "../../../lib/messages";
import CategoryRepo from "../../../models/repositories/CategoryRepo";
import SplitSides from "../../../ui/SplitSides";
import {connect} from "react-redux";
import {reloadCurrentUser} from "../../../store/helpers";


class CategoryEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: { public_tag_id: qs.parse(this.props.location.search).tag },
      categories: [],
    };
  }

  componentDidMount() {
    reloadCurrentUser()
      .then(() => this.getData())
      .catch((data) => data.status === 403 || console.log(data));
  }

  getData() {
    this.getCategories();
  }

  getCategories() {
    const params = { public_tag_id: this.state.formData.public_tag_id, group_id: this.props.userGroupId };
    const repo = new CategoryRepo();

    repo.getAll(params).then(
      (categories) => {
        const category = findById(this.props.match.params.id, categories);

        this.setState({ categories: categories });
        this.setFormData(category);
      },
      (data) => console.log(data)
    );
  }

  setFormData(data) {
    this.setState({ formData: Object.assign(this.state.formData || {}, data) });
  }

  render() {
    const {showError, showSuccess, formData} = this.state;
    const categories = this.state.categories;

    return (
      <layouts.Staff>
        <Card stretch>
          {showSuccess && <Notice type="done">Категория успешно изменена</Notice>}
          {showError && <Notice type="error">{this.state.errors}</Notice>}
          <Margin size="20" />

          <Margin size="20" />
          <Spaced h={40}>
            <h4>Родительская категория</h4>
            <Tree
              items={categories}
              value={formData.id}
            />
            <Margin size="20" />

            <LabeledInput label="Название">
              <TextInput value={formData.name} onChange={v => this.setFormData({ name: v })} />
            </LabeledInput>
            <LabeledInput label="Сортировочный вес">
              <TextInput
                value={formData.sorting_weight}
                type="number"
                onChange={v => this.setFormData({ sorting_weight: v })}
              />
            </LabeledInput>
            <LabeledInput label="Ключевые слова (отделяем запятой без пробелов)">
              <Textarea value={formData.meta_keywords}
                        onChange={v => this.setFormData({ meta_keywords: v.split(',')} )}
              />
            </LabeledInput>
            <Spaced w={16} h={8}>
            <Checkbox
              label="Публичная?"
              checked={formData.public || false}
              onChange={v => this.setFormData({ public: v })}
            />
            </Spaced>
          </Spaced>
          <Margin size="20" />
          <SplitSides>
            <Button size='xs' to={`/staff/categories?tag=${formData.public_tag_id}`}>Назад</Button>
            <Button onClick={() => this.onSubmit()}>СОХРАНИТЬ</Button>
          </SplitSides>
        </Card>
      </layouts.Staff>
    );
  }

  onSubmit() {
    const repo = new CategoryRepo();
    const params = Object.assign({}, this.state.formData, { group_id: this.props.userGroupId });

    repo.update(params).then(
      () => this.onUpdateSuccess(),
      (error) => this.onUpdateError(error)
    );

    this.setState({ showSuccess: false, showError: false, errors: {} });
  }

  onUpdateSuccess() {
    this.setState({ showSuccess: true });
    scrollToTop();

    setTimeout(() => browserHistory.push(`/staff/categories?tag=${this.state.formData.public_tag_id}`), 1000);
  }

  onUpdateError(response) {
    const errors = (response?.responseJSON?.errors || [errorMessage]);

    this.setState({ showError: true, errors: errors.join("; \n")});
    scrollToTop();
  }
}

const mapStateToProps = (state) => ({ userGroupId: state.currentUser.group_id });
export default connect(mapStateToProps)(CategoryEdit);
