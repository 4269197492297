import ChatGPTModelForm from './partials/ChatGPTModelForm'
import ChatGPTModelFilter from './partials/ChatGPTModelFilter'

const repo = new repositories.ChatGPTModelRepo();

class ChatGPTModelEdit extends Component {
  state = {
    model: null,
    success: false,
    errors: null,
    processing: false,
  }

  componentDidMount() {
    this.getModel();
  }

  getModel = () => {
    this.setState({ processing: true, errors: null, success: false });

    const {id} = this.props.match.params;
    repo.get(id).then(
      model => this.setState({ model, processing: false }),
      data => this.setState({ errors: this.parsedErrors(data), processing: false }),
    );
  }

  onSubmit = () => {
    this.setState({ processing: true, errors: null, success: false });

    const { id, name } = this.state.model;
  
    repo.update({ id, name }).then(
      model => this.setState({ model, success: true, processing: false }),
      data => this.setState({ errors: this.parsedErrors(data), processing: false }),
    );
  }

  onChange = (name, value) => {
    this.setState({
      model: {
        ...this.state.model,
        [name]: value,
      },
    });
  };

  onChangeFile = (file) => {
    this.setState({ processing: true, errors: null, success: false });

    repo.update({
      id: this.state.model.id,
      training_file_id: file?.id,
    }).then(
      model => this.setState({ model, success: true, processing: false }),
      data => this.setState({ errors: this.parsedErrors(data), processing: false }),
    );
  }

  parsedErrors = ({responseJSON}) => responseJSON && responseJSON.errors || ["Произошла ошибка"];

  render() {
    const { model, success, errors, processing } = this.state;

    return (
      <layouts.Staff>
        <Card>
          {model && <ChatGPTModelFilter id={model.id} />}
        </Card>
        <Margin size="30" />
        <Card>
          <h2>Редактирование модели Chat GPT (Статус - {`${model?.status}`})</h2>
          <Margin size="10" />
          {success && <Notice type='done'>Обновлено</Notice>}
          {errors && <ErrorNotice errors={errors} />}
          <Margin size="20" />
          {model && (
            <ChatGPTModelForm
              model={model}
              processing={processing}
              onSubmit={this.onSubmit}
              onChange={this.onChange}
              onReload={this.getModel}
              onChangeFile={this.onChangeFile}
            />
          )}
          <Margin size="10" />
        </Card>
      </layouts.Staff>
    )
  }
}

export default ChatGPTModelEdit;
