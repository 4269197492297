import {connect} from 'react-redux';
import {setReverseEventFeed, setHideEventsInFeed} from 'app/store/helpers';

class TicketEventFeed extends Component {
  state = { chatGPT: null }

  constructor(props) {
    super(props);
    this.eventsEndRef = React.createRef()
  }

  getDerivedStateFromProps(props, state) {
    if(state.selected && !props.onSelect) {
      return { selected: [] };
    }
    return null;
  }

  render() {
    const { chatGPT } = this.state;

    return (
      <div className='ticket-event-feed'>
        <Spaced w={30}>
          {this.renderReverseSwitch()}
          {this.renderShowEventsSwitch()}
        </Spaced>
        <Margin size="10" />
        <div className="messages" ref={this.eventsEndRef}>
          {this.eventsToRender().map(e => this.renderTicketEvent(e))}
          {this.eventsToRender() && this.eventsToRender().length > 5 &&
            <ScrollToBottom refEnd={this.eventsEndRef} />
          }
          {chatGPT && (
            <staff.TicketEventChatGPTModal event={chatGPT}
                                           onHide={this.hideChatGPT} />
          )}
        </div>
      </div>
    );
  }

  renderShowEventsSwitch() {
    return <Switch on={<DimLink icon="visibility">Показать события</DimLink>}
                   off={<DimLink icon="visibility_off">Скрыть события</DimLink>}
                   isOn={this.props.showOnlyTextMessages}
                   onChange={status => setHideEventsInFeed(status)} />;
  }

  renderReverseSwitch() {
    return <Switch on={<DimLink icon="sort">Сначала новые</DimLink>}
                   off={<DimLink icon="sort">Сначала старые</DimLink>}
                   isOn={this.props.reverse}
                   onChange={status => setReverseEventFeed(status)} />;
  }

  eventsToRender() {
    let result = this.props.events.slice();
    if(this.props.reverse) {
      result = result.reverse();
    }

    if(this.props.showOnlyTextMessages) {
      result = result.filter(this.isTextMessage);
    }
    return result;
  }

  renderTicketEvent(e) {
    if(this.isTextMessage(e)) {
      return this.renderTextMessage(e);
    }
    return <staff.TicketEvent event={e} tags={this.props.tags} managers={this.props.managers} key={e.id} />;
  }

  renderTextMessage(event) {
    const e = event;
    const {onSelect, updateMessage, onUpdate, onEditMessage, onQuote} = this.props;
    if(updateMessage == e.id) {
      return this.renderMessageUpdateForm(e);
    }

    const onDelete = this.props.onDelete && (() => confirm('Вы уверены?') && this.props.onDelete(e))
    const now = new Date();
    const createdAt = new Date(e.created_at);
    const minutesAgo = !e.created_at ? null : (now.getTime() - createdAt.getTime()) / 1000 / 60;
    return <Message key={e.id} color={this.textMessageColor(e)}
                    selected={-1 != (this.state.selected || []).indexOf(e.id)}
                    viewed={e.manager_id && this.isViewedByUser(e)}
                    onClick={onSelect && (() => this.onMessageClick(e.id))}
                    text={this.text(e)}
                    person={e.manager || e.user}
                    onDelete={(minutesAgo && (minutesAgo < 15) || e.chat_gpt) && onDelete}
                    onQuote={onQuote}
                    onEdit={this.canEditMessage(e) && (() => onEditMessage(e))}
                    onChatGPT={this.showChatGPT(e) && (() => this.openChatGPT(e))}
                    attachments={e.attachments}
                    createdAt={e.created_at}
                    html={e.html} />;
  }

  showChatGPT(e) {
    const { kind, partner_id } = this.props.currentUser;

    return e.kind === 'message' && kind === 'manager' && !partner_id;
  }

  openChatGPT(e) {
    this.setState({ chatGPT: e });
  }

  hideChatGPT = () => {
    this.setState({ chatGPT: null });
  }

  text(e) {
    return e.chat_gpt ? `[Ответ Chat GPT. Проверьте текст перед отправкой пользователю.]\n ${e.message}` : e.message
  }

  canEditMessage(event) {
    if(!event.manager_id) // written by user
      return false;

    const {currentUser} = this.props;
    return currentUser.admin || (event.manager_id == currentUser.id);
  }

  renderMessageUpdateForm(event) {
    return <MessageForm key={event.id}
                               event={event}
                               onSubmit={data => this.props.onUpdate(event, data)}
                               visible />;
  }

  isTextMessage(event) {
    const kinds = ['message', 'note', 'complaint'];
    return (kinds.indexOf(event.kind) != -1);
  }

  isViewedByUser(message) {
    if(message.kind != 'message')
      return false;

    const events = this.props.events;
    const indexOfEvent = function(e) {
      for(let i = 0; i < events.length; i++) {
        if(events[i].id == e.id)
          return i;
      }
    }

    for(let i = indexOfEvent(message); i < events.length; i++) {
      if(events[i].kind == 'view' && events[i].user_id)
        return true;
    }
    return false;
  }

  textMessageColor(event) {
    if(event.kind == 'note') {
      return event.chat_gpt ? 'chat-gpt' : 'orange';
    }
    else if(event.kind == 'complaint') {
      return 'red';
    }
    else if(event.manager) {
      return 'blue';
    } else if(event.user && event.kind == 'message') {
      return 'white';
    }

    return null;
  }

  onMessageClick(messageId) {
    this.setState(function(prevState, props) {
      const selected = prevState.selected || [];
      const alreadySelected = (-1 != selected.indexOf(messageId));
      const changedSelected = alreadySelected ? selected.filter(id => id != messageId) : selected.concat([messageId]);
      props.onSelect(changedSelected);
      return { selected: changedSelected };
    });
  }
}

const mapStateToProps = s => ({ reverse: s.userSettings.reverseEventFeed,
                                showOnlyTextMessages: s.userSettings.hideEventsInFeed,
                                currentUser: s.currentUser });
export default connect(mapStateToProps)(TicketEventFeed);
