import {connect} from 'react-redux'
import qs from 'query-string'

class TicketsLayout extends Component {
  render() {
    return (
      <layouts.Staff>
        <Card stretch>
          {this.renderSections()}
          <br />
          {this.props.children}
        </Card>
      </layouts.Staff>
    )
  }

  renderSections() {
    const tabs = [
      {id: 'solving', label: <span>На разбор</span>},
      {id: 'viewing', label: <span>На присмотр</span>},
      {id: 'my', label: <span>Назначенные на меня</span>},
      {id: 'all', label: <span>Все</span>},
    ];

    const {status} = this.props;
    const links = {
      solving: `/staff/tickets?status=${status}&order=priority&solvable=me`,
      viewing: `/staff/tickets?status=${status}&order=priority&assignment_manager=me&assignment_kind=viewing`,
      my: `/staff/tickets?manager=me&status=${status}&order=priority`,
      all: `/staff/tickets?status=${status}&order=priority`,
    }
    return (
      <Tabs tabs={tabs} current={this.currentTab()} onClick={id => browserHistory.push(links[id])} />
    );
  }

  currentTab() {
    const query = qs.parse(this.props.location.search);
    if(query.manager == 'me') {
      return 'my';
    } else if (query.assignment_kind == 'viewing') {
      return 'viewing';
    } else if (query.solvable == 'me') {
      return 'solving';
    }
    return 'all';
  }
}

const mapStateToProps = s => ({ status: s.userSettings.ticketStatusFilter || 'open' });
export default connect(mapStateToProps)(TicketsLayout);
