function Radio({name, label, onChange, checked, disabled, description, value}) {
  return (
    <div style={{margin: '10.5px 0.5px'}}>
      <label className="radio" style={{display: 'flex', alignItems: 'center' /* justify-content: center; */}}>
        <input type="radio" className="radio__input" name={name} checked={checked || false} value={value || "on"} onChange={onChange}/>
        <div className="radio__dummy"></div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div className="radio__tag_label" style={{fontWeight: 700}}>{label}</div>
          <div className="radio__tag_help">{description}</div>
        </div>
      </label>
    </div>
  )
}

export default Radio;
