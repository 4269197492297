class TreeNode extends Component {
  children(){
    return this.props.items[this.props.item.id] || []
  }
  onClick = (e) => {
    e.stopPropagation()

    const {onChange, item} = this.props
    onChange && onChange(item.id)
  }
  isSelected(){
    return this.props.item.id == this.props.value
  }
  render() {
    const {level, items, item, onChange, value, itemComponentRender} = this.props
    const itemStyle = {}
    if(this.isSelected())
      itemStyle.backgroundColor = 'lightgrey'

    return (
      <div style={{marginLeft: level * 10 + "px"}} onClick={this.onClick}>
        {
          itemComponentRender
            ? itemComponentRender(item)
            : <div style={itemStyle}>{item.name} |{item.sorting_weight}|</div>}
        {
          this.children().map((item) => <TreeNode item={item} items={items} level={level + 1} key={item.id} onChange={onChange} value={value} itemComponentRender={itemComponentRender} />)
        }
      </div>
    )
  }
}
export default TreeNode;
