import PropTypes from 'prop-types'


class SubscriptionMenu extends Component {
  static propTypes = {
    // via
    email: PropTypes.bool,
    telegram: PropTypes.bool,

    // callbacks
    subscribe: PropTypes.func.isRequired,
    unsubscribe: PropTypes.func.isRequired,

    // style
    small: PropTypes.bool, // passed to Button
  }

  state = { show: false }

  componentDidMount() {
    jQuery(window).mouseup(this.onDocumentMouseup)
  }

  componentWillUnmount() {
    jQuery(window).off('mouseup', this.onDocumentMouseup)
  }

  onDocumentMouseup = (event) => {
    if (this.state.show && !jQuery.contains(this.refs.root, event.target)) {
      this.setState({ show: false })
    }
  }

  render() {
    const {small} = this.props;
    const {show} = this.state;
    const {email, telegram} = this.props;

    const hasAnySub = email || telegram;

    return (
      <div className='subscription-menu' ref='root'>
        <Tooltip content='Подписки'>
          <Button color={hasAnySub ? 'green' : 'gray'} small={small} onClick={() => this.setState({ show: !show })}>
            <Icon name='add_alert' />
          </Button>
        </Tooltip>
        <div>
          {show && <Dropdown items={this.dropdownItems()} onSelect={id => this.toggleSubscription(id)} />}
        </div>
      </div>
    );
  }

  dropdownItems() {
    const {email, telegram} = this.props;
    return [
      { id: 'email', label: <div>email {email && <Icon name='done' />}</div> },
      { id: 'telegram', label: <div>Telegram {telegram && <Icon name='done' />}</div> },
    ];
  }

  toggleSubscription(via) {
    const {subscribe, unsubscribe} = this.props;
    this.props[via] ? unsubscribe(via) : subscribe(via);
  }
}

export default SubscriptionMenu;

