class NewArticlePage extends Component {
  state = {}

  componentDidMount() {
  }

  render() {
    const article= this.state.article;

    return (
      <layouts.Staff>
        <Card stretch>
          <h2>Статьи</h2>
          <Margin size="20" />
          <staff.Article onSave={a => browserHistory.push(`/staff/articles/${a.id}`)}
                         article={{ title: 'Новая статья', content: '', published: false }} />
        </Card>
      </layouts.Staff>
    );
  }
}

export default NewArticlePage;
