import {boolValue} from 'app/lib/utils'
import {connect} from 'react-redux'
import {reloadManagers} from 'app/store/helpers'

// /staff/managers
class ManagerList extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    reloadManagers();
  }

  withdrawTickets(manager) {
    if(!confirm('Вы уверены?')) {
      return;
    }

    API.managers.withdrawTickets({
      manager_id: manager.id,
      success: () => { reloadManagers(); alert('Done!'); },
      error: (data) => console.log(data),
    });
  }

  renderManagerRow(manager) {
    const solving_tags = manager.manager_assignments.filter(e => e.kind == 'solving').map(e => e.tag)
    const linkToManager = `/staff/managers/${manager.id}`;
    /* eslint-disable react/jsx-key */
    const cells = [
      <Link to={linkToManager}><Person person={manager} /></Link>,
      <TagList tags={solving_tags} showShortName />,
      boolValue(manager.working_via_autotake_only),
      boolValue(manager.admin),
      boolValue(manager.isPartner()),
      <Spaced h={10} w={10}>
        <Button size="xs" color="green" to={`/staff/tickets?status=active&manager=${manager.id}`}>Тикеты</Button>
        <Button size="xs" color="gray" onClick={() => this.withdrawTickets(manager)}>Снять тикеты</Button>
      </Spaced>
    ];
    /* eslint-enable react/jsx-key */

    return (
      <tr key={'' + manager.id}>
        {cells.map((c, i) => <td key={i}>{c}</td>)}
      </tr>
    );
  }

  render() {
    const managers = this.props.managers;

    return (
      <layouts.Staff>
        <Card stretch>
          <Margin size="20 0"><h2>Менеджеры</h2></Margin>

          <Table cols={['', 'Решает', 'Работает по кнопке', 'Админ', 'Партнер', '']}>
          {managers.map(m => this.renderManagerRow(m))}
        </Table>
        </Card>
      </layouts.Staff>
    );
  }
}

const mapStateToProps = s => ({ managers: s.managers });
export default connect(mapStateToProps)(ManagerList);
