/*
 * Component alows elements to be placed in a row.
 */
function DivGroup({children}) {
  return (
    <div className='div-group'>
      {children.map((e, i) => <div key={i}>{e}</div>)}
    </div>
  )
}

export default DivGroup;
