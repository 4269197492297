import {connect} from 'react-redux'
import {fetchObjectSubset} from 'app/lib/utils'
import {reloadTags} from 'app/store/helpers'
import scrollToTop from 'app/lib/scrollToTop'
import cookie from 'cookie'

class NewPartnerPage extends Component {
  constructor(props) {
    super(props)
    this.state = { formData: {} };
  }

  render() {
    const {formData, showError, showSuccess} = this.state;

    return (
      <layouts.Staff>
        <Card stretch>
          <h2>Новый партнер</h2>
          <Margin size="20" />

          {showSuccess && <Notice type="done">Успешно</Notice>}
          {showError && <Notice type="error">Произошла ошибка</Notice>}

          <LabeledInput label="Название">
            <TextInput value={formData.title} onChange={v => this.setFormData({ title: v })} />
          </LabeledInput>

          <LabeledInput label="E-mail менеджера">
            <TextInput value={formData.email} onChange={v => this.setFormData({ email: v })} />
          </LabeledInput>

          <br />
          <Button onClick={() => this.onSubmit()}>СОЗДАТЬ</Button>
        </Card>
      </layouts.Staff>
    );
  }

  setFormData(data) {
    const allowedFields = ['title', 'email', 'group_id'];
    data = fetchObjectSubset(data, allowedFields);
    this.setState({ formData: Object.assign(this.state.formData || {}, data) });
  }

  onSubmit() {
    this.setFormData({ group_id: cookie.parse(document.cookie).g });

    const repo = new repositories.PartnerRepo();
    repo.create(this.state.formData)
        .then(partner => this.onUpdateSuccess(partner),
              error => this.onUpdateError(error));

    this.setState({ showSuccess: false, showError: false });
  }


  onUpdateSuccess(partner) {
    this.setState({ showSuccess: true });    
    scrollToTop();
    browserHistory.push(`/staff/partners/${partner.id}`);
  }

  onUpdateError(error) {
    console.log(error)
    this.setState({ showError: true });
    scrollToTop();
  }
}

export default NewPartnerPage;
