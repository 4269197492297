class Card extends Component {
  componentDidMount() {
    if (this.props.stretch) {
      this.stretch();
      jQuery(window).resize(this.stretch);
    }
  }

  componentWillUnmount() {
    if (this.props.stretch) {
      jQuery(window).off('resize', this.stretch);
    }
  }

  stretch = () => {
    const height = jQuery(window).height();
    const top = jQuery(this.refs.root).offset().top;
    jQuery(this.refs.root).css({minHeight: height - 80 - top});
  }

  render() {
    const {children, className} = this.props;
    return (
      <div className={`card ${className}`} ref='root'>
        {children}
      </div>
    );
  }
}

export default Card;
