import {connect} from 'react-redux'

class TicketSubscriptionMenu extends Component {
  state = { subscriptions: [], loadedFor: null }

  componentDidMount() {
    this.getSubscriptions();
  }

  componentDidUpdate() {
    const {loading, loadedForTicket} = this.state;
    const {ticket} = this.props;

    if(!loading && loadedForTicket != ticket.id)
      this.getSubscriptions();
  }

  getSubscriptions() {
    const {currentUser, ticket} = this.props;
    if(!ticket || !currentUser.id)
      return;

    const id = ticket.id;
    const repo = new repositories.SubscriptionRepo();
    this.setState({ loading: true });
    const promise = repo.getAll(currentUser.id, { kind: 'ticket', target_id: id });
    promise.then(subscriptions => this.setState({ subscriptions, loadedForTicket: id, loading: false }));
  }

  render() {
    const {ticket, currentUser} = this.props;
    if(!ticket || !currentUser.id)
      return <span />;

    const {subscriptions} = this.state;
    const emailSubscription = subscriptions.find(s => s.via == 'email');
    const tgSubscription = subscriptions.find(s => s.via == 'telegram');
    const unsubscribe = via => {
      const sub = subscriptions.find(s => s.via == via);
      sub && sub.destroy().then(() => this.getSubscriptions());
    };

    const subscribe = via => {
      const repo = new repositories.SubscriptionRepo();
      repo.create(currentUser.id, { via, kind: 'ticket', target_id: ticket.id }).then(() => this.getSubscriptions());
    }

    return <SubscriptionMenu email={!!emailSubscription}
                                    telegram={!!tgSubscription}
                                    subscribe={subscribe}
                                    unsubscribe={unsubscribe}
                                    small />
  }
}

const mapStateToProps = s => ({ currentUser: s.currentUser });
export default connect(mapStateToProps)(TicketSubscriptionMenu);
