import {bemModifiers} from 'app/lib/utils';

class Tooltip extends Component {
  render() {
    const {wrap, children, content, ticketMessage} = this.props;
    const bubbleClass = bemModifiers('tooltip__bubble', [wrap && 'wrap', ticketMessage && 'ticket-message'])
    if(!content)
      return <span>{children}</span>;

    return (
      <div className="tooltip">
        {children}
        <div className={bubbleClass}>
          {content}
          <div className="tooltip__bubble-arrow" />
        </div>
      </div>
    );
  }
}


export default Tooltip;
