const ObjectCantBeLoadedPage = function(_props) {
  return (
    <layouts.User>
      <h1>Ошибка</h1>
      <p>Похоже, Вы не имеете доступа к данному объекту, либо объект не существует.</p>
    </layouts.User>
  );
}

export default ObjectCantBeLoadedPage;
