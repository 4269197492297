import {iterateObject} from 'app/lib/utils'

class AttachmentRepo {
  /*
   * @param file [File]
   * @param onProgress [fn(percent)]
   * @param meta {}
   * @return Promise [fn(xhr), fn(xhr)]
   */
  upload(file, onProgress, meta = {}) {
    const promise = new Promise(function(resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', '/api/attachments.json', true);
      iterateObject(API.headersForRequest(), (k, v) => xhr.setRequestHeader(k, v));

      if(onProgress) {
        xhr.upload.addEventListener('progress', function(e) {
          if (e.lengthComputable) {
            const percent = Math.round(e.loaded / e.total * 100);
            onProgress(percent);
          }
        }, false);
      }

      xhr.onreadystatechange = function() {
        if(xhr.readyState != 4) { // 4 = done
          return;
        }
        xhr.status == 200 ? resolve(xhr) : reject(xhr);
      }
      const fd = new FormData();
      fd.append('meta', JSON.stringify(meta));
      fd.append('file', file);
      xhr.send(fd);
    });

    return promise;
  }
}

export default AttachmentRepo;
