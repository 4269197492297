import { connect } from 'react-redux'
import { reloadTags, setTicketStatusFilter } from 'app/store/helpers'
import { queryParams, isArray } from 'app/lib/utils'
import qs from 'query-string'
import CategoryRepo from "../../../models/repositories/CategoryRepo";
import CategoryList from "../../../ui/CategoryList";
import TagList from "../../../ui/TagList";
import {reloadCurrentUser} from "../../../store/helpers";

// /staff/tickets
class Tickets extends Component {
  state = {}

  componentDidMount() {
    reloadTags();
    reloadCurrentUser()
      .then(() => this.getData())
      .catch((data) => data.status === 403 || console.log(data));
  }

  getData() {
    this.getCategories();
  }

  getCategories() {
    const repo = new CategoryRepo();
    repo.getAll({ group_id: this.props.userGroupId }).then(
      (categories) => this.setState({ categories: categories }),
      (data) => console.log(data)
    );
  }

  generateUrl(parameters) {
    const keys = ['status', 'order', 'reverse', 'user', 'from', 'to',
                  'assignment_kind', 'assignment_manager', 'account',
                  'search_number', 'search_content', 'search_user',
                  'search_account', 'search', 'solvable'];

    let query = `?page=${parameters.page || 1}`;
    const {tags, categories, manager} = parameters;

    if(manager)
      query += (isArray(manager) ? manager : [manager]).reduce((a, e) => a + `&manager=${e}`, '');
    if(tags)
      query += (isArray(tags) ? tags : [tags]).reduce((a, e) => a + `&tags=${e}`, '');
    if(categories)
      query += (isArray(categories) ? categories : [categories]).reduce((a, e) => a + `&categories=${e}`, '');

    query += '&' + queryParams(parameters, keys);

    return '/staff/tickets' + query
  }

  render() {
    const urlToPage = (n) => this.generateUrl(this.locationQueryMergedWith({ page: n }));

    return (
      <layouts.staff.Tickets location={this.props.location}>
        <SplitSides>
          {this.renderStatusSelect()}
          {this.renderOrderSelect()}
        </SplitSides>
        {this.renderTimeFilter()}
        {this.renderFilters()}

        <Margin size='20' />
        <staff.Tickets params={this.ticketsGetParams()} urlToPage={urlToPage} />
      </layouts.staff.Tickets>
    )
  }

  ticketsGetParams() {
    const query = qs.parse(this.props.location.search);

    let searchParams;
    if(query.search) {
      searchParams = query.search;
    } else {
      searchParams = { number: query.search_number,
                       content: query.search_content,
                       user: query.search_user,
                       account: query.search_account };
    }

    return {page: query.page || 1,
             from: query.from,
             to: query.to,
             solvable: query.solvable,
             assignment_manager: query.assignment_manager,
             assignment_kind: query.assignment_kind,
             manager_id: query.manager,
             user_id: query.user,
             status: query.status,
             reverse: query.reverse,
             account_id: query.account,
             order: query.order,
             search: searchParams,
             tag_ids: query.tags,
             category_ids: query.categories};
  }

  renderTimeFilter() {
    const {from, to} = qs.parse(this.props.location.search);
    const onChange = (data) => browserHistory.push(this.urlWithAppliedFilters(data));
    return <TimeFilter from={from} to={to} onChange={onChange}/>
  }

  renderFilters() {
    const tags = this.props.tags.filter((t) => t.partner_id == null);
    const partner_tags = this.props.tags.filter((t) => t.partner_id != null);
    const { categories } = this.state;
    const categoryTitle = "Категории";

    const isTargetSelected = (target, type) => this.selectedFilters(type).includes(target.id);
    const onTargetClicked = (type) => {
      return (target) =>
        (isTargetSelected(target, type) ? this.removeTargetFilter(target, type) : this.addTargetFilter(target, type));
    }

    return (
      <>
        {[
          { title: "Теги", tags },
          { title: "Партнерские теги", tags: partner_tags },
        ]
          .map((item) => (
            <details key={item.title} style={{ marginTop: 25 }}>
              <summary className='cursor-pointer'>{item.title}</summary>
              <Margin size='8' />
              <TagList
                tags={item.tags}
                highlight={this.selectedFilters("tags")}
                onSelect={onTargetClicked("tags")}
                small
              />
            </details>
          ))
          .concat([
            <details key={categoryTitle} style={{ marginTop: 25 }}>
              <summary>{categoryTitle}</summary>
              <CategoryList
                categories={categories}
                highlight={this.selectedFilters("categories")}
                onSelect={onTargetClicked("categories")}
                small
              />
            </details>,
          ])}
      </>
    );
  }

  renderOrderSelect() {
    const orderUrl = (params) => this.urlWithAppliedFilters(params);
    const onChange = (params) => browserHistory.push(orderUrl(params));

    const query = qs.parse(this.props.location.search);
    return (
      <TicketOrderSelect current={query.order}
                         reverse={query.reverse}
                         onChange={onChange} />
    );
  }

  renderStatusSelect() {
    const statusUrl = (status) => this.urlWithAppliedFilters({ status });
    const onChange = status => {
      setTicketStatusFilter(status);
      browserHistory.push(statusUrl(status));
    }

    return (
      <StatusFilterSelect current={qs.parse(this.props.location.search).status}
                                 onChange={onChange} />
    );
  }

  selectedFilters(type) {
    const parameter = qs.parse(this.props.location.search)[type] || [];
    const result = isArray(parameter) ? parameter : [parameter]
    return result.map(e => e - 0); // convert to ints
  }

  addTargetFilter(target, type) {
    const selected = this.selectedFilters(type);
    selected.push(target.id);
    const url = this.urlWithAppliedFilters({ [type]: selected });
    browserHistory.push(url);
  }

  removeTargetFilter(target, type) {
    const filteredTargets = this.selectedFilters(type).filter(e => e !== target.id)
    const url = this.urlWithAppliedFilters({ [type]: filteredTargets });
    browserHistory.push(url);
  }

  // Applies filters to **current** url and **resets page**
  urlWithAppliedFilters(params) {
    return this.generateUrlMergedWith(Object.assign({}, params, { page: null }));
  }

  generateUrlMergedWith(params) {
    return this.generateUrl(this.locationQueryMergedWith(params));
  }

  locationQueryMergedWith(obj) {
    return Object.assign({}, qs.parse(this.props.location.search), obj);
  }
}

const mapStateToProps = state => ({ tags: state.tags, userGroupId: state.currentUser.group_id});
export default connect(mapStateToProps)(Tickets);
