import {bemModifiers} from 'app/lib/utils';
import PropTypes from 'prop-types';

function IconButton(props) {
  const {icon, children, color, disabled} = props;
  const blockClass = bemModifiers('icon-button', [color]);

  const onClick = props.to ? () => browserHistory.push(props.to) : props.onClick;
  const {onDoubleClick} = props;

  return (
    <button disabled={disabled} className={blockClass} onDoubleClick={onDoubleClick} onClick={onClick}>
      <div className='icon-button__icon'>
        <Icon name={icon} />
      </div>
      {children}
    </button>
  )
}

IconButton.propTypes = {
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(['red', 'green', 'grey', 'yellow']),
}

export default IconButton;
