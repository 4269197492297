import {boolValue} from 'app/lib/utils'

class AutoRepliesPage extends Component {
  state = {auto_replies: []}

  componentDidMount() {
    this.getData();
  }

  getData() {
    if (this.state.dataLoading)
      return;

    this.setState({dataLoading: true});
    const repo = new repositories.AutoReplyRepo();
    repo.getAll()
      .then(auto_replies => this.setState({auto_replies, dataLoading: false}))
  }

  render() {
    const {auto_replies} = this.state;

    return (
      <layouts.Staff>
        <Card stretch>
          <h2>Авто-ответы</h2>

          <Margin size='20'/>

          <Table cols={['Название', 'Сообщение', 'Задержка в днях', 'Активна', '']}>
            {auto_replies.map(a => this.renderTableRow(a))}
          </Table>

          <Margin size='20'/>
          <Button size='xs' color='green' to='/staff/auto_replies/new'>Создать</Button>
        </Card>
      </layouts.Staff>
    );
  }

  renderTableRow(autoReply) {
    return (
      <tr key={autoReply.id}>
        <td>{autoReply.title}</td>
        <td>{autoReply.content}</td>
        <td>{autoReply.delay_in_days}</td>
        <td>{boolValue(autoReply.active)}</td>
        <td>
          <Spaced h={10} w={10}>
            <Button size="xs" color="blue" to={`/staff/auto_replies/${autoReply.id}`}>Редактировать</Button>
            <Button size="xs" color="gray" onClick={() => this.deleteAutoReply(autoReply)}>Удалить</Button>
          </Spaced>
        </td>
      </tr>
    )
  }

  deleteAutoReply(autoReply) {
    if (!confirm(`Вы уверены, что хотите удалить авто-ответ ${autoReply.title} ?`))
      return;

    const repo = new repositories.AutoReplyRepo();
    repo.destroy(autoReply)
      .then(data => this.getData());
  }
}

export default AutoRepliesPage;
