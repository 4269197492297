import React, { Component } from 'react'

class UserTicketTable extends Component {
  renderRow(ticket) {
    // const {onSelectChange} = this.props;
    // const selected = this.props.selected || [];
    /* eslint-disable react/jsx-key */
    const cells = [
      <TicketSubject ticket={ticket} />,
      <user_ui.TicketStatus ticket={ticket} />,
      ticket.user_space_events_count,
      ticket.id,
    ]
    /* eslint-enable react/jsx-key */

    return (
      <tr key={ticket.id}>
        {cells.map((c, i) => <td key={i}>{c}</td>)}
      </tr>
    )
  }

  render() {
    const cols = ['Тема', 'Статус', 'Сообщений', 'Номер'];
    return (
      <TableOrNoContent cols={cols} loadingInProgress={this.props.loadingInProgress}>
        {(this.props.tickets || []).map(t => this.renderRow(t))}
      </TableOrNoContent>
    );
  }
}

export default UserTicketTable;
