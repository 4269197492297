import AbstractEntity from './AbstractEntity'

class Statistic extends AbstractEntity {
  // it does not order them really, so TODO: do it
  orderedColumns() {
    return Object.keys(this.columns)
      .reduce((acc, key) =>
        key === 'parent_id' ? acc : [...acc, { systemName: key, humanName: this.columns[key] }],
        []
      )
  }

  orderedColumnHumanNames() {
    return this.orderedColumns().map(col => col.humanName);
  }

  orderedColumnSystemNames() {
    return this.orderedColumns().map(col => col.systemName);
  }

  dataValuesOrderedByColumns() {
    const columnNames = this.orderedColumnSystemNames();
    const orderedRow = row => columnNames.map(n => row[n]);
    return this.data.map(orderedRow);
  }

  columnValues(columnName) {
    return this.data.map(row => row[columnName]);
  }
}

export default Statistic;
