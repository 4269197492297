const selectOptions = [
  { id: 'all',       label: 'любой статус' },
  { id: 'active',    label: 'активные' },
  { id: 'open',      label: 'открытые' },
  { id: 'waiting',   label: 'отвеченные' },
  { id: 'closed',    label: 'закрытые' },
  { id: 'archived',  label: 'в архиве' },
  { id: 'draft',     label: 'черновики'},
];

class StatusFilterSelect extends Component {
  render() {
    const defaultStatus = 'all';
    const selectedId = this.props.current || defaultStatus;
    const selected = selectOptions.find(e => e.id == selectedId);
    const onSelect = id => this.props.onChange(id == defaultStatus ? null : id);
    return (
      <DropdownTrigger items={selectOptions} onSelect={onSelect}>
          {selected.label}
      </DropdownTrigger>
    );
  }
}

export default StatusFilterSelect;
