import {connect} from 'react-redux'
import {reloadManagers, reloadTags} from 'app/store/helpers';
import {assoc} from 'app/lib/utils'
import cookie from 'cookie'
import scrollToTop from 'app/lib/scrollToTop'

class ScriptEditPage extends Component {
  state = {}

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    if(this.scriptId() !== this.state.lastId.toString()) {
      this.getData();
    }
  }

  scriptId() {
    return this.props.match.params.id;
  }

  getData() {
    // CRUTCH! move it to ScriptActionForm when reloading uses timer
    reloadTags();
    reloadManagers();

    if(this.isNewScript()) {
      this.setScript({ name: '', personal: true });
      return;
    }

    const id = this.scriptId();

    API.scripts.get({ id,
                      success: s => this.setScript(s),
                      error: data => console.log(data) });

    API.scriptActions.getAll({ scriptId: id,
                               success: actions => this.setActions(actions),
                               error: data => console.log(data) });
  }

  setActions(actions) {
    this.setState({ actions, actionsFormData: actions.slice() });
  }

  setScript(script) {
    const formData = this.state.scriptFormData || {};
    this.setState({ script,
                    lastId: script.id || 'new',
                    scriptFormData: Object.assign({}, formData, script) });
  }

  isNewScript() {
    return this.scriptId() === 'new';
  }

  render() {
    if (!this.state.script) {
      return <PageTransition />;
    }
    const {showSuccess, showError, scriptFormData} = this.state;
    const errors = this.state.errors || {};

    return (
      <layouts.Staff>
        <Card stretch>
          {showSuccess && <Notice type="done">Успешно</Notice>}
          {showError && <Notice type="error">Произошла ошибка</Notice>}

          <LabeledInput label="Название">
            <TextInput value={scriptFormData.name} onChange={v => this.updateScriptFormData({ name: v })} />
          </LabeledInput>

          <Margin size='16' />

          <Checkbox disabled={!this.isAdmin()}
                           label="Личный"
                           checked={scriptFormData.personal}
                           onChange={v => this.updateScriptFormData({ personal: v })} />

          <Margin size='16' />

          <Checkbox disabled={!this.isAdmin()}
                    label="Не начислять очки активности"
                    checked={scriptFormData.do_not_add_activity_points}
                    onChange={v => this.updateScriptFormData({ do_not_add_activity_points: v })} />

          <Margin size='16' />

          <Button onClick={() => this.saveScript()}>{this.isNewScript() ? 'Создать' : 'Сохранить'}</Button>

          <Margin size='40' />
          {this.isNewScript() || this.renderActions()}
        </Card>
      </layouts.Staff>
    );
  }

  updateScriptFormData(data) {
    this.setState(ps => ({ scriptFormData: Object.assign({}, ps.scriptFormData, data) }));
  }

  renderActions() {
    const actionsFormData = this.state.actionsFormData || [];
    const addAction = () => {
      actionsFormData.push({ action_type: 'add_message', action_value: 'hehey' });
      this.forceUpdate();
    }

    const deleteAction = action =>
      this.setState(ps => ({ actionsFormData: ps.actionsFormData.filter(e => e !== action) }));

    const onChangeFor = action => data => {
      Object.assign(action, data);
      this.forceUpdate();
    }

    const renderActionForm = (a, i) =>
      <staff.ScriptActionForm key={i}
                                     action={a}
                                     onDelete={() => deleteAction(a)}
                                     onChange={onChangeFor(a)} />;

    return (
      <div>
        <h2>Действия</h2>
        {actionsFormData.map(renderActionForm)}
        <Margin size='4' />
        <div><a onClick={addAction}> Добавить </a></div>
        <Margin size='20' />
        <Button onClick={() => this.saveActions()}>Сохранить</Button>
      </div>
    )
  }

  isAdmin() {
    const {currentUser} = this.props;
    return currentUser && currentUser.admin;
  }

  saveScript() {
    this.setState({ showSuccess: false, showError: false, errors: {} });
    const {scriptFormData} = this.state;

    const message = scriptFormData
    message.group_id = cookie.parse(document.cookie).g

    if(this.isNewScript()) {
      const onSuccess = script => {
        this.setScript(script);
        browserHistory.push(`/staff/scripts/${script.id}`);
      }
      API.scripts.create({ params: message,
                           success: onSuccess,
                           error: data => this.onRequestError(data) });
      return
    }
    API.scripts.update({ id: this.state.script.id,
                         params: message,
                         success: s => this.onScriptUpdateSuccess(s),
                         error: data => this.onRequestError(data) });
  }

  saveActions() {
    API.scriptActions.create({ scriptId: this.state.script.id,
                               params: { actions: this.state.actionsFormData },
                               success: actions => this.onActionsUpdateSuccess(actions),
                               error: data => console.log(data), })
  }

  onScriptUpdateSuccess(script) {
    this.setScript(script);
    this.setState({ showSuccess: true });
    scrollToTop();
  }

  onActionsUpdateSuccess(actions) {
    this.setActions(actions);
    this.setState({ showSuccess: true });
    scrollToTop();
  }

  onRequestError(response) {
    this.setState({ showError: true, errors: response.errors });
    scrollToTop();
  }
}

const mapStateToProps = s => ({ currentUser: s.currentUser });
export default connect(mapStateToProps)(ScriptEditPage);
