import {bemModifiers} from 'app/lib/utils'

const dict = {
  open:     'открыт',
  waiting:  'отвечен',
  closed:   'закрыт',
  archived: 'в архиве',
  draft:    'черновик',
}

const className = ({bold}) => bemModifiers('status', [bold && 'bold']);
const Status = props => <span className={className(props)}>{dict[props.name]}</span>;
export default Status;
