import {connect} from 'react-redux'

// TODO: reload_managers when it's with time checking

const ChangeAssignee = function(props) {
  const {action, value, onChange, managers} = props;

  const items = managers.map(m => ({ id: m.id, label: m.full_name }));
  items.unshift({ id: null, label: 'Освободить' }, 'hr');

  const v = value === undefined ? action.action_value : value;
  const selectedId = v ? v - 0 : null;
  const selected = items.find(e => e.id === selectedId);

  return (
    <div>
      Назначить ответственного: &nbsp;
      <DropdownTrigger items={items} onSelect={onChange}>
        {selected.label}
      </DropdownTrigger>
    </div>
  );
}

const mapStateToProps = s => ({ managers: s.managers });
export default connect(mapStateToProps)(ChangeAssignee);
