import AbstractEntity from './AbstractEntity'

class Tag extends AbstractEntity {
  groupName() {
    if(this.system_tag) {
      return 'Системные';
    } else if(this.partner_id) {
      return 'Партнерские';
    } else {
      return 'Общие';
    }
  }
}

export default Tag;
