class CategoryRepo {
  getAll(params) {
    return new Promise(function (resolve, reject) {
      API.categories.getAll({
        params,
        success: resolve,
        error: reject,
      });
    });
  }

  create(category) {
    return new Promise(function(resolve, reject) {
      API.categories.create({
        params: category,
        success: resolve,
        error: reject,
      });
    });
  }

  update(category) {
    return new Promise(function(resolve, reject) {
      API.categories.update({
        id: category.id,
        params: category,
        success: resolve,
        error: reject,
      });
    });
  }

  destroy(category) {
    return new Promise(function(resolve, reject) {
      API.categories.delete({
        id: category.id,
        success: resolve,
        error: reject,
      });
    });
  }
}

export default CategoryRepo;
