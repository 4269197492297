import TagForm from 'app/pages/staff/tags/partials/TagForm'

const tagRepo = new repositories.TagRepo();
const articleRepo = new repositories.ArticleRepo();

class TagPage extends Component {
  state = {}

  componentDidMount() {
    this.getData();
  }

  getData() {
    const {id} = this.props.match.params;
    tagRepo.get(id).then(
      tag => this.setState({ tag, title: tag.short_name }),
      data => console.log(data)
    );
    articleRepo.getAll().then(
      articles => this.setState({ articles }),
      data => console.log(data)
    )
  }

  render() {
    const {title, tag, articles, success, errors} = this.state;

    if(!tag)
      return null;

    const onChange = data => {
      Object.assign(tag, data);
      this.setState({ success: false });
      // this.forceUpdate();
    };

    const onSave = () => tagRepo.save(tag).then(
      tag => this.setState({ tag, success: true }),
      data => this.showErrorsFromResponse(data)
    );

    return(
      <layouts.Staff>
        <Card stretch>
          <h2>{title}</h2>
          {success && <Notice type='done'>Saved</Notice>}
          {errors && <ErrorNotice errors={errors} />}
          <Margin size='25' />
          <TagForm tag={tag} articles={articles} onChange={onChange} onSave={onSave} />
          {success && <Notice type='done'>Saved</Notice>}
          {errors && <ErrorNotice errors={errors} />}
        </Card>
      </layouts.Staff>
    );
  }

  showErrorsFromResponse({responseJSON}) {
    let errors = ["Unknown error"];
    if(responseJSON && responseJSON.errors) {
      errors = responseJSON.errors;
    }

    clearTimeout(this.state.errorsHidingTimeout);
    this.setState({
      errors: responseJSON.errors,
      errorsHidingTimeout: setTimeout(() => this.hideErrorNotice(), 5000)
    });
  }

  hideErrorNotice() {
    this.setState({ errors: null });
  }
}

export default TagPage;
