class ActivityPointsRepo {
  getAll(params) {
    const promise = new Promise(function(resolve, reject) {
      API.activityPoints.getAll({
        params,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }

  getStats(params) {
    const promise = new Promise(function(resolve, reject) {
      API.activityPoints.getStats({
        params,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }

  update(id, params) {
    const promise = new Promise(function(resolve, reject) {
      API.activityPoints.update({
        id,
        params,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }

  create(params) {
    const promise = new Promise(function(resolve, reject) {
      API.activityPoints.create({
        params,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }
}

export default ActivityPointsRepo;
