import moment from 'moment'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import {bemModifiers} from 'app/lib/utils'

const TimestampPicker = function({value, onChange, inline}) {
  const className = bemModifiers('timestamp-picker', [inline && 'inline']);
  return <Datetime value={value && moment.unix(value)}
                   className={className}
                   timeFormat='HH:mm'
                   onChange={m => onChange(m.unix ? m.unix() : null)} />;
}


export default TimestampPicker;
