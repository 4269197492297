
/*
 *
 * Use promise to unify repositories
 * (in case we won't use JQuery in future or some cases)
 *
 */
class GroupRepo {
  getAll(params) {
    const promise = new Promise(function(resolve, reject) {
      const mapToEntities = groups => groups.map(t => new entities.AbstractEntity(t));
      API.groups.getAll({
        params,
        success: groups => resolve(mapToEntities(groups)),
        error: reject
      });
    });

    return promise;
  }


  get(id) {
    const promise = new Promise(function(resolve, reject) {
      API.groups.get({
        id,
        success: t => resolve(new entities.AbstractEntity(t)),
        error: reject,
      });
    });

    return promise;
  }
}

export default GroupRepo;
