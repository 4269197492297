import AbstractEntity from './AbstractEntity'

const categoryTranslations = { design: 'Дизайн',
                               integration: 'Интеграция',
                               seo: 'Продвижение' }

class Partner extends AbstractEntity {
  static getCategoriesList() {
    return ['design', 'integration', 'seo'];
  }

  static translateCategory(c) {
    return categoryTranslations[c];
  }


  getCategories() {
    const fields = Partner.getCategoriesList();
    return fields.filter(f => this[f]);
  }

  hasCategory(c) {
    return this.getCategories().indexOf(c) !== -1;
  }

  translateCategory(category) {
    return Partner.translateCategory(category);
  }
}

export default Partner;
