import {isUndefined, iterateObject} from 'app/lib/utils'
import {connect} from 'react-redux'
import {actions} from 'app/store/actions'
import {reloadAnswerTemplates, reloadCurrentUser, reloadManagers, setPreferPersonalAnswerTemplates} from 'app/store/helpers'
import {sortedAnswerTemplates} from 'app/store/mapping_helpers'

// /staff/answer_templates
class AnswerTemplateList extends Component {
  state = {
    showAnswerTemplates: true
  }

  componentDidMount() {
    reloadAnswerTemplates();
    reloadCurrentUser();
    reloadManagers();
    this.updateMissingSortings();
  }

  componentDidUpdate() {
    this.updateMissingSortings();
  }

  updateMissingSortings() {
    const {answerTemplates, sortings, updateSortings} = this.props;
    const missingTemplates = answerTemplates.filter(t => isUndefined(sortings[t.id]));
    if(missingTemplates.length == 0) {
      return;
    }
    let i = Object.keys(sortings).length;
    const newSortings = {};
    missingTemplates.forEach(t => { newSortings[t.id] = i++ });
    updateSortings(newSortings);
  }

  onDrop(e, dropOverId) {
    const draggedId = e.dataTransfer.getData('id');
    const {sortings} = this.props;
    let currentPosition = sortings[draggedId];
    let toPosition = sortings[dropOverId] - 1;
    this.changeSorting(draggedId, toPosition - currentPosition);
  }

  hideAnswerTemplates() {
    this.setState({ showAnswerTemplates: false });
  }

  showAnswerTemplates(e) {
    const currentHeaderName = e.currentTarget.innerText.split('\t')[1];
    const newHeaderName = e.dataTransfer.getData('headerName');
    if (currentHeaderName === newHeaderName) {
      this.setState({ showAnswerTemplates: true });
      return;
    }

    setPreferPersonalAnswerTemplates(!this.getPreferPersonalAnswerTemplates());
    this.setState({ showAnswerTemplates: true });
  }

  getPreferPersonalAnswerTemplates() {
    const {userSettings} = this.props;
    return (userSettings && !jQuery.isEmptyObject(userSettings)) 
      ? userSettings.preferPersonalAnswerTemplates 
      : false;
  }

  render() {
    const [personalAnswerTemplates, systemAnswerTemplates] = this.props.answerTemplates.reduce((res, elem) => {
      res[elem.personal ? 0 : 1].push(elem);
      return res;
    }, [[], []]);

    return (
      <layouts.Staff>
        <Card stretch>
          <Margin size="20 0"><h2>Шаблоны ответов</h2></Margin>

          <Button size="xs" color="green" to={"/staff/answer_templates/new"}>Новый</Button>
          <Margin size="20" />

          <Table cols={['', 'Название', 'Содержимое', 'Автор', '']}>
            {this.getPreferPersonalAnswerTemplates()
              ? [
                this.renderTablePart('Личные', personalAnswerTemplates),
                this.renderTablePart('Системные', systemAnswerTemplates)  
              ] : [
                this.renderTablePart('Системные', systemAnswerTemplates),
                this.renderTablePart('Личные', personalAnswerTemplates)
              ]
            }
          </Table>

          <Margin size="20" />
          <Button size="xs" color="green" to={"/staff/answer_templates/new"}>Новый</Button>
        </Card>
      </layouts.Staff>
    );
  }

  renderTablePart(headerName, answerTemplates) {
    return ([
      answerTemplates.length > 0 && this.renderTableRowHeader(headerName),
      this.state.showAnswerTemplates && answerTemplates.map(t => this.renderTableRow(t))
    ]);
  }

  renderTableRowHeader(headerName) {
    return (
      <DraggableTrHeader 
        headerName={headerName}
        colSpan={4}
        getPreferPersonal={this.getPreferPersonalAnswerTemplates.bind(this)}
        onDragStart={this.hideAnswerTemplates.bind(this)}
        onDrop={this.showAnswerTemplates.bind(this)} />
    );
  }

  renderTableRow(answerTemplate) {
    const {title, content, manager} = answerTemplate;
    const contentText = content.length > 50 ? (content.substring(0, 50) + '...') : content;
    /* eslint-disable react/jsx-key */
    const cells = [
      <span>{title}</span>,
      <span>{contentText}</span>,
      manager && <Person short person={manager} />,
      this.renderControlButtons(answerTemplate),
    ];
    /* eslint-enable react/jsx-key */

    return (
      <DraggableTr
        key={'' + answerTemplate.id}
        id={answerTemplate.id}
        onDrop={this.onDrop.bind(this)}
        cells={cells} />
    );
  }

  renderControlButtons(answerTemplate) {
    if(!this.hasWriteAccess(answerTemplate)) {
      return null;
    }

    const deleteAnswerTemplate = () => {
      if (!confirm(`Вы точно хотите удалить ${answerTemplate.title}?`)) {
        return;
      }

      API.answerTemplates.delete({ id: answerTemplate.id,
                                   success: () => reloadAnswerTemplates(),
                                   error: data => console.log(data) });
    }

    return (
      <Spaced w={3}>
        <IconButton icon='edit' to={"/staff/answer_templates/" + answerTemplate.id} />
        <IconButton icon='delete' color='red' onClick={deleteAnswerTemplate} />
      </Spaced>
    );
  }

  changeSorting(id, step) {
    const {sortings, updateSortings} = this.props;
    const fromIndex = sortings[id];
    const toIndex = fromIndex + step;
    const isBetweenPositions = step > 0
                                 ? (index => index > fromIndex && index <= toIndex)
                                 : (index => index < fromIndex && index >= toIndex);

    const change = {};
    iterateObject(sortings, function(id, index) {
      if(isBetweenPositions(index)) {
        change[id] = index + (step > 0 ? -1 : 1);
      }
    });

    change[id] = toIndex;
    updateSortings(change);
  }

  hasWriteAccess(answerTemplate) {
    const {currentUser} = this.props;
    const manager_id = answerTemplate.manager && answerTemplate.manager.id;
    return currentUser && (currentUser.admin || currentUser.id == manager_id);
  }
}

const mapStateToProps = state => ({ sortings: state.userSettings.answerTemplateSortings || {},
                                    userSettings: state.userSettings,
                                    currentUser: state.currentUser,
                                    answerTemplates: sortedAnswerTemplates(state) });
const mapDispatchToProps = dispatch => ({
  updateSortings: sortings => dispatch(actions.updateAnswerTemplateSortings(sortings)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AnswerTemplateList);
