import {findById} from 'app/lib/utils'
import {connect} from 'react-redux'

const TagById = function({id, small, tags}) {
  const t = findById(id, tags);
  // TODO: throw exception?
  if(!t)
    return null;

  return <Tag data={t} small={small} />;
}

const mapStateToProps = s => ({ tags: s.tags });
export default connect(mapStateToProps)(TagById);
