import Chart from 'chart.js';
import {connect} from 'react-redux'
import {findById} from 'app/lib/utils'
import RingList from 'app/lib/ring_list'

class BarChart extends Component {
  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate() {
    this.renderChart();
  }

  render() {
    return <canvas id="bar-chart"></canvas>;
  }

  renderChart() {
    const {statistic} = this.props;
    const ctx = document.getElementById('bar-chart').getContext('2d');
    var myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.labels(),
        datasets: this.datasets()
      },
      options: {
        scales: {
          xAxes: [{ maxBarThickness: 150 }],
          yAxes: [{ ticks: { beginAtZero:true } }]
        },
        tooltips: {
          // mode: 'index',
          footerFontStyle: 'italic',
          callbacks: {
            // column comment
            footer: (tooltipItems, data) => {
              const {index, datasetIndex} = tooltipItems[0];
              return data.datasets[datasetIndex].columnComments[index];
            }
          }
        },
      }
    });
  }

  // datasets are values column
  datasets() {
    const {statistic} = this.props;
    const result = [];
    const colorRing = new RingList(['yellow', 'blue', 'green', 'magenta', 'red']);

    for(let name of statistic.orderedColumnSystemNames()) {
      if(name != this.mainColumn() && name != this.additionalColumn())
        result.push(this.datasetFor(name, colorRing.next()));
    }

    return result;
  }

  datasetFor(columnName, color) {
    const {statistic} = this.props;
    const label = statistic.columns[columnName];
    const data = statistic.data.map(row => row[columnName]);

    let columnComments = [];
    if(statistic.additional_data.column_comments) {
      columnComments = statistic.additional_data.column_comments.map(row => row[columnName]);
    }
    return { label, data, columnComments, backgroundColor: color, borderColor: 'black', borderWidth: 1  };
  }

  mainColumn() {
    const {statistic} = this.props;
    if(!statistic.main_column)
      throw('main_column required');
    return statistic.main_column;
  }

  additionalColumn() {
    const {statistic} = this.props;
    return statistic.additional_column;
  }

  labels() {
    const { statistic, managers, tags, categories } = this.props;
    const main_column = statistic.main_column;
    const additional_column = statistic.additional_column;
    if(!main_column)
      throw 'main_column required';

    const getParentCategoryName = (categoryId) => {
      const category = categories.find(c => c.id === categoryId);
      return category && category.parent_id
        ? (categories.find(c => c.id === category.parent_id) || {}).name || ''
        : '';
    };


    const labelValue = function(row) {
      let label;
      const value = row[main_column];
      switch(statistic.column_types[main_column]) {
        case 'manager_id':
          label = (findById(value, managers) || {}).full_name;
          break;
        case 'tag_id':
          label = (findById(value, tags) || {}).short_name;
          break;
        case 'category_id':
          label = (findById(value, categories) ||{}).name;
          break;
        default: label = value;
      }
      if (additional_column) {
        const additional_value = row[additional_column];
        switch(statistic.column_types[additional_column]) {
          case 'manager_id':
            label += ' & ' +(findById(additional_value, managers) || {}).full_name;
            break;
          case 'tag_id':
            label += ' & ' + (findById(additional_value, tags) || {}).short_name;
            break;
          case 'category_id': {
            const categoryName = (findById(additional_value, categories) || {}).name;
            const parentCategoryName = getParentCategoryName(additional_value);
            label += parentCategoryName ? ` & ${parentCategoryName} & ${categoryName}` : ` & ${categoryName}`;
            break;
          }
          default: label += ' & ' + additional_value;
        }
      }
      return label;
    }
    return statistic.data.map(labelValue);
  }

  managerById(id) {
    const {managers} = this.props;
  }
}

const mapStateToProps = s => ({ managers: s.managers, tags: s.tags, categories: s.categories });
export default connect(mapStateToProps)(BarChart);
