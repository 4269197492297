const ScriptAction = function({action, onChangeValue, value}) {
  const ns = staff.script_actions;

  switch(action.action_type) {
    case 'add_tag':
      return <ns.AddTag action={action} />;
    case 'remove_tag':
      return <ns.RemoveTag action={action} />;
    case 'set_status':
      return <ns.SetStatus action={action} value={value} onChange={v => onChangeValue(action, v)}/>;
    case 'add_message':
      return <ns.AddMessage action={action} value={value} onChange={v => onChangeValue(action, v)}/>;
    case 'add_note':
      return <ns.AddNote action={action} value={value} onChange={v => onChangeValue(action, v)}/>;
    case 'change_assignee':
      return <ns.ChangeAssignee action={action} value={value} onChange={v => onChangeValue(action, v)}/>;
    default:
      return <div>{action.action_type}: {value || action.action_value}</div>;
  }
}

export default ScriptAction;
