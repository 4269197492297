import { queryParams } from 'app/lib/utils'
import qs from 'query-string'
import UserTicketTable from "../../../ui/user_ui/TicketTable";

class UserTicketList extends Component {
  state = {}

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    if(!this.state.loadingInProgress && this.needToUpdateData()) {
      this.getData();
    }
  }

  getData() {
    const params = this.ticketsGetParams();
    this.setState({ loadingInProgress: true });
    API.tickets.getAll({
      params: params,
      success: ({tickets, pagination}) => this.setState({ lastRequestParams: params, tickets, pagination }),
      error: data => console.log(data),
      complete: () => this.setState({ loadingInProgress: false }),
    });
    API.me.get({
      success: ({allowed_groups}) => this.setState({ groups: allowed_groups}),
      error: data => console.log(data),
    });
  }

  needToUpdateData() {
    // CRUTCH: keeping request params and compare them as json string
    return JSON.stringify(this.state.lastRequestParams) != JSON.stringify(this.ticketsGetParams());
  }

  ticketsGetParams() {
    const query = qs.parse(this.props.location.search);
    return { page: query.page || 1,
             status: query.status };
  }

  renderNewTicketButton(group) {
    const url = "/my/new?g=" + group.id
    return (
      <Margin size="0 0 20 0" key={group.title}><Button size="xs" to={url}>Написать обращение в { group.title }</Button></Margin>
    )
  }

  render() {
    const {tickets, groups} = this.state;
    const urlToPage = n => this.generateUrlMergedWith({ page: n });

    return (
      <layouts.User statusFilter={this.ticketsGetParams().status}>
        { groups && groups.map( group => this.renderNewTicketButton(group) ) }
        <UserTicketTable tickets={tickets} loadingInProgress={this.state.loadingInProgress}/>
        <Margin size='8' />
        <Pagination data={this.state.pagination}
                           urlGenerator={urlToPage} />
      </layouts.User>
    );
  }

  generateUrlMergedWith(params) {
    return this.generateUrl(this.locationQueryMergedWith(params));
  }

  generateUrl(parameters) {
    const keys = ['status'];
    let query = `?page=${parameters.page || 1}`;
    query += '&' + queryParams(parameters, keys);

    return '/my/tickets' + query
  }

  locationQueryMergedWith(obj) {
    return Object.assign({}, qs.parse(this.props.location.search), obj);
  }
}

export default UserTicketList
