import filesize from 'app/lib/filesize'

const Attachment = function({file, onDelete, onClick}) {
  const { progress } = file;

  return (
    <div className='attachment d-flex'>
      <a className="attachment__name d-inline-flex text-decoration-none" href={file.url || '#'} onClick={onClick} target="_blank" rel='noopener referrer'>
        {(file.url && !onDelete) &&
          <span className="material-icons mr-1">
            attach_file
          </span>
        }
        <div>
          <div className='text-overflow-ellipsis'>{file.name}</div>
          <div className="attachment__size text-nowrap text-gray mt-1">({filesize(file.size)})</div>
        </div>
      </a>
      {onDelete && <div className="attachment__delete ml-4" onClick={() => onDelete(file)}>&times;</div>}
      
      {progress && progress < 100 && <div className="attachment__progress" style={{width: file.progress + '%'}} />}
    </div>
  )
}

export default Attachment;
