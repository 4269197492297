const isEmpty = (obj) => !obj || obj.length === 0;

function TableOrNoContent({ cols, compact, children, loadingInProgress }) {
  if (loadingInProgress) {
    return <NoContent status={"Подождите, идет загрузка..."} spinner={true} />;
  }
  if (isEmpty(children)) {
    return <NoContent status={"Данные отсутствуют"} />;
  }
  return (
      <Table cols={cols} compact={compact}>
        {children}
      </Table>
  );
}

export default TableOrNoContent;
