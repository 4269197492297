class StatisticsLayout extends Component {
  render() {
    const tabs = [
      {id: 'managers', label: <span>По менеджерам</span>},
      {id: 'tags', label: <span>По тегам</span>},
    ]
    return (
      <layouts.Staff>
        <Card stretch>
          <Tabs tabs={tabs} current={this.currentTab()} onClick={id => browserHistory.push(`/staff/statistics/${id}`)} />
          <br />

          {this.props.children}
        </Card>
      </layouts.Staff>
    );
  }

  currentTab() {
    const path = location.pathname.split('/');
    return path[path.length - 1];
  }
}

export default StatisticsLayout;
