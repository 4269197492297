class TicketActivityStatus extends Component {
  render() {
    const {ticket} = this.props
    const [text, className] = {
      complete: ['решён', 'text-gray'],
      archived: ['в архиве', 'text-gray'],
      waiting_for_user: ['отвечен', 'text-cyan'],
      closed: ['закрыт', 'text-cyan'],
      draft: ['черновик', 'text-gray'],
      unassigned: ['ждет', 'text-yellow'],
      waiting_for_manager: ['ждет', 'text-yellow'],
    }[ticket.status_for_manager]

    const date = {
      waiting_for_manager: ticket.user_attended_at,
      unassigned: ticket.user_attended_at,
      waiting_for_user: ticket.manager_attended_at,
    }[ticket.status_for_manager] || ticket.status_changed_at

    return <span className={className} style={{whiteSpace: 'nowrap'}}>
      {text} <TimePassedSince time={date} />
    </span>
  }
}

export default TicketActivityStatus;
