import TimestampPicker from "../../../../ui/TimestampPicker";

class NotificationForm extends Component {
    render() {
        const { desc, onChangeDesc, date, onChangeDate, push, onChangePush, push_date, onSave } = this.props;
        let content = desc
        let time = 0
        let message = "Отправить пуш уведомления"
        if (typeof push_date === 'string') {
            message += ". ( Последний пуш был " + (new Date(push_date)) + " )"
        }
        if (!Object.keys(content).length) {
            content = " "
        }
        if (date != null && Object.keys(date).length){ 
            time = (new Date(date)).getTime() / 1000
        }

        return (
            <div className='tag-form'>
                <Margin size='16' />
                <Spaced h={40}>
                    <LabeledInput label="Содержание уведомления">
                        <Textarea value={content} onChange={v => onChangeDesc(v)} />
                    </LabeledInput>
                    Дата окончания:
                    <TimestampPicker value={time} onChange={v => onChangeDate(v)} />
                    <Checkbox label={message} checked={push}
                           onChange={v => onChangePush(v)} />
                    <SplitSides>
                        <Button onClick={onSave}>Сохранить</Button>
                    </SplitSides>
                </Spaced>
            </div>
        );
    }
}

export default NotificationForm;
