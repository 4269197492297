const actionTypes = {
  setCurrentUser: 'SET_CURRENT_USER',
  setGroups: 'SET_GROUPS',
  setTags: 'SET_TAGS',
  setManagers: 'SET_MANAGERS',
  setUser: 'SET_USER',
  setCategories: 'SET_CATEGORIES',
  setDocumentTitle: 'SET_DOCUMENT_TITLE',
  setReverseEventFeed: 'SET_REVERSE_EVENT_FEED',
  setHideEventsInFeed: 'SET_HIDE_EVENTS_IN_FEED',
  setMessageFormCache: 'SET_MESSAGE_FROM_CACHE',
  setScripts: 'SET_SCRIPTS',
  setAnswerTemplates: 'SET_ANSWER_TEMPLATES',
  setAppbarSearchType: 'SET_APPBAR_SEARCH_TYPE',
  setPreferPersonalAnswerTemplates: 'SET_PREFER_PERSONAL_ANSWER_TEMPLATES',
  setPreferPersonalScripts: 'SET_PREFER_PERSONAL_SCRIPTS',
  setTicketStatusFilter: 'SET_TICKET_STATUS_FILTER',
  setChatGPTModels: 'SET_CHAT_GPT_MODELS',
  mergeUsersById: 'MERGE_USERS_BY_ID',
  updateAnswerTemplateSortings: 'UPDATE_ANSWER_TEMPLATE_SORTINGS',
  updateScriptSortings: 'UPDATE_SCRIPT_SORTINGS',
}

const actions = {
  setCurrentUser: user => ({ user, type: actionTypes.setCurrentUser }),
  setGroups: groups => ({ groups, type: actionTypes.setGroups }),
  setTags: tags => ({ tags, type: actionTypes.setTags }),
  setCategories: categories => ({ categories, type: actionTypes.setCategories }),
  setManagers: managers => ({ managers, type: actionTypes.setManagers }),
  setUser: (user, id) => ({ user, id, type: actionTypes.setUser }),
  setDocumentTitle: documentTitle => ({ documentTitle, type: actionTypes.setDocumentTitle }),
  setReverseEventFeed: value => ({ reverseEventFeed: value, type: actionTypes.setReverseEventFeed }),
  setHideEventsInFeed: value => ({ hideEventsInFeed: value, type: actionTypes.setHideEventsInFeed }),
  setMessageFormCache: (id, text) => ({ id, text, type: actionTypes.setMessageFormCache }),
  setAnswerTemplates: answerTemplates => ({ answerTemplates, type: actionTypes.setAnswerTemplates }),
  setScripts: scripts => ({ scripts, type: actionTypes.setScripts }),
  setAppbarSearchType: value => ({ value, type: actionTypes.setAppbarSearchType }),
  setPreferPersonalAnswerTemplates: value => ({ value, type: actionTypes.setPreferPersonalAnswerTemplates }),
  setPreferPersonalScripts: value => ({ value, type: actionTypes.setPreferPersonalScripts }),
  setTicketStatusFilter: status => ({ status, type: actionTypes.setTicketStatusFilter }),
  setChatGPTModels: chatGPTModels => ({ chatGPTModels, type: actionTypes.setChatGPTModels }),
  mergeUsersById: usersObject => ({ usersObject, type: actionTypes.mergeUsersById }),
  updateAnswerTemplateSortings: sortings => ({ sortings, type: actionTypes.updateAnswerTemplateSortings }),
  updateScriptSortings: sortings => ({ sortings, type: actionTypes.updateScriptSortings }),
}

export { actions, actionTypes };
