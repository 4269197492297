/* global require:readonly */
import jQuery from 'jquery'
import React from 'react'
import {Link} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import repositories from 'app/models/repositories'
import entities from 'app/models/entities'

const browserHistory = createBrowserHistory();

Object.assign(window, {
  $: jQuery, jQuery,
  React, Component: React.Component,
  Link, browserHistory,
})

// FIXME: надо переделать этот хак порядка загрузки на нормальные импорты везде
Object.assign(window, require('app/ui').default)
Object.assign(window, {
  layouts: require('app/layouts').default,
  repositories, entities
})
Object.assign(window, {API: require('app/models/api').default})
