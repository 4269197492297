// /staff/categories
import Tree from 'app/ui/tree/tree'
import qs from "query-string";
import {findById} from 'app/lib/utils';
import CategoryRepo from "../../../models/repositories/CategoryRepo";
import TagRepo from "../../../models/repositories/TagRepo";
import {connect} from "react-redux";
import {reloadCurrentUser} from "../../../store/helpers";

class CategoriesList extends Component {
  state = { categories: [], tags: [] }

  componentDidMount() {
    reloadCurrentUser()
      .then(() => this.getData())
      .catch((data) => data.status === 403 || console.log(data));
  }

  getData() {
    this.getCategories();
    this.getTags();
  }

  getCategories() {
    const repo = new CategoryRepo();

    repo.getAll({ group_id: this.props.userGroupId }).then(
      (categories) => this.setState({ categories: categories }),
      (data) => console.log(data)
    );
  }

  getTags() {
    const repo = new TagRepo;
    repo.getAll({ group_id: this.state.groupId, public_only: true }).then((tags) => {
      this.setState({ tags, selectedTag: findById(qs.parse(this.props.location.search).tag, tags) });
    });
  }

  render() {
    const { showError, selectedTag } = this.state;

    return (
      <layouts.Staff>
        <Card stretch>
          <h2>Категории и подкатегории. <b>{selectedTag?.name}</b></h2>
          <Margin size='20' />

          {showError && <Notice type="error">{this.state.errors}</Notice>}
          <Margin size='20' />

          {this.renderTable(selectedTag?.id)}
        </Card>
      </layouts.Staff>
    );
  }

  renderTable(selectedTagId) {
    if (selectedTagId) {
      return this.renderTreeTable(selectedTagId);
    }
    return this.renderTagsTable();
  }

  renderTagsTable() {
    const { tags } = this.state;

    return <Table cols={["Публичный тег", ""]}>{tags.map((t) => this.renderTagRow(t))}</Table>;
  }

  renderTagRow(tag) {
    const cells = [
      <Link to={`/staff/categories?tag=${tag.id}`} key={tag.id} onClick={() => this.setState({ selectedTag: tag })}>
        {tag.name}
      </Link>,
    ];

    return (
      <tr key={tag.id}>
        {cells.map((c, i) => (
          <td key={i}>{c}</td>
        ))}
      </tr>
    );
  }

  renderTreeTable(selectedTagId) {
    const categories = this.state.categories.filter((c) => c.public_tag_id == selectedTagId);

    return (
      <div>
        <SplitSides>
          <Button
            size="xs"
            color="blue"
            onClick={() => {
              this.setState({ selectedTag: {} });
              browserHistory.push("/staff/categories");
            }}
          >
            Назад
          </Button>
          <Button to={`/staff/categories/new?tag=${selectedTagId}`}>Добавить категорию/подкатегорию</Button>
        </SplitSides>

        <Margin size="20" />
        <Table cols={["Название |Сортировочный вес|", ""]}>
          <Tree items={categories} itemComponentRender={this.renderTreeRow} />
        </Table>
      </div>
    );
  }

  renderTreeRow = (category) => {
    const {name, id, sorting_weight} = category;
    /* eslint-disable react/jsx-key */
    const cells = [
      <span>{name} |{sorting_weight}|</span>,
      id != null && this.renderControlButtons(category),
    ];
    /* eslint-enable react/jsx-key */

    return (
      <tr key={'' + category.id}>
        {cells.map((c, i) => <td key={i}>{c}</td>)}
      </tr>
    );
  }

  renderControlButtons(category) {
    const deleteCategory = () => {
      const repo = new CategoryRepo;
      repo.destroy(category).then(
        () => this.setState({ categories: this.state.categories.filter((c) => c.id != category.id) }),
        (data) => this.showErrorsFromResponse(data)
      );
    }

    return (
      <Spaced w={3}>
        <IconButton icon="edit" to={`/staff/categories/${category.id}?tag=${this.state.selectedTag.id}`} />
        <IconButton icon="delete" color="red" onClick={deleteCategory} />
      </Spaced>
    );
  }

  showErrorsFromResponse(response) {
    this.setState({ showError: false, errors: {} });

    this.setState({ showError: true, errors: response?.responseJSON?.errors.join("; \n")});
  }
}

const mapStateToProps = (state) => ({ userGroupId: state.currentUser.group_id });
export default connect(mapStateToProps)(CategoriesList);
