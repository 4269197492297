class PartnerRepo {
  getAll() {
    const promise = new Promise(function(resolve, reject) {
      const mapToEntitites = data => data.map(e => new entities.Partner(e));

      API.partners.getAll({
        success: data => resolve(mapToEntitites(data)),
        error: reject,
      });
    });

    return promise;
  }

  get(id) {
    const promise = new Promise(function(resolve, reject) {
      API.partners.get({
        id,
        success: p => resolve(new entities.Partner(p)),
        error: reject,
      });
    });

    return promise;
  }

  update(partner) {
    const promise = new Promise(function(resolve, reject) {
      API.partners.update({
        id: partner.id,
        params: partner,
        success: p => resolve(new entities.Partner(p)),
        error: reject,
      });
    });

    return promise;
  }
  create(params) {
    const promise = new Promise(function(resolve, reject) {
      API.partners.create({
        params,
        success: p => resolve(new entities.Partner(p)),
        error: reject,
      });
    });

    return promise;
  }

  updatePosition(params) {
    const promise = new Promise(function(resolve, reject) {
      API.partners.updatePosition({
        params,
        success: p => resolve(new entities.Partner(p)),
        error: reject,
      });
    });

    return promise;
  }
}

export default PartnerRepo;
