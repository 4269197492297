import "core-js/stable";
import "regenerator-runtime/runtime";
// для удобства разработки, ряд ключевых переменных делаем доступными глобально
import 'app/globals'

// импортим реактовскую функцию, которая заведёт все наше приложение
import {render} from 'react-dom'

// подключаем стили
import 'app/styles'
import 'react-select/dist/react-select.css'
import 'react-datetime/css/react-datetime.css'
import "react-image-gallery/styles/css/image-gallery.css";
// Импортируем клиентские роуты (то есть, набор соответствий между URLями и их обработчиками)
import Routes from './routes'

import { Provider } from 'react-redux'
import { store } from './store'
import { setDefaultDocumentTitle } from './store/helpers'

// HACK: monkey-patch routing function for smoother transition between pages
const oldPush = browserHistory.push
browserHistory.push = function(...args) {
  PageTransition.saveDom();
  oldPush(...args);
}

browserHistory.listen((_location) => setDefaultDocumentTitle());

// монтируем Реакт-приложение
render(
  <Provider store={store}>
    {Routes}
  </Provider>,
  document.getElementById('app')
)
