class DocumentationArticlesRepo {
    getAll(search) {
        const promise = new Promise(function(resolve, reject) {
          API.documentationArticles.getAll({
            search_text: search,
            success: resolve,
            error: reject,
          });
        });
    
        return promise;
      }
}

export default DocumentationArticlesRepo;
