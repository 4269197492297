import {connect} from 'react-redux'
import Select from 'react-select'
import PropTypes from 'prop-types';

class ActiveManagerSelect extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired, // f(manager)
    selected: PropTypes.number, // id
    clearable: PropTypes.bool, // should user be able to clear value?
    placeholder: PropTypes.string,
    /***** REDUX *****/
    activeManagers: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  render() {
    const {activeManagers, onChange, selected, clearable, placeholder} = this.props;

    return <Select simpleValue
                   valueKey='id'
                   labelKey='full_name'
                   placeholder={placeholder || 'Выберите менеджера'}
                   clearable={clearable}
                   options={activeManagers}
                   value={selected}
                   onChange={onChange} />
  }
}

const mapStateToProps = s => ({ activeManagers: s.activeManagers });
export default connect(mapStateToProps)(ActiveManagerSelect);
