/*
 *
 * Use promise to unify repositories
 * (in case we won't use JQuery in future or some cases)
 *
 */
class AccountRepo {
  update(account) {
    const promise = new Promise(function(resolve, reject) {
      API.accounts.update({
        id: account.id,
        params: account,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }
}

export default AccountRepo;
