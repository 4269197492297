import qs from 'query-string'

class UserLoginPage extends Component {
  state = {}

  render() {
    const {email, password, error} = this.state;
    return (
      <layouts.partials.SiteContent>
        <Card stretch>
          <h1>Вы не авторизованы</h1>
          <p>
            Для того, чтобы создать обращение или просмотреть уже созданные, &nbsp;
            <a href='https://auth.insales.ru/login'>войдите</a> в личный кабинет InSales
          </p>
        </Card>
      </layouts.partials.SiteContent>
    );
  }

  insalesAuthUrl() {
    let result = '/staff/login?insales=true';
    const redirectUrl = qs.parse(this.props.location.search).redirect;
    if(redirectUrl)
      result += `&redirect=${encodeURIComponent(redirectUrl)}`;
    return result;
  }
}

export default UserLoginPage;
