import AbstractEntity from './AbstractEntity'

class Subscription extends AbstractEntity {
  destroy() {
    const repo = new repositories.SubscriptionRepo();
    return repo.destroy(this.subscriber_id, this.id);
  }
}

export default Subscription;
