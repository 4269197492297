/*
 * Props:
 * * data - pagination data from rails
 * * urlGenerator - function(n) that return url to page n
 * * onChange - you can provide it instead of urlGenerator for click event
 */
class Pagination extends Component {
  pages() {
    const data = this.props.data;
    if(!data)
      return null;
    const {urlGenerator, onChange} = this.props;
    const result = [];
    const numbers = this.numbersToShow();
    let renderPageLink;
    if(urlGenerator) {
      renderPageLink = n => <Link to={urlGenerator(n)}>{n}</Link>;
    } else {
      renderPageLink = n => <a onClick={() => onChange(n)}>{n}</a>;
    }

    if (numbers.length < 2) {
      return null
    }

    for(let i in numbers) {
      const page = numbers[i]

      if(page - (numbers[i - 1] || 0) > 1) { // gap between numbers
        result.push(<div key={`ellipsis-${page}`} className='pagination-ellipsis'>...</div>);
      }

      result.push (
        <div key={page} className={page == data.current ? 'pagination-page--current' : 'pagination-page'}>
          {page == data.current ? page : renderPageLink(page)}
        </div>
      );
    }

    return result;
  }

  render() {
    return (
      <div className='pagination'>
        {this.pages()}
      </div>
    );
  }

  numbersToShow() {
    const data = this.props.data;
    const perGroup = 3;
    if(data.total <= perGroup * 3 + 2) {
      // just array filled with 1..data.total
      return Array(data.total).fill(0).map((e, i) => i + 1);
    }

    const result = [];

    // first group
    for(let p = 1; p <= perGroup; p++)
      result.push(p);

    const inFirstGroup = n => n <= perGroup;
    const inLastGroup = n => n > data.total - perGroup;

    // middle group
    const middleStart = data.current - Math.floor(perGroup / 2);
    if(result[result.length - 1] == middleStart - 2) {
      // instead of ellipsis
      result.push(middleStart - 1);
    }
    for(let p = middleStart; p < middleStart + perGroup; p++) {
      !inFirstGroup(p) && !inLastGroup(p) && result.push(p);
    }

    // last group
    const lastStart = data.total - perGroup + 1;
    if(result[result.length - 1] == lastStart - 2) {
      // instead of ellipsis
      result.push(lastStart - 1);
    }
    for(let p = lastStart; p <= data.total; p++)
      result.push(p)

    return result;
  }
}

export default Pagination;
