import {linkToTicket} from 'app/lib/utils';

class TicketJoin extends Component {
  state = { selected: [], ticketsFromSearch: [] }
  componentDidMount() {
    this.setState({ selected: [], ticketsFromSearch: [] });
  }

  render() {
    const {onHide, ticket} = this.props;
    const {errorMessage, subject} = this.state;

    return (
      <div className='ticket-fork'>
        <Modal onHide={onHide}>
          <h2>Объединение тикетов внутри #{ticket.id}</h2>
          {errorMessage && <Notice type="error">{errorMessage}</Notice>}
          <Margin size="20" />
          {this.renderTicketList()}
          <Margin size="20" />
          {this.renderSearchForm()}
          {this.renderFoundTickets()}

          <SplitSides>
            <div />
            <Button onClick={() => this.join()}>Объединить</Button>
          </SplitSides>
          {errorMessage && <Notice type="error">{errorMessage}</Notice>}
        </Modal>
      </div>
    );
  }

  renderTicketList() {
    const recentTickets = this.props.ticket.recent_not_archived_user_tickets;
    const tickets = (recentTickets || []).concat(this.state.ticketsFromSearch);
    const selected = this.state.selected;

    const unselect = id => this.setState({ selected: selected.filter(e => e != id) });
    const unique = array => array.filter((e, index) => array.indexOf(e) === index);
    const select = id => this.setState({ selected: unique(selected.concat([id])) });

    const renderCheckbox = t => <Checkbox onChange={v => v ? select(t.id) : unselect(t.id)}
                                                 checked={selected.indexOf(t.id) != -1}
                                                 label={this.renderTicketLabel(t)} />
    return (
      <div>
        <h3>Недавние активные тикеты</h3>
        {tickets.map(t => <div key={t.id}>{renderCheckbox(t)}</div>)}
      </div>
    )
  }

  renderSearchForm() {
    const searchParams = function(searchFormData) {
      const result = { status: ['open', 'waiting', 'closed'] };

      if(searchFormData.search) {
        Object.assign(result, searchFormData);
      } else {
        Object.assign(result,
                      { search: { number: searchFormData.search_number,
                                  content: searchFormData.search_content,
                                  user: searchFormData.search_user,
                                  account: searchFormData.search_account } });
      }

      return result;
    }

    const searchInProgress = this.state.searchInProgress;
    return (
      <div>
        <h3>Поиск</h3>
        <staff.TicketSearchForm onSubmit={data => this.search(searchParams(data))}
                                       disabled={searchInProgress}
                                       initType='number' />
        {searchInProgress && <Spinner />}
      </div>
    );
  }

  search(params) {
    this.setState({ searchInProgress: true });
    API.tickets.getAll({ params,
                         success: ({tickets}) => this.setState({ foundTickets : tickets }),
                         error: data => console.log(data),
                         complete: () => this.setState({ searchInProgress: false }) });
  }

  renderFoundTickets() {
    const {selected, ticketsFromSearch} = this.state;
    const foundTickets = this.state.foundTickets || [];
    const addTicket = t => this.setState({ selected: selected.concat([t.id]),
                                           ticketsFromSearch: ticketsFromSearch.concat([t]) });
    const renderAddButton = t => <a onClick={() => addTicket(t)}><Icon name='add' size='15px' /></a>;
    const renderListItem = t => <div key={t.id}>{renderAddButton(t)} {this.renderTicketLabel(t)}</div>;
    return (
      <div>
        {foundTickets.map(t => renderListItem(t))}
      </div>
    );
  }

  renderTicketLabel = t => <span>{t.subject} (<LinkToTicket id={t.id} />)</span>;

  join() {
    const {subject, selected} = this.state;
    const {ticket, onHide} = this.props;
    const onError = ({responseJSON}) => this.showError(responseJSON.errors.join('; '));

    if(selected.length === 0) {
      this.showError('Не выбрано ни одного тикета');
      return;
    }

    this.hideError();
    API.tickets.join({ params: { with: selected },
                       id: ticket.id,
                       success: ticket => location.reload(true), // CRUTCH!
                       error: onError });
  }

  showError(message) {
    this.setState({ errorMessage: message });
  }

  hideError() {
    this.setState({ errorMessage: null });
  }
}

export default TicketJoin;
