import {reloadManagers} from 'app/store/helpers'
import strings from 'app/lib/strings'
import qs from 'query-string'

// /staff/calls
class CallListPage extends Component {
  state = {}
  componentDidMount() {
    reloadManagers();
    this.getData();
  }

  componentDidUpdate() {
    this.getData();
  }

  getData() {
    const page = qs.parse(this.props.location.search).page || 1;
    if(this.isLoadingInProgress() || this.state.loadedPage === page) {
      return;
    }
    const repo = new repositories.CallRepo();
    this.setState({ loadingInProcess: true });
    repo.getAll({ page })
        .then(data => this.setState(data))
        .then(data => this.setState({ loadingInProcess: false, loadedPage: page }))
  }

  render() {
    const {calls, pagination} = this.state;
    if (!calls) {
      return <PageTransition />;
    }

    return (
      <layouts.Staff>
        <Card stretch>
          <Margin size="20" />
          <h2>Звонки</h2>
          <Margin size="20" />

          <Table cols={['', 'Номер', 'Поступил', 'Ответ', 'Продолжительность', 'Менеджер']}>
            {calls.map(c => this.renderTableRow(c))}
          </Table>

          <Pagination data={pagination} urlGenerator={n => this.urlToPage(n)} />
        </Card>
      </layouts.Staff>
    );
  }

  urlToPage(n) {
    n = n || 1;
    return n == 1 ? `/staff/calls` : `/staff/calls?page=${n}`;
  }

  renderTableRow(call) {
    const {external_id, answered_at, recieved_at, phone, duration, manager_id} = call;
    let duration_minutes = '';
    let answer_in = '';
    if (recieved_at && answered_at)
      answer_in = (new Date(answered_at) - new Date(recieved_at)) / 1000;

    if(duration < 60) {
      duration_minutes = `${duration} сек`;
    } else if (duration >= 60) {
      duration_minutes = new Date(duration * 1000).toISOString().substring(14, 19);
    } else if(duration > 3600) {
      duration_minutes = new Date(duration * 1000).toISOString().substring(11, 19);
    }
    /* eslint-disable react/jsx-key */
    const cells = [
      <span>{external_id}</span>,
      <span>{phone}</span>,
      recieved_at && <span><TimePassedSince time={recieved_at} /> назад</span>,
      answer_in && <Tooltip content={new Date(answered_at).toLocaleString('ru', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })}><span>{answer_in} сек</span></Tooltip>
                || call.ended_at && <span>пропущен</span>,
      call.getDurations().map(d => <Tooltip content={`${d} ${strings.p(d, 'секунда', 'секунды', 'секунд')}`} key={d}>{duration_minutes}</Tooltip>),
      call.getManagerIds().map(id => <div key={id}><ManagerById id={id} /></div>),
    ];
    /* eslint-enable react/jsx-key */

    return (
      <tr key={'' + call.id} id={`call_${call.id}`}>
        {cells.map((c, i) => <td key={i}>{c}</td>)}
      </tr>
    );
  }

  isLoadingInProgress() {
    return this.state.loadingInProcess;
  }
}

export default CallListPage;
