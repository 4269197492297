import {bemModifiers} from 'app/lib/utils';

class Textarea extends Component {
  focus() {
    this.refs.element.focus();
  }

  render() {
    const {value, placeholder, onChange, onPaste, color, small, disabled, name, error} = this.props;
    const modifiers = [color, small && 'small', error && 'error'].filter(e => e);
    const className = bemModifiers('textarea', modifiers);
    return (
      <textarea className={className}
                name={name}
                ref='element'
                value={value}
                onChange={e => onChange && onChange(e.target.value)}
                onPaste={e => onPaste && onPaste(e)}
                disabled={disabled}
                placeholder={placeholder} />
    );
  }
}

export default Textarea;
