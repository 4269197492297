import Chart from 'chart.js';
import {connect} from 'react-redux'
import {findById} from 'app/lib/utils'

class IndividualPiesChart extends Component {
  render() {
    const {statistic} = this.props;

    return (
      <div>
        {statistic.data.map(row => this.renderPie(row))}
      </div>
    )
  }

  renderPie(row) {
    const label = this.pieLabel(row);
    const data = this.pieData(row);
    // base64 without = HACK: better to use something else
    const idSuffix = btoa(unescape(encodeURIComponent(label))).match(/[^=]+/)[0];

    return <charts.Pie idSuffix={idSuffix} key={label} label={label} data={data} />;
  }

  pieLabel(row) {
    const {statistic, managers, tags} = this.props;
    const value = this.rowMainColumnValue(row);

    switch(statistic.column_types[this.mainColumn()]) {
      case 'manager_id': return (findById(value, managers) || {}).full_name;
      case 'tag_id': return (findById(value, tags) || {}).short_name;
      default: return value;
    }
  }

  pieData(row) {
    const {statistic} = this.props;
    const result = [];

    for(let key in row) {
      if(key == this.mainColumn())
        continue;

      const label = statistic.columns[key];
      result.push([row[key], label]);
    }

    return result;
  }

  rowMainColumnValue(row) {
    const {statistic} = this.props;
    return row[this.mainColumn()];
  }

  mainColumn() {
    const {statistic} = this.props;
    const column = statistic.main_column;
    if(!column)
      throw 'main_column required';

    return column;
  }
}

const mapStateToProps = s => ({ managers: s.managers, tags: s.tags });
export default connect(mapStateToProps)(IndividualPiesChart);
