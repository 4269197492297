import {actions, actionTypes} from './actions'
import {localCache, withWritingToLocalCache} from './local_cache'
import CurrentUserRepo from './../models/repositories/CurrentUserRepo'

const initialUserSettings = {
  ticketStatusFilter: 'open',
  preferPersonalAnswerTemplates: false,
  preferPersonalScripts: false,
  reverseEventFeed: localCache.values().reverseEventFeed,
  hideEventsInFeed: localCache.values().hideEventsInFeed,
  appbarSearchType: localCache.values().appbarSearchType,
  answerTemplateSortings: localCache.values().answerTemplateSortings || {},
  scriptSortings: localCache.values().scriptSortings || {},
}
let withSavingTimeout = null;
function withSaving(state, change) {
  const nextState = withWritingToLocalCache(state, change);
  clearTimeout(withSavingTimeout);
  withSavingTimeout = setTimeout(() => (new CurrentUserRepo()).updateSettings(change),
                                 1500)

  return nextState;
}

function userSettingsReducer(state, action) {
  let nextState = null;
  switch(action.type) {
    case actionTypes.setCurrentUser:
      return action.user.settings ? action.user.settings : state;
    case actionTypes.setTicketStatusFilter:
      return Object.assign({}, state, { ticketStatusFilter: action.status });
    case actionTypes.setReverseEventFeed:
      return withSaving(state, { reverseEventFeed: action.reverseEventFeed });
    case actionTypes.setHideEventsInFeed:
      return withSaving(state, { hideEventsInFeed: action.hideEventsInFeed });
    case actionTypes.setAppbarSearchType:
      return withSaving(state, { appbarSearchType: action.value });
    case actionTypes.setPreferPersonalAnswerTemplates:
      return withSaving(state, { preferPersonalAnswerTemplates: action.value });
    case actionTypes.setPreferPersonalScripts:
      return withSaving(state, { preferPersonalScripts: action.value });
    case actionTypes.updateAnswerTemplateSortings: {
      const updatedSortings = Object.assign({}, state.answerTemplateSortings, action.sortings);
      return withSaving(state, { answerTemplateSortings: updatedSortings });
    }
    case actionTypes.updateScriptSortings: {
      const updatedScriptSortings = Object.assign({}, state.scriptSortings, action.sortings);
      return withSaving(state, { scriptSortings: updatedScriptSortings });
    }
    default:
      return state;
  }
}

export {initialUserSettings, userSettingsReducer}
