/*
 * This component loads and contains tickets and shows them
 */
class Tickets extends Component {
  state = {}

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    const {loadingInProgress} = this.state;
    if(!loadingInProgress && this.needToLoadData()) {
      this.loadData();
    }
  }

  needToLoadData() {
    // HACK: keeping request params and compare them as json string
    const {params} = this.props;
    const {lastLoadParams} = this.state;
    return JSON.stringify(lastLoadParams) != JSON.stringify(params);
  }

  loadData() {
    this.setState({ loadingInProgress: true });
    const unsetLoadingFlag = () => this.setState({ loadingInProgress: false });

    const repo = new repositories.TicketRepo();
    const {params} = this.props;
    repo.getAll(this.props.params)
        .then(
          ({tickets, pagination}) => {
            this.setState({ lastLoadParams: params, tickets, pagination })
            this.loadUnreadStatus();
          },
          data => console.log(data)
        )
        .then(unsetLoadingFlag, unsetLoadingFlag)
  }

  loadUnreadStatus() {
    const repo = new repositories.TicketRepo();
    const ids = this.state.tickets.map(t => t.id);
    repo.getUnreadStatuses(ids)
        .then(unreadStatuses => this.setState({ unreadStatuses }),
              data => console.log(data))
  }

  render() {
    const {tickets, pagination, loadingInProgress, unreadStatuses} = this.state;
    const {urlToPage, params} = this.props;

    if(!tickets)
      return null;

    return (
      <div>
        <TicketTable
            tickets={tickets}
            unreadStatuses={unreadStatuses || {}}
            searchedByNumber={params.search.number}
            loadingInProgress={loadingInProgress}
        />
        <Margin size='20' />
        <Pagination data={pagination}
                           urlGenerator={urlToPage} />
      </div>
    )
  }
}

export default Tickets;
