import {bemModifiers} from 'app/lib/utils';

class Notice extends Component {
  componentDidMount() {
    // HACK: after mount component is not fully styled to imitate animation
    setTimeout(() => jQuery(this.refs.root).addClass('notice--animate'));
  }

  render() {
    const {type, children} = this.props;
    return (
      <div ref='root' className={bemModifiers('notice', [type])}>
        <Icon className='notice__icon' name={type} />
        <div className='notice__text'>
          {children}
        </div>
      </div>
    );
  }
}


export default Notice
