import {findById} from 'app/lib/utils'

const TagSelect = function({ current, onChange, tags }) {
  const items = tags.map(t => ({ id: t.id, label: t.short_name || t.name }));
  const selectedTag = findById(current - 0, tags);
  return (
    <DropdownTrigger onSelect={onChange} items={items}>
      {selectedTag ? <Tag data={selectedTag} /> : 'Выберите тег'}
    </DropdownTrigger>
  );
}

export default TagSelect;
