class AutoReplyRepo {

  getAll(params) {
    const promise = new Promise(function (resolve, reject) {
      API.autoReplies.getAll({
        params,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }

  get(id) {
    const promise = new Promise(function (resolve, reject) {
      API.autoReplies.get({
        id,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }

  update(autoReply) {
    const promise = new Promise(function (resolve, reject) {
      API.autoReplies.update({
        id: autoReply.id,
        params: autoReply,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }

  create(autoReply) {
    const promise = new Promise(function (resolve, reject) {
      API.autoReplies.create({
        params: autoReply,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }

  destroy(autoReply) {
    const id = typeof autoReply == 'object' ? autoReply.id : autoReply;

    const promise = new Promise(function (resolve, reject) {
      API.autoReplies.delete({
        id,
        success: resolve,
        error: reject,
      });
    });

    return promise;
  }
}

export default AutoReplyRepo;
