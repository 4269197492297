import cookie from 'cookie'
import scrollToTop from 'app/lib/scrollToTop'

class AnswerTemplateNew extends Component {
  constructor(props) {
    super(props);
    this.state = { formData: { personal: true} };
  }

  componentDidMount() {
    API.managers.get({ id: 'me',
                       success: m => this.setState({ currentUser: m }),
                       error: data => console.log(data) });
  }

  render() {
    const {showSuccess, showError, formData, hideSubmit} = this.state;
    const errors = this.state.errors || {};

    return (
      <layouts.Staff>
        <Card stretch>
          {showSuccess && <Notice type="done">Успешно</Notice>}
          {showError && <Notice type="error">Произошла ошибка</Notice>}

          <LabeledInput label="Название" error={errors.title && errors.title.join('; ')}>
            <TextInput value={formData.title || ''} onChange={v => this.setFormData({ title: v })} />
          </LabeledInput>

          <Margin size='16' />

          <LabeledInput label="Содержимое" error={errors.content && errors.content.join('; ')}>
            <Textarea value={formData.content || ''} onChange={v => this.setFormData({ content: v })} />
          </LabeledInput>

          <Margin size='16' />

          <LabeledInput label="Вес при сортировке" error={errors.order_weight && errors.order_weight.join('; ')}>
            <TextInput value={formData.order_weight || 0} onChange={v => this.setFormData({ order_weight: v })} />
          </LabeledInput>

          <Margin size='16' />

          <Checkbox disabled={!this.isAdmin()} label="Личный" checked={formData.personal} onChange={v => this.setFormData({ personal: v })} />

          <Margin size='16' />

          {!hideSubmit && <Button onClick={() => this.onSubmit()}>Создать</Button>}
        </Card>
      </layouts.Staff>
    );
  }

  isAdmin() {
    const currentUser = this.state.currentUser;
    return currentUser && currentUser.admin;
  }

  setFormData(data) {
    this.setState({ formData: Object.assign(this.state.formData || {}, data) });
  }

  onSubmit() {
    const message = this.state.formData
    message.group_id = cookie.parse(document.cookie).g

    API.answerTemplates.create({ params: this.state.formData,
                                 success: t => this.onUpdateSuccess(t),
                                 error: jqueryData => this.onUpdateError(jqueryData.responseJSON) })

    this.setState({ showSuccess: false, showError: false, hideSubmit: true, errors: {} });
  }

  onUpdateSuccess(template) {
    this.setState({ showSuccess: true });
    scrollToTop();

    setTimeout(() => browserHistory.push(`/staff/answer_templates`), 2000);
  }

  onUpdateError(response) {
    this.setState({ showError: true, errors: response.errors, hideSubmit: false });
    scrollToTop();
  }
}

export default AnswerTemplateNew;
