import { connect } from 'react-redux'

const IntArray = function({ value, onChange }) {
  value = value || [];
  const isNotBlank = s => !s.match(/^\s+$/);
  const strToIntArray = str => str == '' ? null : str.split(',').filter(isNotBlank).map(e => e - 0);
  const prepareValue = v => strToIntArray(v[v.length -1] == ',' ? (v + '0') : v);

  return <TextInput value={value.join(', ')}
                           onChange={v => onChange(prepareValue(v))} />;
}

let TagArray = function({ value, onChange, tags }) {
  const alreadySelected = tag => value && -1 != value.indexOf(tag.id);
  const withoutTag = tag => value.filter(id => id != tag.id);
  const withTag = tag => (value || []).concat(tag.id);
  const onSelect = tag => onChange(alreadySelected(tag) ? withoutTag(tag) : withTag(tag));
  return <TagList tags={tags} highlight={value} onSelect={onSelect} small />;
}
TagArray = connect(s => ({ tags: s.tags }))(TagArray);

let CategoryArray = function({ value = [], onChange, categories }) {
  const alreadySelected = (category) => value.includes(category.id)
  const getSubcategoriesIds = (parentId) =>
    categories.filter((c) => c.parent_id === parentId).map((c) => c.id)

  const onSelect = category => {
    if (!category.parent_id) {
      const subcategoriesIds = getSubcategoriesIds(category.id)
      value = alreadySelected(category)
        ? value.filter((id) => id !== category.id && !subcategoriesIds.includes(id))
        : [...value, category.id, ...subcategoriesIds]
    } else {
      value = alreadySelected(category)
        ? value.filter((id) => id !== category.id)
        : [...value, category.id]
    }
    onChange(value)
  }

  return <CategoryList categories={categories} highlight={value} onSelect={onSelect} small />;
}
CategoryArray = connect(s => ({ categories: s.categories }))(CategoryArray);

const Parameter = function({ info, value, onChange }) {
  let input = null;
  if(info.type == 'timestamp') {
    input = <TimestampPicker value={value} onChange={onChange} />;
  } else if(info.type == 'manager_id_array') {
    input = <staff.ManagerMultiselect selected={value} onChange={onChange} />;
  } else if(info.type == 'tag_id_array') {
    input = <TagArray value={value} onChange={onChange} />;
  } else if(info.type == 'int_array') {
    input = <IntArray value={value} onChange={onChange} />;
  } else if(info.type == 'category_id_array') {
     input = <CategoryArray value={value} onChange={onChange} />;
  }

  const isInTable = info.type === 'tag_id_array' || info.type === 'category_id_array'

  return(
    <div className={isInTable ? 'parameters-item' : undefined}>
      {info.name}:<br />
      {input ? input : `TODO: ${info.type}`}
    </div>
  );
}

export default Parameter;
