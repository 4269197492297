import {findById} from 'app/lib/utils'
import {connect} from 'react-redux'

const ManagerById = function({id, short, managers}) {
  const m = findById(id, managers);

  // TODO: throw exception?
  if(!m)
    return null;

  return <Person person={m} short={short} />;
}

const mapStateToProps = s => ({ managers: s.managers });
export default connect(mapStateToProps)(ManagerById);
