import {connect} from 'react-redux'

class SingleTicket extends Component {
  state = {}

  render() {
    const {showTicketFork, showTicketJoin} = this.state;
    const {events, ticket} = this.props;
    return (
      <layouts.Staff side={this.renderRightPanel()}>
        <Card stretch>
          {this.props.children}
          {showTicketFork && <staff.TicketFork ticket={ticket} events={events} onHide={() => this.setState({ showTicketFork: false })} />}
          {showTicketJoin && <staff.TicketJoin ticket={ticket} onHide={() => this.setState({ showTicketJoin: false })} />}
        </Card>
      </layouts.Staff>
    );
  }

  renderRightPanel() {
    const {currentUser, ticket} = this.props;
    const isPartner = currentUser.kind == 'manager' && currentUser.isPartner();
    return (
      <div>
        {this.renderInfoCard()}
        <Margin size='20' />
        {ticket && ticket.account_id && this.renderAccountNoteCard()}
        <Margin size='20' />
        {!isPartner && this.renderTicketActionsCard()}
        <Margin size='20' />
        {!isPartner && this.renderFreeWorksCard(this.props.freePlan)}
        <Margin size='20' />
        {!isPartner && this.renderRecentTicketsCard()}
      </div>
    );
  }

  renderInfoCard() {
    const ticket = this.props.ticket;

    return (
      <Card>
        <Person person={ticket.user} />
        <Margin size="12 10">
          <staff.TicketInfoCard ticket={ticket} freePlan={this.props.freePlan} />
        </Margin>
      </Card>
    )
  }

  renderFreeWorksCard(freePlan) {
    const {ticket, reloadData } = this.props;
    const freeWorkType = this.props.freePlan ? "обращения" : "доработки";

    return (
      <Card>
        <h3>Бесплатные {freeWorkType}</h3>
        <staff.FreeWorksInfo key={ticket && ticket.id} ticket={ticket} freePlan={freePlan} afterChange={reloadData} />
      </Card>
    )
  }

  renderAccountNoteCard() {
    const {ticket, reloadData, currentUser} = this.props;
    const {account_id} = ticket;
    const note = ticket.account && ticket.account.note;

    return (
      <Card>
        <h3>Заметка к аккаунту</h3>
        <staff.AccountNote key={`${account_id}${note}`} account_id={account_id} note={note} currentUser={currentUser} onSave={reloadData} />
      </Card>
    )
  }
  renderRecentTicketsCard() {
    const ticket = this.props.ticket;
    return (
      <Card>
        <h3>Тикеты</h3>
        <small><Link to={`/staff/tickets?user=${ticket.user.id}`}>Все тикеты</Link></small>
        <Margin size='10' />
        <staff.TicketList tickets={ticket.recent_not_archived_user_tickets} />
      </Card>
    );
  }

  renderTicketActionsCard() {
    return (
      <Card>
        <a onClick={() => this.setState({ showTicketFork: true, showTicketJoin: false })}>Отсоединение тикета</a>
        <br />
        <a onClick={() => this.setState({ showTicketFork: false, showTicketJoin: true })}>Объединение тикетов</a>
      </Card>
    );
  }
}

const mapStateToProps = s => ({ currentUser: s.currentUser })
export default connect(mapStateToProps)(SingleTicket);
